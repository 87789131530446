@use "../../abstracts/admin-variables"as *;

.label {
  color: #4F4F4F;

}

.Adminheader {
  @include flex(center, space-between);
  // padding: 0.75rem 0.875rem 0.75rem 1.875rem;
  padding: 0.25rem 0.25rem 0.25rem 1.875rem;
  // background-color: $primary;
  background-color: #5497C7;
  border-radius: 40px;

  &__info {
    @include flex(center, flex-start);
    gap: 14px;
  }

  &__actions {
    @include flex(center, center);
    gap: 10px;
  }

  &__title {
    font-size: 1.75rem;
    font-weight: 700;
    line-height: normal;
    color: $white;
    margin: 0;
  }

  &__subtitle {
    font-size: 0.875rem;
    font-weight: 500;
    line-height: normal;
    color: $white;
  }

  &__profile {
    position: relative;

    &.Adminprofile--active {
      .Adminprofile {
        border-radius: 25px 25px 0 0;
        transition: all 0.3s ease;

        .Adminprofile__info {
          .adminbtn-sm {
            transform: rotate(180deg);
            transition: all 0.3s ease;
          }
        }
      }

      .Adminprofile__dropdown {
        height: auto;
        top: 100%;
        opacity: 1;
        visibility: visible;
        transition: all 0.3s ease;
      }
    }
  }
}

.Adminprofile {
  min-width: 250px;
  // background-color: $secondary;
  background-color: $secondary;
  border-radius: 25px;
  @include flex(center, space-between);
  gap: 8px;
  cursor: pointer;
  position: relative;
  z-index: 99;

  &__info {
    @include flex(center, flex-start);
    gap: 8px;

    .btn-sm {
      transition: all 0.3s ease;
    }

    &--name {
      @include flex(flex-start, flex-start);
      flex-direction: column;

      h4 {
        color: $white;
        font-size: 1.125rem;
        font-weight: 600;
        line-height: normal;
        margin: 0;
      }

      p {
        color: $white;
        font-size: 0.625rem;
        font-weight: 400;
        line-height: normal;
      }
    }
  }

  &__image {
    width: 50px;
    height: 50px;
    flex: 0 0 50px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__dropdown {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    transition: all 0.3s ease;
    z-index: 1;

    ul {
      // background-color: $secondary;
      background-color: #5497C7;
      border-radius: 0 0 25px 25px;
      padding-bottom: 0.375rem;

      li {
        padding: 0.5rem 0;

        a {
          @include flex(center, flex-start);
          gap: 8px;
          color: $white;
          text-decoration: none;

          svg {
            width: 50px;
          }

          span {
            margin: 0 16.5px;
          }
        }
      }
    }
  }
}