@media print {
  @page {
    size: A4;
    margin: 0;
  }

  body {
    font-family: sans-serif;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
  }

  .main-container {
    margin: 0;
    padding: 0;
  }

  .container {
    width: 100%;
    margin: 0 auto;
    padding: 0px !important;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 120px;
  }

  .h1 {
    font-size: 2em;
    margin-bottom: 0.5em;
  }

  .h2 {
    font-size: 1.5em;
    margin-bottom: 0.5em;
  }

  table,
  .invoice-info,
  .customer-info,
  .total-table,
  .sign,
  .last-content {
    page-break-inside: avoid;
  }
}
.calender_container {
  width: 100%;
  margin: 0 auto;
  padding: 0px !important;
}

@media screen and (max-width: 450px) {
  .calender_container {
    width: 100%;
    margin: 0 auto;
    padding:0px 20px !important;
  }
}

@media screen and (max-width: 400px) and (min-width: 390px) {
  .birthday_container {
    padding: 0px 17px !important;
  }
}

.container {
  width: 100%;
  margin: 0 auto;
  padding: 0px !important;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.h1 {
  font-size: 2em;
  margin-bottom: 0.5em;
}

.h2 {
  font-size: 1.5em;
  margin-bottom: 0.5em;
}

.main-table {
  width: 100%;
  border-collapse: collapse;
}

.main-table th {
  color: white;
}

.main-table th,
.main-table td {
  border-radius: 2px;
  padding: 0.5em;
}

.Dec-table {
  margin-bottom: 203%;
  width: 100px;
  margin-left: 34px;
  color: white;
}

.Dec-table th {
  text-align: left;
}

.total {
  font-weight: bold;
}

.invoice-info {
  display: flex;
  justify-content: space-evenly;
  align-content: flex-end;
  gap: 4px;
  margin: 6% 0% 3% 50%;
}

.right {
  text-align: left;
}

.left p {
  color: #5497c7;
  font-weight: 300;
  align-items: right;
}

.right p span {
  font-weight: 600;
  line-height: 21px;
}

.main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3% 20%;
}

.name {
  font-size: 1.2rem;
  font-weight: 600;
  color: #5497c7;
}

.main-table {
  width: 120px;
}

.total-table {
  display: flex;
  flex-direction: column;
  width: 200px;
  margin: 20px 60%;
}

.row {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
  padding: 5px;
  align-items: flex-start;
}

.row:nth-child(odd) {
  background-color: #f2f2f2;
}

.label {
  font-weight: bold;
  color: #666;
  text-align: left;
}

.value {
  text-align: right;
}

.sign {
  display: flex;
  background-color: white;
  width: 300px;
  height: 100px;
  padding: 10px;
  margin: 20px 50%;
}

.line {
  width: 100%;
  height: 2px;
  background-color: black;
  margin: 10px 0;
}

.text {
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
}

.Bflexbox {
  display: flex;
  width: 250px;
  flex-direction: column;
  border: 2px solid;
  margin-left: 5%;
  margin-top: -125%;
  .desc {
    color: white;
    font-weight: bold;
  }
  .BColumn {
    width: 100%;
    align-items: flex-end;
    text-align: center;
    color: black;
    border: 1px solid;
  }
}
