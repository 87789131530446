$xs: 0 !default;
$sm: 576px !default;
$md: 768px !default;
$lg: 992px !default;
$xl: 1200px !default;
$xxl: 1400px !default;

$grid-breakpoints: (xs: $xs,
  sm: $sm,
  md: $md,
  lg: $lg,
  xl: $xl,
  xxl: $xxl,
);

.scrollable-container::-webkit-scrollbar {
  width: 2px;
}

.scrollable-container::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 1px;
}

.scrollable-container {
  scrollbar-width: thin;
  /* For Firefox */
  scrollbar-color: darkgrey transparent;
}


// Default padding for grid cells
$gutter-x: 1rem !default;

.container {
  max-width: 100%;
  width: 100%;
  padding: 0 $gutter-x;
  margin: 0 auto;
  display: block;

  @media screen and (min-width: $sm) {
    max-width: 540px;
    padding: 0 $gutter-x;
  }

  @media screen and (min-width: $md) {
    max-width: 720px;
  }

  @media screen and (min-width: $lg) {
    max-width: 960px;
  }

  @media screen and (min-width: $xl) {
    max-width: 1030px;
  }

  @media screen and (min-width: $xxl) {
    max-width: 1340px;
  }

  &--fluid {
    width: 100%;
    padding: 0 $gutter-x;
  }

  &--component {
    width: 100vw;
    height: 100vh;
    padding: 1.1rem;
    background-color: #f2f2f2;
  }

  &--grid {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 180px 1fr;
    gap: 5px;
  }
}

div[class^="col"] {
  padding: 0 $gutter-x;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -$gutter-x;
  margin-right: -$gutter-x;

  >* {
    flex-shrink: 0;
    width: 100%;
  }
}

.flex {
  display: flex;

  &--inline {
    display: inline-flex;
  }

  &--nowrap {
    flex-wrap: nowrap;
  }

  &--wrap {
    flex-wrap: wrap;
  }

  &--grow {
    flex-grow: 1;
  }

  &--column {
    flex-direction: column;
  }
}

.block {
  display: block;
}

.justifyspace {
  &--between {
    justify-content: space-between;
  }

  &--end {
    justify-content: flex-end;
  }

  &--start {
    justify-content: flex-start;
  }

  &--center {
    justify-content: center;
  }
}

.alignitems {
  &--center {
    align-items: center;
  }

  &--end {
    align-items: flex-end;
  }

  &--start {
    align-items: flex-start;
  }
}

.align {
  &--right {
    text-align: right !important;
  }

  &--center {
    text-align: center !important;
  }
}

// Grid loop
$columns: 12; // Max number of columns

.col_flex {
  width: 100%;

  &--inline {
    width: auto;
  }
}

// Grid cells for small devices
@for $i from 1 through $columns {
  .col_xs_#{$i} {
    flex: 0 0 auto;
    width: calc(100% / $columns * $i);
  }
}

// Grid cells for small-medium devices
@media (min-width: $sm) {
  @for $i from 1 through $columns {
    .col_sm_#{$i} {
      flex: 0 0 auto;
      width: calc(100% / $columns * $i);
    }

    .sm_offset_#{$i} {
      margin-left: calc(100% / $columns * $i);
    }
  }
}

// Grid cells for medium devices
@media (min-width: $md) {
  @for $i from 1 through $columns {
    .col_md_#{$i} {
      flex: 0 0 auto;
      width: calc(100% / $columns * $i);
    }

    .md_offset_#{$i} {
      margin-left: calc(100% / $columns * $i);
    }
  }
}

// Grid cells for large devices
@media (min-width: $lg) {
  @for $i from 1 through $columns {
    .col_lg_#{$i} {
      flex: 0 0 auto;
      width: calc(100% / $columns * $i);
    }

    .lg_offset_#{$i} {
      margin-left: calc(100% / $columns * $i);
    }
  }
}

// Grid cells for extra large devices
@media (min-width: $xl) {
  @for $i from 1 through $columns {
    .col_xl_#{$i} {
      flex: 0 0 auto;
      width: calc(100% / $columns * $i);
    }

    .xl_offset_#{$i} {
      margin-left: calc(100% / $columns * $i);
    }
  }
}

// Grid cells for extra-extra large devices
@media (min-width: $xxl) {
  @for $i from 1 through $columns {
    .row>.col_xxl_#{$i} {
      flex: 0 0 auto;
      width: calc(100% / $columns * $i);
    }

    .xxl_offset_#{$i} {
      margin-left: calc(100% / $columns * $i);
    }
  }
}

.flexbox {
  position: relative;
  display: flex;
  gap: 10px;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  .column {
    width: 100%;
    padding: 0;
  }
}



// ANIMATED LAODER CSS 
/* styles.css */
/* styles.css *//* styles.css */
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: white;
  padding: 0px 35px;
}

.loader-logo {
  width: 400px; 
  height: 200px;
  animation: bounce 2s infinite;
}
