@use "./variables"as *;

@media screen and (max-width: 991px) {
  .featureSec {
    padding: 35px;
  }
}

@media only screen and (max-width: 425px) {
  .aboutData {
    padding: 0px 20px;
  }

  .tab-contents {
    .tab-content {
      display: none;
      // padding: 0 40px;
      padding: 0 0px;
    }
  }

  .owl-carousel {
    width: 66%;
    margin-top: 2%;
    margin-left: 17%;
  }

  .owl-stage-outer {
    width: 100%;
  }

  .featureSec {
    padding: 80px;
  }

  .teamContent {
    width: 100%;
  }

  .facilitiesSec .facility_gymTimeGuywithName {
    gap: 10px;
    padding: 0px 15px;
  }

  .cheer_gym_box_container_fluid .blue_box_container {
    width: 100%;
    padding: 17px;
  }

  .cheerCarousel {
    padding: 78px;
    margin: 20px 52px !important;
  }

  .cheer_gym_box_container_fluid .pink_box_container {
    width: 100%;
    padding: 50px;
  }

  .team_classes_type_heading_container,
  .team_gym_cheer_email {
    padding: 0px 22px;
  }

  .container {
    width: 100%;
    margin: 0 auto;
    padding: 0px 20px !important;

    @media only screen and (max-width: 390px) {
      padding: 0px 20px !important;



    }


    @media screen and (min-width: 2560px) and (min-height: 1600px) {
      padding: 0px 100px !important;

    }
  }

  .videothumnialbox__pic__img {
    width: 100% !important;
    height: auto !important;
  }

  .modal__content {
    width: 100% !important;
    height: auto;
  }


  .icon--cake {
    width: 38% !important;
    gap: 8px !important;
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: start;
  }

  .title {
    text-align: left !important;
  }

  .titleBox {
    gap: 13px !important;
  }

  .birthdayActivity__card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }

  .blogList__card {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .aboutData__box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .aboutusSec .aboutData .picture--cliplayerPink::after {
    width: 160px;
    height: 200px !important;
    bottom: -60px;
  }

  .faqsData {
    display: flex;
    flex-direction: column;
    gap: 30px;

    @media screen and (max-width: 630px) {
      padding: 0px 0px;
    }

    @media screen and (max-width: 390px) {
      padding: 0px 25px;


    }

    @media screen and (max-width: 375px) {
      padding: 0px 0px !important;
    }
  }

  .cheerleading_upper_content_row {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    gap: 80px;
    flex-direction: column !important;
  }

  ._enrichment_programList__card {
    display: flex;
    align-items: center;
    gap: 40px;
    background: #fff;
    padding: 0px;
    box-shadow: 0px 24.2957763672px 54px 0px rgba(205, 205, 205, 0.4);
    flex-direction: column !important;
    padding: 20px !important;
  }

  .para {
    margin-bottom: 0;
    font-size: 1rem !important;
    font-weight: 300;
  }

  .noClassError {
    margin: 10% 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .succes-modal-title {
    display: flex;
    justify-content: center;
  }

  .aboutusSec .cheer_upper_content {
    width: 100% !important;
  }

  .aboutusSec .cheer_img {
    width: 100% !important;
  }

  .aboutusSec .aboutData .picture {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-repeat: no-repeat !important;
    background-position: bottom;
    background-size: contain !important;
    height: 100%;
    width: 100%;
    min-height: 370px;
    padding: 45px 0 35px 60px;
    text-align: center;
  }

  .picture iframe {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 90%;
    height: 85%;
    border: 0;
  }
}

.bday_terms_h4 {
  font-size: 1.2rem;
}

.bdaylist {
  list-style: inside;
}

.bdayfont {
  font-weight: 600;
}

.bday_content {

  font-size: 1rem;
  line-height: 20px;
  font-style: sans-serif;
}


@media screen and (max-width: 767px) {
  .aboutusSec .aboutData .picture {
    padding: 0 0 35px 0;
    min-height: 330px;
  }

  .birthdaySec .birthdayActivity__card .tittleBox {
    margin-bottom: -16px !important;
  }

  .birthdaySec .birthdayActivity__list__col {
    padding: 0px 35px !important;
  }

  .picture iframe {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 90%;
    height: 85%;
    border: 0;
  }

  .cheer-logo {
    height: 76px;
  }

  .newButton-image1 {
    left: 56% !important;
  }
}

@media screen and (max-width:450px) {
  .newButton-image1 {
    left: 50% !important;
  }
  .half_full_day_schedule_desc .half_full_weekly_desc {
    margin-top: 1rem;
   padding: 0px;
  }

}

// SCaling the Website from Ledt
/* For iPhone 12 and similar-sized devices */
@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) {
  .facilitiesSec {
    .container {
      padding: 0px 35px !important;

    }
  }

 

  .programList {
    padding: 30px 0px 0px 0px;
  }
}


/* For iPhone 13 and similar-sized devices */
@media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) {
  .facilitiesSec {
    padding: 0px;
  }

  .programList {
    padding: 30px 1px 0px 0px;
  }

  .shortext_dummy_text_home {
    padding: 0px;
  }
}

/* For iPhone 14 and similar-sized devices */
@media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) {
  .facilitiesSec {
    padding: 0px;
  }


  .programList {
    padding: 30px 1px 0px 0px;
  }

  .shortext_dummy_text_home {
    padding: 0px;
  }
}


/* For iPhone 15 and similar-sized devices */
@media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) {

  .home_dummy_text_container {
    background: red;
  }

  .facilitiesSec {
    .container {
      padding: 0px 0px !important;
    }

    .home_dummy_shortText {
      padding: 0px;
    }

    .facilitiesSec .shortText {
      line-height: 20px;
      font-size: 1rem;


    }

    .programList {
      padding: 30px 1px 0px 0px;
    }
  }

  .programList {
    padding-top: 0px;
  }

  .shortext_dummy_text_home {
    padding: 0px;
  }
}

@media screen and (max-width: 768px) {

  .featureSec,
  .staffSec,
  .aboutusSec,
  .birthdaySec,
  .commonSec,
  .facilitiesSec {
    margin: 10px 0px;
    width: 100%;
  }

}

@media screen and (max-width: 390px) {


  .enr_abou {
    padding: 0px;
  }

}


.campText {
  margin-bottom: 0;
  margin-top: 10px;
  line-height: 29px;
  word-spacing: 2px;
  font-size: 1rem;

}


.prgrm_content {
  margin-bottom: 20px;
}

.title--program {
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.3rem;
}


// Student Card Stylin in Parent 

.student_container {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  align-items: flex-start;
}

.student__card {
  width: 100%;
  max-width: 320px;
  height: 120px;
  margin-bottom: 20px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.student_flexbox {
  display: flex;
  align-items: center;
}

.student_pic {
  flex-shrink: 0;
  margin-right: 20px;
}

.student_image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}

.detail {
  flex-grow: 1;
}

.name {
  margin-bottom: 5px;
}

.para {
  margin: 0;
}

.student_flx {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.student_pic {
  img {
    width: 40px;
    height: auto;
  }
}

.btnFlex {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.btnActions {
  margin-left: auto;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
}

.Table {
  display: table;
  width: 100%;
}

.Title {
  display: table-caption;
  text-align: center;
  font-weight: bold;
  font-size: larger;
}

.Heading {
  display: table-row;
  font-weight: bold;
  text-align: center;
  padding: 1rem 0.25rem;
  background-color: #5497C7;
  color: white;
  border: none;
  border-radius: 2px solid;

  p {
    padding: 6px;
  }
}

.Row:nth-child(odd) {
  background-color: rgba(134, 134, 134, 0.1490196078);
}

.Row:nth-child(even) {
  background-color: white;
  border: 2px solid;
  border-bottom-left-radius: 2px;

}

.Row {
  display: table-row;
  background-color: white;
  padding: 5px;
}


.Cell {
  text-align: center;
  display: table-cell;
  border: none;

  border: 1px solid white;
  border-bottom-left-radius: 3px;

  // border: gray;
  // border-width: thin;
  // border: 1px solid gray;
  p {
    font-size: 0.9rem;
    padding: 10px;
  }
}

.Cell:last-child {
  width: 10%;
}


.Heading_style {
  cursor: pointer;
}


.status_input {
  cursor: pointer;
  width: 150px;
  font-size: 14px;
  padding: 4px 8px;
}


.terms {
  padding: 20px;
  width: 17px;
  height: 20px;
}


.footer_gymtime_guy_icon {
  position: relative;

  @media screen and (max-width: 1920px) {
    top: 25px !important;
    left: 2px;
  }

}


.payment_container {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin: -10px 38px;
}

.cof_dropdown {
  width: fit-content;
  left: -11px;
}

.cof_column {
  display: flex;
  flex-direction: column;
  left: -11px;
}

.cof_container {
  display: flex;
  align-items: center;
  width: fit-content;
  position: relative;
  right: 20px;
}


.class_container {
  width: 100%;

}

.class_name_input {
  width: 100%;
  max-width: 250px;
}


.label_container {
  display: flex;
  align-items: center;

}

.first_column {
  width: 350px !important;
  min-width: 350px;
  max-width: 250px !important;
  text-align: center;
}

.first_column_label {
  width: 350px !important;
  min-width: 440px;
  max-width: 250px !important;
}


.rec_label {
  font-size: 1rem;
  font-weight: 600;
}

.rec_div {
  min-width: 200px;
  background: "red";
}



.react_custom_menu {
  width: 100%;
  max-width: auto;
  height: 80px;
  height: 44px;
  padding: 0.25rem 0.75rem;
  border: 1px solid #dadafd;
  border-radius: 0.5rem;
  cursor: pointer;
  appearance: none;
  background: #f1f1ff;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: normal;
  min-width: 250px;
}


// FOR MOBILE SCREEN REMOIVING GMIMR GUY IN BDAY 
@media (max-width: 760px) {
  .gymtim_guy_logo {
    display: none;
  }
}

.bday_flex {
  @media screen and (max-width: 435px) {
    max-width: 91px;
  }

  @media screen and (max-width: 390px) {
    max-width: 50px !important;
  }
}


.dj_div .dj_div {
  width: 100%;

  h2 {
    font-size: 1rem;
    line-height: 0.9rem;
  }

  @media screen and (max-width: 435px) {
    width: 100%;
    font-size: 1rem;
  }
}

.team_cheer_div {
  @media screen and (max-width: 435px) {
    padding: 0px 25px;
  }
}

.bday_flex {
  min-width: 160px;

  @media screen and (max-width: 393px) {
    min-width: 30px !important;
  }

}


.dance_icon {

  @media screen and (max-width: 630px) {
    height: 84px !important;
    width: 100%;
  }
}



.djSection {
  @media screen and (max-width: 393px) {
    gap: 0px;
  }

}



.bday_flex__col {
  img {
    width: 75px;
    height: 70px;

    @media screen and (max-width: 435px) {
      margin-top: 16px;
      margin-left: 24px;
      width: 35px;
      height: 31px;

    }

    @media screen and (max-width: 393px) {
      margin-top: 17px;
      margin-left: 24px;
      width: 35px;
      height: 28px;
    }

    @media screen and (max-width: 390px) {
      margin-top: 17px;
      margin-left: 24px;
      width: 35px;
      height: 31px;

    }
  }

  h2 {
    font-size: 1.4rem;
  }
}




// IPHONE 15 Pro Max::

/* Target iPhone 14 Pro Max and smaller devices */



.aboutusSec {
  .container {
    @media screen and (max-width: 393px) {
      padding: 0px;
    }
  }
}

.enr_icon {
  height: 90px;
  // @media screen and (max-width: 390px) {
  //   height: 70px !important;
  //   position: relative !important;
  //   bottom: 6px !important;
  // }

}

.aboutusSec {
  @media screen and (max-width: 393px) {
    padding: 0px 35px;
  }
}

.cheer_guy {
  width: 120px;
  height: auto;

  @media screen and (max-width:450px) {
    width: 20% !important;
    height: auto;
  }
}

.cheer_picture {
  display: "flex" !important;
  align-items: "center" !important;
  gap: "15px" !important;
  padding: "0px 40px" !important;

}

.about-text {
  margin-top: 66px;

  @media screen and (max-width: 630px) {
    margin-top: 0px !important;
  }
}

.ourStaff {
  @media screen and (max-width: 630px) {
    margin-top: -61px;
  }
}

@media screen and (max-width: 430px) and (max-height: 932px) {


  //  Sections


  .aboutusSec {
    .container {
      padding: 0px 0px !important;

      @media screen and (max-width: 393px) {
        padding: 0px !important;
      }
    }

  }

  .pop_up_sec {
    padding: 0px 30px;
  }

  .program_picture,
  .shortText_prgram_detail {
    padding: 0px !important;

    font-size: 2.25rem;
    width: 100%;
    padding: 0px;
  }

  .class_det_head {
    padding: 0px 0px;

  }


  .dance_logo {
    width: 80px;
  }
}

.program_picture_responsive {
  @media screen and (max-width:450px) {
    margin-left: 0px;
  }
}