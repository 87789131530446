@use "./admin-variables" as *;

.Admin-modal {
  display: none;
  opacity: 0;
  visibility: hidden;
  background: rgba(0, 0, 0, 0.54);
  backdrop-filter: blur(8px);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1050;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  outline: 0;
  transition: opacity 0.3s ease-in-out;

  &__dialog {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 10vh auto;
    max-height: 80vh;

    &--lg {
      width: 90%;
      max-width: 950px;
    }

    &--md {
      width: 90%;
      max-width: 768px;

      .Admin-modal__footer {
        .admin-button__group {
          justify-content: flex-end;

          .adminbtn {
            width: fit-content;
            padding: 0.875rem 3.75rem;
          }
        }
      }
    }

    &--sm {
      width: 90%;
      max-width: 370px;
    }
  }

  &__content {
    background: white;
    width: 100%;
    overflow-y: auto;
    border-radius: 16px;
    box-shadow: 0px 4px 29px 0px rgba(0, 0, 0, 0.25);
    transform: translateY(-150%);
  }

  &__head {
    display: flex;
    justify-content: space-between;
    gap: 8px;
    // background-color: $primary;
    background-color: #5497C7;
    padding: 1rem 0.75rem 1rem 1.5rem;

    &--title {
      color: white;
      font-size: 1.5rem;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 0;
    }
  }

  &__close {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 21px;
    height: 21px;
    border-radius: 50%;
  }

  &__body {
    padding: 2rem 1.5rem 1.5rem;
  }

  &__footer {
    padding: 0 1.5rem 1.5rem;
  }

  &--open {
    display: flex;
    opacity: 1;
    visibility: visible;

    .Admin-modal__dialog {
      .Admin-modal__content {
        transform: translateY(0);
        transition: all 0.3s ease;
      }
    }
  }

  &__warning {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;

    svg {
      flex: 0 0 46px;
    }

    span {
      color: $danger;
      font-size: 1.125rem;
      font-weight: 500;
      line-height: normal;
    }
  }

  .form__group {
    width: 100%;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .form__input,
  .form__select {
    border: 0;
    border-radius: 8px;
    background-color: #f1f1ff;
    color: $inputColor;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: normal;
    height: 44px;

    &::placeholder {
      color: $textDarkGray;
    }
  }

  .admin-button__group {
    .adminbtn {
      width: 100%;
    }
  }
}

.adminCampForm {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}


.class_name {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.campTable thead tr {
  color: white;
}



.custom-select__control {
  width: 100%;
  border-radius: 7px !important;
}

.important_field {
  color: red;
}

.dollar {
  position: absolute;
  top: 10px;
  left: 5px;
  width: 14px;
}

.fee_input {
  input {
    padding-left: 20px;
  }
}

.holiday_container_edit{
  display: flex;
  gap: 20px;
}
.btn_conatiner{
  margin: 10px 0px;
  float: right;
  display: flex;
  gap: 20px;
}