.calender_heading {
  font-size: 3rem;
  font-weight: 500;
  color: rgb(0, 187, 227);
}
.holiday_list_container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
}
.month_holiday_calender {
  width: 100%;
}
.calender_month_name {
  font-size: 2rem;
  font-weight: 800;
  color: #ea519c;
}
.holiday_container {
  display: flex;
  gap: 50px;
  margin-top: 15px;
}
.borderBotton-line {
  margin: 30px 0px;
  border-bottom: 5px solid rgb(224, 220, 220);
  border-radius: 10px;
  width: 100%;
}
.date_container,
.holiday_name {
  width: 30%;
}
.holiday_calender_desclaimer {
  font-family: Poppins, sans-serif;
  font-size: 1.5rem;
  font-weight: 500;
  margin-top: 1rem;
  text-align: center;
}



.holiday_list_container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  width: 100%;
}

.month_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.month_holiday_calender {
  flex-grow: 1; /* Ensure it takes up available space */
}

.borderBotton-line {
  margin: 30px 0px;
  border-bottom: 5px solid rgb(224, 220, 220);
  border-radius: 10px;
  width: 100%;
}

/* Responsive styles */
@media (max-width: 768px) {
  .holiday_list_container {
    grid-template-columns: 1fr; /* Stack items vertically */
    gap: 20px;
  }

  .month_holiday_calender,
  .holiday_container {
    width: 100%;
  }

  .holiday_container {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }

  .date_container,
  .holiday_name {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .calender_heading {
    font-size: 2rem;
  }

  .calender_month_name {
    font-size: 1.5rem;
  }

  .holiday_calender_desclaimer {
    font-size: 1rem;
  }

  .holiday_container {
    gap: 10px;
    flex-direction: row;
  }

  .borderBotton-line {
    margin: 20px 0px;
    border-bottom: 3px solid rgb(224, 220, 220);
  }
}



/* ===================================Static Calender styling====================== */
/* @import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@500&display=swap");
.holiday_list_container {
  font-family: "Poppins", sans-serif;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(501px, 1.5fr));
  gap: 16px;
  font-size: 0.838rem;
}

.month_holiday_container {
  font-family: "Poppins", sans-serif;
  border-bottom: 4px solid #ddd;
  padding: 16px;
  border-radius: 4px;
}

.holiday_entry {
  font-family: "Poppins", sans-serif;
  margin-bottom: 11px;
  display: flex;
  gap: 1rem;
}
.holiday_event {
  font-style: normal;
}
.calender_heading {
  color: #00bbe3;
  font-family: "Poppins", sans-serif;
  font-size: 3rem;
  font-weight: 500;
}
.month_name_container {
  font-family: "Poppins", sans-serif;
  display: flex;
  gap: 1rem;
}
.calender_icon {
  color: #ea519c;
}
.month_name {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 2.2rem;
  color: #ea519c;
}
.holiday_date {
  color: #5e5f5f;
  flex: 0 0 6rem;
  width: 6rem;
}
.holiday_day {
  color: #5e5f5f;
  flex: 0 0 7rem;
  width: 7rem;
}
.holiday_event{
  color: #5e5f5f;
}
.holiday_calender_desclaimer {
  font-family: "Poppins", sans-serif;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 500;
  margin-top: 1rem;
}
.calenderBox {
  margin-top: 1rem;
}
@media screen and (max-width: 1365px) {
  .calender_heading {
    font-size: 3.5rem;
  }
}
@media screen and (max-width: 1199px) {
  .calender_heading {
    font-size: 3rem;
  }
  .month_name {
    font-size: 1.25rem;
  }
}
@media screen and (max-width: 991px) {
  .calender_heading {
    font-size: 2rem;
  }
  .month_name_container,
  .holiday_entry {
    gap: 10px;
  }
}
@media screen and (max-width: 767px) {
  .calender_heading {
    font-size: 1.375rem;
  }
  .month_name {
    font-size: 1.125rem;
    margin-bottom: 10px;
  }
  .calenderBox h5 {
    font-size: 0.875rem;
  }
  .holiday_calender_desclaimer {
    font-size: 1.125rem;
    margin-bottom: 30px;
    margin-top: 0;
  }
  .month_holiday_container {
    padding: 10px 0;
  }
  .holiday_list_container {
    font-size: 0.813rem;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1.5fr));
  }
} */
