@use "./admin-variables" as *;

.Adminpage {
  width: 100%;
  height: 100%;
  overflow: auto;

  &__section {
    width: 100%;
    height: calc(100% - 63px);
    background-color: $white;
    border-radius: 12px;
    margin-top: 4px;
    padding: 0.875rem 0.7rem;
  }

  &__title {
    color: $textColor;
    font-size: 1rem;
    font-weight: 600;
    line-height: normal;
    margin: 0 0 6px 0;
  }

  &__head {
    @include flex(center, space-between);
    gap: 8px;
  }

  &__body {
    margin: 2px 0 0 0;
    overflow-y: auto;
    // height: calc(100vh - 300px);
    height: calc(100vh - 267px);

    /* width */
    &::-webkit-scrollbar {
      height: 8px;
      border-radius: 4px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: $gray;
      border-radius: 2px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #ccc;
      border-radius: 2px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: $primary;
    }
  }

  &__filter {
    @include flex(center, flex-start);
    gap: 8px;
  }
}

table {
  width: 100%;

  tr {

    th,
    td {
      vertical-align: middle;
      // min-width: 200px;

      &:last-child {
        min-width: -1px;
      }
    }
  }

  thead {
    tr {
      th {
        padding: 0.875rem 0.625rem 1.75rem;
        background: #5497c7;

        &:first-child {
          border-top-left-radius: 8px;
          padding-left: 1.5rem;
        }

        &:last-child {
          border-top-right-radius: 8px;
          padding-right: 1.5rem;
        }

        span {
          text-align: left;
          color: $white ;
          font-size: 1rem;
          font-weight: 600;
          line-height: normal;
          @include flex(center, flex-start);
          gap: 6px;

          &.actions {
            justify-content: flex-end;
          }
        }
      }
    }
  }

  tbody {
    position: relative;
    // top: -10px;

    tr {

      td {
        color: #868686;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding: 0.75rem 0.625rem;

        &:first-child {
          padding-left: 1.5rem;
        }

        &:last-child {
          padding-right: 1.5rem;
        }

        span {
          text-align: left;
          // color: $textColor;
          // font-size: 0.875rem;
          // font-weight: 500;
          // line-height: normal;
          // color: #868686;
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;

          &.attendance {
            display: flex;
            gap: 12px;
            flex-wrap: wrap;

            em {
              font-style: normal;
            }
          }
        }
      }

      &:nth-child(odd) {
        td {
          background: #86868626;

          &:first-child {
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
          }

          &:last-child {
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
          }
        }
      }
    }
  }

  .button__group {
    justify-content: flex-end;
    gap: 32px;

    .btn {
      padding: 0;
      background-color: transparent;
      border: 0;
      height: auto;
    }
  }
}

/* Basic styles for the accordion */
.accordion {
  width: 100%;
  margin: 0 auto;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;

    .item {
      .title {
        font-size: 1rem;
        font-weight: 500;
        color: $textDarkGray;
        margin-bottom: 0;
      }

      .btn-transparent {
        font-size: 0.875rem;
        font-weight: 500;
        color: $secondary;
      }
    }
  }

  &__tableCard {
    margin-bottom: 1rem;
  }
}

/* Styles for each accordion item */
.accordion-item {
  margin-bottom: 10px;
  border-radius: 5px;
  overflow: hidden;
}

.accordion-button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #f0f0f0;
  text-align: left;
  cursor: pointer;
  border: none;
  outline: none;
}

/* Styles for the accordion content */
.accordion-content {
  padding: 0 10px;
  background-color: #fff;
  max-height: 0;
  /* Start with content hidden */
  overflow: hidden;
  transition: max-height 0.3s ease;
  /* Slide-down animation */
  border-radius: 8px;
}

/* Toggle content when button is clicked */
.accordion-item.active .accordion-content {
  max-height: 1000px;
  /* Show content with max height */
}

.attendence_table_Entity_item {
  font-size: 0.85rem;
}

// attendence input checkbox toggiling
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ed7d7d;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked+.slider {
  background-color: #2ba319;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.slider_not_marked {
  position: absolute;
  cursor: pointer;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #d7cbcb;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider_not_marked:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked+.slider_not_marked {
  background-color: #c3d940;
}

input:focus+.slider_not_marked {
  // box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider_not_marked:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider_not_marked.round {
  border-radius: 34px;
}

.slider_not_marked.round:before {
  border-radius: 50%;
}

.tableNoData {
  margin-top: 20px;
}

.myProfileWrap {
  display: flex;
  align-items: center;
  gap: 50px;

  .pic {
    flex: 0 0 210px;
    width: 210px;
    height: 210px;
    overflow: hidden;
    position: relative;
    border-radius: 50%;

    &__img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .uploadInput {
      display: none;
    }

    .btnUpload {
      background: rgba($black, 0.3);
      padding: 1.25rem 1rem;
      position: absolute;
      font-size: 0.938rem;
      font-weight: 500;
      color: $white;
      left: 0;
      right: 0;
      bottom: 0;
      text-align: center;
      z-index: 1;
      cursor: pointer;
    }
  }

  .detailsInfo {
    width: calc(100% - 210px);

    .page__title {
      font-size: 1.5rem;
      font-weight: 600;
      color: #f7659b;
    }

    .flexbox {
      gap: 30px;

      .adminform__group {
        position: relative;

        .adminform__input {
          background: rgba(#868686, 0.15);
          font-size: 1rem;
          color: #989898;
          height: 48px;
        }

        .adminbtn {
          background: #e2f8ff;
          font-size: 0.625rem;
          font-weight: 500;
          color: #5d60ef;
          position: absolute;
          right: 10px;
          top: 8px;
          height: 32px;
          z-index: 1;
        }
      }
    }
  }
}

.accordion {
  &__card {
    background: rgba(#868686, 0.15);
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 1rem;

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.875rem 1.25rem;
      background: linear-gradient(180deg, #5d60ef 0.52%, #b2b4f9 98.44%);
      font-size: 1rem;
      font-weight: 600;
      color: #fff;
      gap: 10px;
      border-radius: 8px;

      .title {
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 1rem;
        font-weight: 600;
        color: #fff;
        margin-bottom: 0;
      }

      .actions {
        display: flex;
        align-items: center;
        gap: 12px;

        &__btn {
          display: flex;
          align-items: center;
          background: transparent;
          border: none;
        }
      }
    }

    &__body {
      background: #fff;
      padding: 0.875rem 1.25rem;
      border-radius: 8px;
      margin: 1rem;
      display: none;

      .title {
        font-size: 0.875rem;
        font-weight: 400;
        color: #313131;
        margin-bottom: 1.5rem;
      }

      .commonList {
        &__row {
          display: flex;
          gap: 20px;
          margin-bottom: 2rem;
          flex-wrap: wrap;

          &__col {
            display: flex;
            gap: 15px;
          }
        }

        .Adminform__group {
          width: 100%;
          margin-bottom: 1rem;
        }

        .cardbox {
          width: 100%;

          .browseFile {
            padding: 1rem;
            border: 1px solid #939393;
            width: 180px;
            min-height: 160px;
            text-align: center;
            border-radius: 0.5rem;

            &__icon {
              display: flex;
              align-items: center;
              justify-content: center;
              margin-bottom: 0.5rem;
            }

            &__text {
              font-size: 0.75rem;
              font-weight: 400;
              color: #334155;
              margin-bottom: 0.5rem;
            }

            &__ortext {
              font-size: 0.625rem;
              font-weight: 400;
              color: #334155;
              margin-bottom: 0.5rem;
            }

            .browseBtn {
              display: inline-block;
              vertical-align: middle;
              background: transparent;
              padding: 0.25rem 1rem;
              font-size: 0.525rem;
              font-weight: 500;
              color: #0475d7;
              line-height: normal;
              transition: all 0.5s ease;
              border: 1px solid #0475d7;
              border-radius: 50px;
              margin-bottom: 0.5rem;
              cursor: pointer;
            }

            .hiddenInput {
              display: none;
            }

            .browseFileData {
              font-size: 0.625rem;
              font-weight: 400;
              color: #a1a1aa;

              &__text {
                font-size: 0.625rem;
                font-weight: 400;
                color: #a1a1aa;
                margin-bottom: 0.25rem;

                strong {
                  color: #334155;
                  font-weight: 600;
                }
              }
            }
          }

          .browseBox {
            width: 180px;
            min-height: 160px;

            .adminbutton__group {
              margin-top: 1rem;

              .adminbtn {
                padding: 0.5rem 1rem;
                min-width: 90px;
                height: 36px;
              }
            }
          }
        }

        .label {
          font-size: 0.875rem;
          font-weight: 700;
          color: #4F4F4F;
          min-width: 145px;
        }

        .Adminbutton__group {
          .adminbtn {
            padding: 0.5rem 1rem;
            min-width: 100px;
            height: 36px;
          }
        }
      }
    }

    &.active {
      .accordion__card__body {
        display: block;
      }
    }
  }
}

.Adminpage__sections {
  background-color: white;
  margin: 1.5rem 1.5rem 1.5rem 1.5rem;
  border-radius: 1.5rem;
}

.myProfileWrap {
  display: flex;
  align-items: center;
  padding: 30px;
}

.BrowseByProgramManegebtn {
  background-color: #5d60ef;
  border-radius: 5px;
  padding: 3px 5px;
  margin-right: 1rem;
  color: white;
  margin-left: 10px;
}

.hide-btn-manage-class {
  margin-left: 10px;
}

.hide-btn-manage-class img {
  height: 25px;
}

.prevImage {
  height: 75px;
  width: 100%;
  object-fit: cover;
}

.attendance_box_map {
  display: flex;
  gap: 1.5rem;
}

.attendance_box {
  height: 30px;
  width: 50px;
  border: 3px solid black;
  display: flex;
  // align-items: center;
  justify-content: center;
}

.rollsheet_date {
  font-size: 10px;
}

.tabs__card_attendance {
  cursor: pointer;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 2px solid #0475d7;
  border-radius: 5px;
  min-width: 150px;
  width: 155px;
  height: 101px;
  padding: 1rem;

  &-active {
    cursor: pointer;
    margin-top: 30px;
    min-width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    // border: 2px solid black;
    border-radius: 5px;
    width: 160px;
    height: 100px;
    padding: 10px;
    background-color: #5497c7;
    color: white;
    transition: 0.2s all ease-in-out;
  }
}

.tabs__card_attendance img {
  height: 40px;
  width: 150px;
}

.tabs__card_attendance-active img {
  height: 40px;
  width: 120px;
}

.radio_class_rollSheet_list {
  display: flex;
  overflow-x: auto;
  gap: 20px;
  margin-bottom: 10px;
  padding-bottom:10px ;
}

.tabs__rollSheet__text {
  font-weight: 700;
  font-size: 0.65rem;
}

///input
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.attendance_subtitle {
  display: flex;
  gap: 1rem;
}

.attendance_subtitle_info {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.attendance_subtitle_heading {
  font-weight: 600;
}

@media print {
  .page-break {
    page-break-before: always;
  }
}

.loader_container {
  display: flex;
  justify-content: center;
}

.Edit_program_images {
  background: linear-gradient(180deg, #5497c7 2.08%, #5497c7 100%);
  float: right;
  color: white;
  padding: 0.75rem 1rem;
  border-radius: 8px;
  margin: 5px;
}

.edit_image_container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
}

.main_edit_program_image {
  height: 108px;
  width: 200px;
}

.hiddenInputfor_image_upload {
  display: none !important;
}

.image_edit_upload_btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border-radius: 8px;
  padding: 0.75rem 3rem;
  width: -moz-fit-content;
  width: fit-content;
  height: 44px;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: normal;
  transition: all 0.5s ease;
  border-style: solid;
}

.cms_master_menu_program_title {
  font-size: 1.2rem;
  font-weight: 600;
}

.header_adminform__input {
  width: 62px;
  height: 44px;
  padding: 0.625rem 0.75rem;
  border: 1px solid #dadafd;
  border-radius: 0.5rem;
  background-color: #F1F1FF;
  color: #3b3b3b;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: normal;
  min-width: 152px;
}

.color-list-item {
  display: flex;
  justify-content: space-between;
  padding: 5px 0px;
}

.color-item {
  height: 20px;
  width: 30px;
  background-color: #2ba319;
}

.drop-down .selected {
  width: 62px;
  height: 44px;
  padding: 0.625rem 0.75rem;
  border: 1px solid #dadafd;
  border-radius: 0.5rem;
  background-color: #F1F1FF;
  color: #3b3b3b;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: normal;
  min-width: 152px;
}

.color_options {
  top: 100%;
  left: 0;
  width: 100%;
  border: 1px solid #ccc;
  background-color: #F1f1ff;
  max-height: 130px;
  overflow-y: auto;
  border-radius: 10px;
  margin-top: 1px;
  margin-left: 2px;
}

.color_options::-webkit-scrollbar {
  width: 6px;
  border-radius: 20px;
}

.color_options::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 20px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.color_options::-webkit-scrollbar-track {
  background-color: #F1f1ff;
  /* Set the color of the track */
}

.browseFile_color_list {
  padding: 1rem;
  border: 1px solid #939393;
  width: 180px;
  min-height: 160px;
  text-align: center;
  border-radius: 0.5rem;
  height: 282px;
}

.add_holiday_calender_container{
  border: 0.3px solid rgb(201, 200, 200);
  border-radius: 10px;
  padding: 20px;
  background-color: rgb(245 244 244);
  margin-bottom: 30px;
}

.add_holiday_calender_container_row {
  margin-top: 1rem;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.addHoliday_calender_loader_container {
  height: 10rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.leder_payment_notes_temp {
  height: 150px !important;
  width: auto !important;
  overflow: auto !important;
}
.back_btn_cms{
  display: flex;
  margin-top: 20px;
  cursor: pointer;

  &:hover{
    color: pink;
  }
}
.breadcrumb__link_cms{
   height: 23px;
  &:hover{
    color: #ed98c1 !important;
  }
}