.holiday_calender_container {
}
.session_container {
  background-color: rgba(134, 134, 134, 0.15);
  padding: 30px;
  border-radius: 20px;
  margin-bottom: 20px;
}
.input_text_field_season {
  display: flex;
  gap: 3rem;
}
.all_month_holiday_calender {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.holiday_months_container {
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  margin: 10px 0px;
}
.month_holidays_container{
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0px;
  gap: 30px;
}
