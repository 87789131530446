.page {
  page-break-after: always;
}

@media print {
  body {
    transform: scaleX(0.8);
    transform-origin: top left;
    page-break-inside: avoid;
  }
  @page {
    sheet-size: A3-L;
    margin: 10px;
  }
  html,
  body {
    width: 115%;
    height: 100%;
    overflow: visible;
    margin: 0px;
  }
  .no-print {
    display: block;
  }
  .no-page-break {
    /* display: inline-block; */
    width: 100%;
    page-break-inside: avoid;
  }
  .attendance_box_map {
    display: flex;
    /* flex-wrap: wrap; */
    /* margin-top: 20px; */
  }
.meta_data_col,.teacher_rollsheet_list_container_fluid{
  margin-top:-20px ;
}
  .attendance_box_classname {
    display: inline;
    font-size: 1.3rem;
    font-weight: 700;
  }
 .roll_sheet_meta_data{
  line-height: 25px;
 }
}
