.title {
  font-size: 4rem;
  font-weight: 700;

  @media screen and (max-width:1199px) {
    font-size: 3rem;
  }

  @media screen and (max-width:991px) {
    font-size: 2rem;
  }

  @media screen and (max-width:639px) {
    font-size: 1.875rem;
  }

  &--light {
    font-weight: 300;
  }

  &--regular {
    font-weight: 400;
  }

  &--medium {
    font-weight: 500;
  }

  &--extrabold {
    font-weight: 800;
  }

  &--h1 {
    font-size: 4rem;

    @media screen and (max-width:1199px) {
      font-size: 3rem;
    }

    @media screen and (max-width:991px) {
      font-size: 2rem;
    }

    @media screen and (max-width:639px) {
      font-size: 1.875rem;
    }
  }

  &--h2 {
    font-size: 3rem;

    @media screen and (max-width:1199px) {
      font-size: 2rem;
    }

    @media screen and (max-width:991px) {
      font-size: 1.875rem;
    }

    @media screen and (max-width:639px) {
      font-size: 1.5rem;
    }
  }

  &--h3 {
    font-size: 2rem;

    @media screen and (max-width:1199px) {
      font-size: 1.5rem;
    }

    @media screen and (max-width:991px) {
      font-size: 1.25rem;
    }

    @media screen and (max-width:639px) {
      font-size: 1.2rem;
    }
  }

  &--h4 {
    font-size: 1.5rem;

    @media screen and (max-width:1199px) {
      font-size: 1.25rem;
    }

    @media screen and (max-width:991px) {
      font-size: 1.20rem;
    }

    @media screen and (max-width:639px) {
      font-size: 1.15rem;
      background-color: red;
    }
  }

  &--h5 {
    font-size: 1rem;
  }

  &--h6 {
    font-size: 0.8rem;
  }
}


// handling

.submenu {
  display: none;
  flex-direction: column;
}

.submenu--active {
  display: flex;
  flex-direction: column;
  font-size: 0.813rem;
}

.column{
  display: flex;
  flex-direction: column;
}