@use "./admin-variables"as *;

.adminbutton {
  &__group {
    @include flex(center, flex-start);
    gap: 8px;
  }
}

.adminbtn {
  @include flex(center, center);
  gap: 8px;
  border-radius: 8px;
  padding: 0.75rem 1rem;
  width: fit-content;
  height: 44px;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: normal;
  transition: all 0.5s ease;
  border-style: solid;

  svg {
    transition: all 0.5s ease;

    path {
      transition: all 0.5s ease;
    }
  }

  &-sm {
    @include flex(center, center);
    width: 50px;
    height: 50px;
  }

  &-rounded {
    border-radius: 50%;
  }

  &-primary {
    // background: linear-gradient(180deg, #8789fd 2.08%, #5d60ef 100%);
    background: linear-gradient(180deg, #5497C7 2.08%, #5497C7 100%);
    color: $white;
    // border-color: #8789fd;
    border-color: #00ace5;

    &:hover {
      background: #e2f8ff;
      // color: #5d60ef;
      color: #00ace5;
      // border-color: #5d60ef;
      border-color: #00ace5;
      transition: all 0.5s ease;

      svg {
        path {
          // stroke: #5d60ef;
          stroke: #00ace5;
          transition: all 0.5s ease;
        }
      }
    }
  }

  &-invoice {
    // background: linear-gradient(180deg, #8789fd 2.08%, #5d60ef 100%);
    background: #c3d940;
    color: $white;
    // border-color: #8789fd;
    border-color: #00ace5;

    &:hover {
      background: #e2f8ff;
      // color: #5d60ef;
      color: #00ace5;
      // border-color: #5d60ef;
      border-color: #00ace5;
      transition: all 0.5s ease;

      svg {
        path {
          // stroke: #5d60ef;
          stroke: #00ace5;
          transition: all 0.5s ease;
        }
      }
    }
  }

  &-secondary {
    background-color: $secondary;
  }

  &-light {
    background: #e2f8ff;
    color: #5d60ef;
    border: 1px solid #5d60ef;
    margin-bottom: 10px;

    &:hover {
      background: linear-gradient(180deg, #5d60ef 2.08%, #5d60ef 100%);
      color: $white;
      border-color: #8789fd;
      transition: all 0.5s ease;
    }
  }

  &-blueOutline {
    background: #ffffff;
    // color: #5d60ef;
    color: #00ace5;

    border: 1px solid #5d60ef;

    &:hover {
      background: linear-gradient(180deg, #8789fd 2.08%, #5d60ef 100%);
      color: $white;
      border-color: #8789fd;
      transition: all 0.5s ease;

      svg {
        path {
          stroke: #fff;
          transition: all 0.5s ease;
        }
      }
    }
  }

  &-transparent {
    background: transparent;
    border: 0;
    color: $textDarkGray;

    &--blue {
      // color: #5d60ef;
      color: #00ace5;
    }
  }

  &-danger {
    background: $danger;
    color: $white;
    border-color: $danger;

    &:hover {
      background-color: $white;
      color: $danger;
      transition: all 0.5s ease;

      svg {
        path {
          stroke: $danger;
        }
      }
    }
  }
}


.btnWithCheckBox {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.btnMarkAsRead {
  background: $white;
  font-size: 0.875rem;
  color: $black;
  font-weight: 400;
  border-radius: 20px;
  padding: 0.5rem 1rem;
  display: flex;
  gap: 8px;
  min-width: 160px;
  justify-content: center;
  align-items: center;
}


.cms_program_search_btn {
  margin-top: 0px !important;
}

.adminformRadiobox {
  display: flex;
  align-items: center;
  gap: 6px;
  margin-bottom: 10px;
  font-size: 0.875rem;
  color: $black;
  font-weight: 400;
}