.modalHeaderContainer{
    display: flex;
    justify-content: space-between;
}
.closeProgramEditModal{
    /* background-color: gray; */
    border-radius: 50%;
    border:3px solid white ;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 7px;
    color: white;
    font-weight: 900;
}
.programSelectedFile{
    font-size: 13px;
    font-weight: 600;
}