@use './variables' as *;

.breadcrumbSec {
  max-width: 90%;
  padding: 1rem 6rem;

  @media screen and (max-width:767px) {
    padding-bottom: 0;
  }
  @media screen and (max-width:450px) {
    padding: 10px;
  }
}

.breadcrumb {
  max-width: 100%;
  display: flex;
  align-items: center;
  background-color: white;
  margin-bottom: 0;
  padding: 0;

  &__item {
    display: flex;
    align-items: center;
    line-height: 0;

    &::before {
      content: url('../../images/breadcrumb-spacer.svg');
      display: inline-block;
      vertical-align: middle;
      position: relative;
      margin-left: -11px;
    }
  }

  &__link {
    font-size: 1.75rem;
    color: rgba($black, 58%);
    line-height: 1;

    @media screen and (max-width:991px) {
      font-size: 1.25rem;
    }

    &:hover {
      color: $pink;
      text-decoration: none;
    }

    &:not([href]) {
      &:hover {
        color: rgba($black, 58%);
      }
    }
  }
}
.breadcrumbSec_cms{
  // width: 90% !important;
  padding:10px 0px !important;
}