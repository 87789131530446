/* iPhone 12 Pro */
@media only screen and (min-device-width: 390px) and (max-device-width: 844px) and (-webkit-device-pixel-ratio: 3) {
    .shortText {
        p {
            font-size: 1rem;
            line-height: 1.6rem;
        }
    }

  .login_modal_content{
    width: 100%;
  }

    .staffSec .ourStaff {
        padding: 0px 0px !important;
        text-align: left !important;
    }

    .ourStaff__topblock {
        h2 {
            font-size: 1.2rem !important;
        }
    }

    .classAvailability__header {
        h2 {
            font-size: 2rem !important;
        }
    }

    .class_details_shortText_container {
        font-size: 1rem;
        line-height: 1.6rem;
    }

    .content {
        .shortText {
            p {

                &:last-child {
                    width: 100%;
                    font-size: 1rem !important;
                    line-height: 1.6 !important;
                    text-align: justify;
                }
            }
        }
    }

    .bday_Div {
        padding: 0px 10px !important;
    }

    .extr-Act {
        padding: 0px 35px !important;
    }


    .aboutusSec .aboutData .content .shortText p:last-child {
        width: 100%;
        font-size: 1rem !important;
        line-height: 1.6rem !important;
        text-align: left;

    }


    .aboutusSec .aboutData .picture--cliplayerBlue::after {
        bottom: -45px !important;
    }


    .aboutusSec .aboutData .picture--cliplayerPink::after {
        bottom: -70px !important;
    }

    .classAvailability_container_fluid .classAvailability {
        width: 750px !important;
        padding: 0px 2px !important;
    }

    .aboutusSec .aboutData {
        // padding: 0px 35px !important;
        line-height: 35px;
        font-size: 1.2rem;
        text-align: justify;
    }

    .pop_col_paratext_container .pop_col_paratext {
        font-size: 1rem;
        font-weight: 400;
        padding: 0px 10px;
        line-height: 1.6rem;
        text-align: justify;
    }

    .aboutusSec .aboutData .picture iframe {
        height: 190px !important;
        margin-top: 22px !important;
    }

    .aboutusSec .aboutData .picture {
        padding: 0px 0px 35px 0px !important;
        min-height: 265px !important;
    }

    .extra_act_resp , .parentForm2{
        padding: 0px 35px !important;
    }
}




/* iPhone 14 Pro Max */
@media only screen and (min-device-width: 430px) and (max-device-width: 932px) and (-webkit-device-pixel-ratio: 3) {
    .extra_act_resp{
        padding: 0px 35px ;
    }
    .aboutusSec .aboutData .picture--cliplayerPink::after {
        width: 160px;
        height: 92px;
        bottom: -49px !important;
    }

    .birthdaySec {
        padding: 30px 35px !important;
    }

}




@media (max-width: 430px) {
 .login_modal_content{
    width: 100%;
 }
 
}
