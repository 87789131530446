@use "./admin-variables"as *;

$field-height: 44px;
$field-height-sm: 40px;
$field-font-size-sm: 0.8rem;
$field-bg: #f1f1ff;
$field-radius: 0.5rem;
$field-full-radius: 1.5rem;
$field-padding-x: 0.75rem;
$field-padding-y: 0.625rem;
$label-margin-bottom: 0.25rem;

.adminform {
  .label {
    color: #4f4f4f !important;
  }

  &__group {
    position: relative;

    &__grayBox {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: $field-height;
      padding: $field-padding-y $field-padding-x;
      border: 1px solid $border;
      border-radius: $field-radius;
      background-color: #f1f1ff;
      color: $inputColor;
      font-size: 0.75rem;
      font-weight: 400;
      line-height: normal;
      min-width: 200px;
    }

    &--mb16 {
      margin-bottom: 1rem;
    }
  }

  &__input {
    width: 100%;
    height: $field-height;
    padding: $field-padding-y $field-padding-x;
    border: 1px solid $border;
    border-radius: $field-radius;
    background-color: $field-bg;
    color: $inputColor;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: normal;
    min-width: 200px;

    &--fullrounded {
      border-radius: $field-full-radius;
      padding: $field-padding-y $field-padding-x * 1.5;
    }

    &--sm {
      height: $field-height-sm;
      font-size: $field-font-size-sm;
    }
  }

  // Date picker
  &__date {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: $field-height;
    padding: $field-padding-y $field-padding-x $field-padding-y $field-padding-x * 2.5;
    border: 1px solid $gray;
    border-radius: $field-radius;
    background: #f1f1ff url("../../../../Parent/parent-assets/images/calender.svg") 1rem center no-repeat;
    background-size: 16px;

    &--sm {
      height: $field-height-sm;
      font-size: $field-font-size-sm;
    }

    &::-webkit-calendar-picker-indicator {
      background: transparent;
      color: transparent;
      cursor: pointer;
      height: auto;
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      top: 0;
      width: auto;
    }
  }

  &__select {
    width: 100%;
    height: $field-height;
    padding: $field-padding-y $field-padding-x;
    border: 1px solid $border;
    border-radius: $field-radius;
    cursor: pointer;
    -webkit-appearance: none;
    appearance: none;
    background: #f1f1ff url("../../images/icons/arrow-down-black.svg") right 1rem center no-repeat;
    color: $inputColor;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: normal;
    min-width: 200px;

    &--fullrounded {
      border-radius: $field-full-radius;
      padding: $field-padding-y $field-padding-x * 1.5;
    }

    &--sm {
      height: $field-height-sm;
      font-size: $field-font-size-sm;
    }
  }

  &__textarea {
    width: 100%;
    height: $field-height;
    min-height: 120px;
    padding: $field-padding-y $field-padding-x;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: normal;
    border: 1px solid $gray;
    border-radius: $field-radius;
    background-color: $field-bg;

    &--fullrounded {
      border-radius: $field-full-radius;
      padding: $field-padding-y $field-padding-x * 1.5;
    }

    &--sm {
      height: $field-height-sm;
      font-size: $field-font-size-sm;
    }
  }

  &__checkbox {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 1rem;

    &__option {
      display: flex;
      align-items: flex-start;
      position: relative;
      margin-right: 15px;
      margin-bottom: 10px;
    }

    &__label {
      position: relative;
      font-size: 0.875rem;
      color: #696f79;
      padding-left: 30px;
      cursor: pointer;

      &::before {
        content: "";
        position: absolute;
        width: 18px;
        height: 18px;
        left: 0;
        top: 0;
        border: 1px solid $border;
        border-radius: 6px;
      }
    }

    &__labelText {
      font-size: 0.875rem;
      color: #696f79;
    }

    &__input {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      opacity: 0;
      z-index: 1;
      cursor: pointer;

      &:checked {
        +.form__checkbox__label {
          &::before {
            background: #ea519c;
            border-color: #ea519c;
          }

          &::after {
            content: "";
            background: url(../../images/icons/check-icon.svg) no-repeat;
            background-position: center center;
            display: inline-block;
            position: absolute;
            width: 8px;
            height: 8px;
            left: 6px;
            top: 6px;
            border-radius: 50px;
          }
        }
      }

      &--withoutlabel {
        width: 18px;
        height: 18px;
      }
    }
  }

  &__label {
    display: block;
    position: absolute;
    top: 24px;
    left: 16px;

    &--sm {
      font-size: $field-font-size-sm;
      margin-bottom: 0px;
    }
  }
}

.adminform__select_Order_Type {
  width: 200px;
  height: $field-height;
  padding: $field-padding-y $field-padding-x;
  border: 1px solid $border;
  border-radius: $field-radius;
  cursor: pointer;
  -webkit-appearance: none;
  background: #f1f1ff url("../../images/icons/arrow-down-black.svg") right 1rem center no-repeat;
  color: $inputColor;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: normal;
}

.export_btn_attendence {
  border: 1px solid #5d60ef;
  min-width: 70px;
}

.adminUser_Order_Type {
  margin-bottom: 1rem;
}

.react-datepicker-wrapper {
  width: 100%;
}

.adminViewToolTip {
  display: flex;
  align-items: center;
  gap: 5px;

  &__item {
    display: block;
    background: #f1f1f1;
    width: 100%;
    padding: 0.5rem 0.875rem;
    font-size: 0.65rem;
    color: $black;
    font-weight: 400;
    line-height: normal;
    border-radius: 5px;
    margin: 2px;

    &:first-child {
      min-width: 120px;
    }
  }
}

// multiSeelcte input Csss
.multiSelectInput {
  height: 40px;
  width: 100%;
  border: 1px solid $border;
  border-radius: $field-radius;
  background-color: $field-bg;
  color: $inputColor;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: normal;
  min-width: 200px;
  background-color: #f1f1ff;
}

.css-13cymwt-control {
  background-color: #f1f1ff;
  border-radius: 1px solid #f1f1ff;
}

.dropdown-heading {
  background-color: $field-bg;
}

// DRP

.custom-dropdown {
  position: relative;
  width: 400px;
}

.selected-option {
  padding: 10px;
  border: 1px solid #ccc;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}

.delete-icon {
  cursor: pointer;
  width: 30px;
  height: 30px;
}

.delete-icon:hover {
  color: red;
  border-width: 2px solid;
  border: 2px solid red;
}

.options {
  top: 100%;
  left: 0;
  width: 100%;
  border: 1px solid #ccc;
  background-color: #f1f1ff;
  max-height: 130px;
  overflow-y: auto;
  border-radius: 0px 4px;
}

.option {
  padding: 7px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}

.option:hover {
  background-color: #ccc;
}

// DATe picker setup for admin-- add student

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.create_calender_holiday_btn {
  display: flex;
  justify-content: flex-start;
}

.half_day_camp_container_fluid,
.full_day_camp_container_fluid {
  margin-bottom: 15px;
  margin-top: 10px;
}

.gallery_title {
  font-size: 1.5rem;
  font-weight: 400;
}

.fac_gallery_title_container {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;

  .gallery_title_sub h1 {
    font-size: 1.2rem;
    font-weight: 600;

    &:hover {
      color: #ea519c;
      transition: 0.2s ease-out;
    }
  }
}

.fac_image_gallery_conatiner {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.fac_cms_gallery {
  width: 100px;
}

.cms_birthday_amount_value {
  margin: 10px 0px;
}

.rsw-editor {
  border: 1px solid #ddd;
  border-radius: .375rem;
  display: flex;
  flex-direction: column;
  min-height: 400px !important;
  overflow: hidden;
}

.min-max-age-input {
  width: 100px !important;
}

.makeupBody {
  @media screen and (width: 2560px) and (height: 1600px) {
    height: 100%;
    overflow-y: scroll;
  }

  height:450px;
  overflow-y: scroll;
}

.heading_input_container{
  margin-top: 20px;
  display: flex;
  gap: 20px;
  width: 50%;
}
.email_submit-btn{
  margin-top: 10px;
}