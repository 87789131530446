// Varaibles
$primary:#EA512E;
$secondary:#00BBE3;
$blackColor:#000;
$black:#5E5F5F;
$white:#fff;
$blue:#0F294E;
$green:#C3D940;
$navyblue:#223D98;
$pink:#EA519C;
$orange:#F79727;
$purple:#593F98;
$succes:#00C871;
$lightgray:#696F79;
$gray:#8692A6;
$darkGray:#5E5F5F;
$darkGray2:#999999;
$border:#D6D6D6;
$red:#FF0000;

// Easing
$ease-time: 200ms;
$easing: ease-in-out;