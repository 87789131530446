.title {
  font-size: 2.5rem;
  font-weight: 700;

  @media screen and (max-width:1439px) {
    font-size: 2.625rem;
  }

  @media screen and (max-width:1199px) {
    font-size: 2rem;
  }

  @media screen and (max-width:991px) {
    font-size: 1.875rem;
  }

  @media screen and (max-width:639px) {
    font-size: 1.5rem;
  }

  &--light {
    font-weight: 300;
  }

  &--regular {
    font-weight: 400;
  }

  &--medium {
    font-weight: 500;
  }

  &--h1 {
    font-size: 3rem;
    margin-bottom: 20px;

    @media screen and (max-width:1439px) {
      font-size: 2.625rem;
    }

    @media screen and (max-width:1199px) {
      font-size: 2rem;
    }

    @media screen and (max-width:991px) {
      font-size: 1.875rem;
    }

    @media screen and (max-width:639px) {
      font-size: 1.5rem;
    }
  }

  &--h2 {
    font-size: 2.25rem;

    @media screen and (max-width:1199px) {
      font-size: 1.875rem;
    }

    @media screen and (max-width:991px) {
      font-size: 1.5rem;
    }

    @media screen and (max-width:639px) {
      font-size: 1.25rem;
    }
  }

  &--h3 {
    font-size: 1.75rem;

    @media screen and (max-width:1199px) {
      font-size: 1.5rem;
    }

    @media screen and (max-width:991px) {
      font-size: 1.25rem;
    }

    @media screen and (max-width:639px) {
      font-size: 1.2rem;
    }
  }

  &--h4 {
    font-size: 1.5rem;

    @media screen and (max-width:1199px) {
      font-size: 1.25rem;
    }

    @media screen and (max-width:991px) {
      font-size: 1.2rem;
    }

    @media screen and (max-width:639px) {
      font-size: 1.15rem;
    }
  }

  &--h5 {
    font-size: 1.5rem;
    font-weight: 500;
  }

  &--h6 {
    font-size: 0.8rem;
  }
}
