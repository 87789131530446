.rollSheet_pdf_container {
  overflow: auto;
  padding: 0px 10px;
}
.rollsheet_pdf_container td,
.rollsheet_pdf_container th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}
.rollsheet_pdf_container tr:nth-child(even) {
  background-color: #ffffff;
}

.class_pdf_container {
  margin-bottom: 4rem;
}
.pdf_table_heading {
  font-size: 1.45rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 10px;
  text-transform: capitalize;
}
.and_text {
  display: flex;
  align-items: center;
}
.roll_sheet_meta_data {
  display: flex;
  gap: 20px;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 40px;
  margin-bottom: 30px;
}
._rollsheet_title {
  font-size: 1.21rem;
  font-weight: 500;
}
.pdf_no_data_found {
  font-weight: 600;
  font-size: 1.41rem;
}
.rollsheet_pdf_container_heading_row th{
  background-color: rgb(119, 119, 119) !important;
  color: black;
}
.rollsheet_pdf_heading {
  text-align: center;
  font-weight: 700;
  font-size: 1.45rem;
}
.rollsheet_date_pdf{
  font-size: 0.8rem;
}
.rollsheet_pdf_table_row_data{
  font-size: 1.259rem;
  font-weight: 700;
  font-family: 'Times New Roman', Times, serif;
}
.make_up_class_pdf_content_title{
  margin-top: 1.6rem;
  margin-bottom: 1rem;
  font-size: 1.45rem;
  font-weight: 700;
  text-align: center;
}
.pdf_make_class_container thead tr th{
  background-color: rgb(226, 223, 223) !important;
  padding: 10px 20px;
}
.show_weekdays_btn{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: gray;
  font-weight: 400;
  margin-top: 22px;
  margin-bottom: 1px;
  font-size: 0.81rem;
  cursor: pointer;
}
.attendance_box_pdf{
  height: 30px;
  width: 75px;
  border: 2.5px solid black;
  display: flex;
  justify-content: center;
}
.selected_class_name_rollsheet{
  width: fit-content;
}
.attendance_box_classname{
  display: none;
}

.email_pdf{
  min-width:70px !important;
}
.age_pdf{
  min-width: 0px !important;
}
.student_allergies{
  font-size: 1rem;
  max-width: 200px;
}
.meta_data_col{
  display: flex;
  gap: 20px;
  align-items: center;
}
.email_pdf{
  min-width: none;
}
.rollsheet_tbody_container tr td{
  padding: 1px !important;
}
.rollsheet_header_date_caontainer{
  display: flex;
}
.rollsheet_date_pdf{
  width: 100px;
}

