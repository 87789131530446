.cmsBlog-title-input{
    width: 100%;
    padding: 0.625rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: normal;
    border: 1px solid #dddddd;
    border-radius: 0.5rem;
    background-color: #F1F1FF;
}

.adminbutton__group{
    margin-top: 15px;
    display: flex;
    gap: 15px;
}
.admibtn-primary{
    background:linear-gradient(180deg, #5497C7 2.08%, #5497C7 100%);;
    color: #ffffff;
    border-color: #8789fd;
    height: 36px;
    padding: 0.5rem 1rem;
    min-width: 90px;
    height: 36px;
    border-radius: 8px;
    
    &:hover{
        background: #e2f8ff;
        color: #5d60ef;
        border-color: #5d60ef;
        transition: all 0.5s ease;
    }
}
.admibtn-transparent{
    font-size: 15px;
    background: transparent;
    border: 0;
    color: #656565;
}
.add-blog-btn{
    padding: 0.5rem 1rem;
    font-weight: 500;
    border: 1px solid #5d60ef;
    min-width: 148px;
    border-radius: 10px;
    background: #e2f8ff;
    color: #5d60ef;

    &:hover{
        background-color: #5D60EF;
        color: #ffffff;
        border-color: #8789fd;
        transition: all 0.5s ease;
    }
}
.addBlogBtn_container{
    margin-top: 20px;
    margin-bottom: 10px;
    margin-right: 10px;
    display: flex;
    justify-content: flex-end;
}
.delete-btn{
    background-color: red !important;
    color: white !important;
    transition: all 0.5s ease !important;
}
.jodit .jodit-workplace .jodit-wysiwyg, .jodit .jodit-workplace .jodit-wysiwyg_iframe, .jodit-container .jodit-workplace .jodit-wysiwyg, .jodit-container .jodit-workplace .jodit-wysiwyg_iframe {
    background-color: #F1F1FF;
}