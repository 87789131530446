@use "../abstracts/variables"as *;
@use "../abstracts/grid"as *;

.footer {
  background: $navyblue;
  font-size: 1rem;
  color: $white;
  font-weight: 400;
  position: relative;
  -webkit-clip-path: polygon(0 0, 100% 5%, 100% 100%, 0 100%);
  clip-path: polygon(0 0, 100% 5%, 100% 100%, 0 100%);
  // height: 47vh;
  overflow: hidden;

  @media screen and (max-width: 767px) {
    font-size: 0.938rem;
  }

  .footerInner {
    display: flex;
    justify-content: center;
    position: relative;
  }

  &__top {
    padding: 80px 0 45px;
    position: relative;

    @media screen and (max-width: 767px) {
      padding-bottom: 25px;
    }

    @media screen and (max-width:435px) {
      padding: 80px 20px 45px;
    }

    &__flexbox {
      display: flex;
      gap: 70px;
      flex-wrap: wrap;
      padding: 0px;

      @media screen and (max-width: 1900px) {
        gap: 60px;
      }

      @media screen and (min-width: $lg) {
        flex-wrap: wrap;
      }

      @media screen and (max-width: 767px) {
        display: block;
      }
    }

    .shorttext {
      max-width: 760px;
      font-size: 1.125rem;
      font-weight: 300;
      line-height: 30px;
      margin-bottom: 15px;

      @media screen and (max-width: 991px) {
        font-size: 1rem;
        line-height: 25px;
      }
    }
  }

  &__contactInfo {
    padding-top: 50px;
    font-weight: 300;

    @media screen and (max-width: 767px) {
      padding-top: 15px;
    }

    &__item {
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__title {
      font-size: 0.875rem;
      margin-bottom: 3px;
    }

    &__link {
      display: inline-block;
      vertical-align: middle;
      text-decoration: none !important;
      color: $blackColor;
      -webkit-transition: all 0.3s ease-out;
      transition: all 0.3s ease-out;

      &:hover {
        color: $primary;
      }
    }
  }

  &__col {
    // width: calc(22% - 50px);

    @media screen and (max-width: $xl) {
      width: calc(22% - 25px);
    }

    @media screen and (max-width: $lg) {
      width: calc(33.333% - 25px);
    }

    @media screen and (max-width: 767px) {
      width: 100% !important;
      margin-bottom: 35px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &:first-child {
      width: 34%;

      @media screen and (max-width: $lg) {
        width: 100%;
        margin-bottom: 35px;
      }
    }

    &:nth-child(3) {
      @media screen and (max-width: 767px) {
        margin-bottom: 0;
      }
    }
  }

  &__logo {
    max-width: 215px;
    margin-bottom: 1.563rem;

    @media screen and (max-width: $lg) {
      margin-bottom: 20px;
    }

    &__img {
      display: inline-block;
      vertical-align: middle;
      max-width: 100%;
    }
  }

  &__text {
    font-size: 0.851rem;
    font-weight: 500;
    line-height: 28px;
    color: $white;
    margin-bottom: 1.25rem;

    @media screen and (max-width: 767px) {
      font-size: 0.875rem;
    }

    &__para {
      margin-bottom: 0.938rem;
      line-height: 28px;

      @media screen and (max-width: 767px) {
        line-height: 24px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__socialLink {
    display: flex;
    align-items: center;
    gap: 14px;

    &__item {
      display: block;
    }

    &__link {
      display: flex;
      justify-content: center;
      align-items: center;
      background: $pink;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      text-align: center;

      img {
        display: inline-block;
        vertical-align: middle;
        max-width: 100%;
      }
    }
  }

  &__title {
    margin-top: 10px;
    position: relative;
    font-size: 1.263rem;
    font-weight: 500;
    // line-height: 16px;
    color: $white;
    margin-bottom: 17px;
    padding-bottom: 26px;

    @media screen and (max-width: $xl) {
      font-size: 18px;
    }

    @media screen and (max-width: $sm) {
      margin-bottom: 20px;
      padding-bottom: 15px;
    }

    &::after {
      content: "";
      background: $pink;
      position: absolute;
      width: 50px;
      height: 2px;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
    }
  }

  &__menu {
    display: block;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;

    &__item {
      display: block;
      margin-bottom: 12px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__link {
      display: inline-block;
      vertical-align: middle;
      position: relative;
      font-size: 0.82rem;
      font-weight: 500;
      line-height: 1.2;
      color: $white;

      @media screen and (max-width: 1439px) {
        font-size: 13px;
      }

      &:hover,
      &:focus {
        color: $pink;
        text-decoration: none;
      }

      &:active {
        color: #fff;
      }

    }
  }

  &__contact {
    display: block;
    margin-bottom: 0;

    &__item {
      display: block;
      margin-bottom: 12px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__link {
      display: inline-block;
      vertical-align: middle;
      position: relative;
      padding-left: 40px;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.2;
      color: $white;

      &:hover,
      &:focus {
        color: $pink;
        text-decoration: none;
      }

      &:active {
        color: #fff;
      }

    }

    &__icon {
      display: inline-block;
      vertical-align: middle;
      position: absolute;
      left: 0;
      top: 2px;
      z-index: 1;

      path {
        -webkit-transition: all 0.3s ease-out;
        transition: all 0.3s ease-out;
      }
    }

    &__title {
      display: block;
      font-size: 13px;
      font-weight: 500;

      @media screen and (max-width: $xl) {
        font-size: 1rem;
      }
    }
  }

  &__bottom {
    padding: 25px 0;

    @media screen and (max-width: 767px) {
      padding-top: 15px;
      padding-bottom: 15px;
    }

    &__flexbox {
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;
      gap: 15px;

      @media screen and (max-width: 2199px) {
        padding-right: 125px;
      }

      @media screen and (max-width: 1900px) {
        padding-right: 260px;
      }

      @media screen and (max-width: 767px) {
        flex-direction: column;
        gap: 10px;
        padding-right: 0;
      }
    }

    .nav {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      align-items: center;
      // width: 33%;

      @media screen and (max-width: 767px) {
        width: 100%;
        justify-content: center;
      }

      &__item {
        margin: 0 22px 0 0;
        position: relative;

        &:last-child {
          margin-right: 0;

          &::after {
            display: none;
          }
        }

        &::after {
          content: "/";
          font-size: 0.81rem;
          font-weight: 300;
          position: absolute;
          right: -14px;
          top: 0;
          z-index: 1;
        }

        &__link {
          display: inline-block;
          vertical-align: top;
          text-decoration: none;
          background: none;
          padding: 0;
          font-size: 0.82rem;
          font-weight: 300;
          color: $white;
          line-height: 1;
          -webkit-transition: all 0.3s ease-out;
          transition: all 0.3s ease-out;

          @media screen and (max-width: 1365px) {
            font-size: 0.875rem;
          }

          @media screen and (max-width: 767px) {
            vertical-align: middle;
            font-size: 0.813rem;
          }

          &:hover,
          &:focus {
            background: none;
            color: $pink;
            text-decoration: none;
          }

          &:active {
            color: #fff;
          }


        }
      }
    }

    .copyrighttext {
      font-size: 0.81rem;
      font-weight: 300;
      text-align: left;
      width: 30%;

      @media screen and (max-width: 1365px) {
        font-size: 0.875rem;
      }

      @media screen and (max-width: 767px) {
        width: 100%;
        font-size: 0.75rem;
        text-align: center;
      }

      a {
        display: inline-block;
        vertical-align: middle;
        text-decoration: none;
        color: $pink;

        &:hover,
        &:focus {
          color: $pink;
          text-decoration: none;
        }

        &:active {
          color: #fff;
        }

      }
    }
  }
}

.footerOuterWrapper {
  position: relative;
  top: -1px;

  .container {
    position: relative;

    &.footer-squiggle {
      &::after {
        content: "";
        background: url(../../images/overlay-squiggle.png);
        background-repeat: no-repeat;
        background-size: 100% auto;
        width: 230px;
        height: 155px;
        position: absolute;
        left: -15px;
        top: -55px;
        z-index: 1;

        @media screen and (max-width: 767px) {
          left: 0px;
          width: 125px;
          height: 90px;
          top: -18px;
        }
      }
    }
  }
}

.footer__text__para {
  font-size: 13px !important;
}

.footer__col_gymtime_guy_icon_container {
  transform: rotate(196deg);
  position: absolute;
  height: 20rem;
  top: 214px;
  right: 0;
  z-index: -1;



  @media screen and (max-width: 1600px) {
    height: 95px;
    top: 100%;
    right: 40px;
  }



  @media screen and (max-width: 1024px) {
    height: 50px;
    right: 30px;
  }

  @media screen and (max-width: 780px) {
    display: none;
  }


}

@media (max-width: 1024px) {
  .footer__col_gymtime_guy_icon_container {
    height: 73px;
  }
}

@media (max-width: 768px) {
  .footer__bottom__flexbox {
    padding: 0px;
    width: max-content;
  }

  .footer-squiggle {
    top: 10px;
    width: 200px;

  }
}

@media (max-width: 1024px) {

  .footer__menu__link,
  .footer__contact__title,
  .footer__contact__item a,

  .footer__text__para {
    font-size: 0.8rem;
    color: red($color: #000000);

  }

  .footer__contact {
    font-size: 0.8rem;
    color: red($color: #000000);
  }
}

@media (max-width: 1600px) {
  .footer__col:first-child {
    width: 21%;
  }

  .footer__bottom__flexbox {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .footer__bottom .copyrighttext {
    font-size: 13px;
  }

  .footer__bottom .nav__item__link {

    font-size: 13px;
  }
}

@media (max-width: 1400px) {
  .footer__bottom {
    padding: 8px 0;
  }
}


@media (max-width: 1024px) {
  .footer__col_gymtime_guy_icon_container {
    height: 73px;
  }
}

@media (max-width: 768px) {
  .footer__bottom__flexbox {
    padding: 0px;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  }



  .footer-squiggle {
    width: 200px;
  }

}



@media (max-width: 574px) {
  .footer__col {
    width: 100%;
    margin-bottom: 20px;
  }

  .footer__bottom__flexbox {
    padding: 0px 2px 34px;
    width: 100%;
    flex-direction: column;
    align-items: center;
  }

}