.color-list-items-container{
    padding: 0px !important;
}
.color-list-items-container li {
    font-size: 12px;
}
.color_options_container{
    top: 100%;
    left: 0;
    width: 100%;
    border: 1px solid #ccc;
    background-color: #F1f1ff;
    max-height: 75px;
    overflow-y: auto;
    border-radius: 10px;
    margin-top: 1px;
    margin-left: 2px;
    padding: 0px;
}