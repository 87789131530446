.invoice-box {
  width: 21cm;
  height: 29.7cm;
  margin: auto;
  font-size: 0.7rem;
  padding: 60px;
}

.invoice-title {
  font-size: 13px;
  margin-right: 100px;
}

.invoice-table {
  width: 100%;
  table-layout: fixed;
}

.table-head th {
  padding: 0.8rem;
}

.tableTH,
.row-data td,
.total-row td {
  padding: 0.6rem;
  border: 1px solid #c3d940;
  text-align: center;
  font-size: 0.5rem;
}

.total-row td {
  font-weight: bold;
}

/* Adjustments for A4 size paper */
.invoice-box {
  width: 21cm;
  min-height: 29.7cm;
  margin: 0 auto;
}

.invoice-table {
  width: 100%;
}

.flexbox {
  margin: 1rem 0;
}

.flex {
  margin: 1rem 0;
  width: 100%;
}

.table-head {
  padding: 1rem;
}

.tableTH {
  padding: 0.5rem;
}

.total-row td {
  padding: 0.5rem;
}

.table-footer {
  margin-top: 2rem;
}

.row-data td {
  padding: 0.5rem;
}

.invoice-table {
  page-break-inside: avoid;
}

.row-data {
  page-break-inside: avoid;
}

.invoice-flexBox {
  margin: 2rem 0rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}

.flex {
  margin: 2rem 0rem;
  padding: 0px 1rem;
  width: 500px;
  display: flex;
  justify-content: space-between;
}

.table-head {
  padding: 1.3rem;
  text-align: center;

  background-color: #c3d940;
}

.tableTH {
  padding: 10px;
  border: 1px solid lightblue;
  text-align: center;
}

.total-row {
  border: 1px solid #c3d940;
  font-weight: bold;
  font-size: 0.5rem;
  td {
    padding: 0.5rem;
    border: 1px solid #c3d940;
    text-align: center;
  }
}

/* FOOTER  */

.table-footer {
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  text-align: center;
  margin-top: 100px;
  margin-bottom: 0px !important;
}

.row-data {
  td {
    border: 1px solid #c3d940;
    padding: 0.5rem;
    text-align: center;
  }
}
.message {
  text-align: center;
  margin: 40px 0px;
}
