h1,
a {
  margin: 0;
  padding: 0;
  text-decoration: none;
}

.section {
  padding: 4rem 2rem;

  display: flex;
}

.sectionInvalid {
  margin-top: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sectionInvalid .error {
  font-size: 150px;
  color: #ea519c;
  text-shadow: 1px 1px 1px #eeee, 2px 2px 1px #eeee, 3px 3px 1px #eeee,
    4px 4px 1px #eeee, 5px 5px 1px #eeee, 6px 6px 1px #eeee, 7px 7px 1px #eeee,
    8px 8px 1px #eeee, 25px 25px 8px rgba(0, 0, 0, 0.2);
}

.page {
  margin: 2rem 0;
  font-size: 20px;
  font-weight: 600;
  color: #5e5f5f;

  @media screen and (max-width:450px) {
  padding: 10px;
  text-align: center;
  }
}

.back-home {
  display: inline-block;
  border: 2px solid grey;
  border-radius: 7px;
  color: #5e5f5f;
  text-transform: uppercase;
  font-weight: 600;
  padding: 0.75rem 1rem 0.6rem;
  transition: all 0.2s linear;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3);
}
.back-home:hover {
  background: #ea519c;
  color: #ddd;
}
