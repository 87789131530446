@use "./variables" as *;

.modal {
  display: none;
  background: rgba($black, 0.6);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1050;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  outline: 0;
  opacity: 0;
  transition: opacity .15s linear;

  &__dialog {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1.75rem auto;
    width: 865px;
    min-height: calc(100% - 3.5rem);
    transform: translate3d(0%, 0, 0);
    transition: opacity 0.3s linear, right 0.3s ease-out;

    &--lg {
      // width: 1060px;

      @media screen and (max-width: 1024px) {
        // width: 991px;
      }

      @media screen and (max-width: 991px) {
        // width: 760px;
      }

      @media screen and (max-width: 760px) {
        width: 90%;
      }
    }

    &--md {
      width: 510px;

      @media screen and (max-width: 639px) {
        width: 100%;
      }
    }

    &--sm {
      width: 440px;

      @media screen and (max-width: 575px) {
        width: 90%;
      }
    }

    @media screen and (max-width: 639px) {
      width: 90%;
    }
  }

  &__content {
    background: $white;
    width: 70%;
    overflow-y: auto;
    box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.41);
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $lightgray;
    padding: 1.25rem 2.5rem;
    border-bottom: 1px solid $lightgray;

    &--center {
      display: block;
      text-align: center;
    }

    @media screen and (max-width: 639px) {
      padding: 0.938rem;
    }
  }

  .close__button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    background: rgba($black, 12%);
    font-size: 2rem;
    font-weight: 400;
    color: rgba($black, .3);
    width: 25px;
    height: 25px;
    outline: none;
    border: none;
    border-radius: 50%;
    cursor: pointer;
  }

  &__title {
    font-size: 1.75rem;
    font-weight: 700;
    color: $blackColor;
    margin-bottom: 0;
  }

  .backbtn {
    display: flex;
    align-items: center;
    gap: 8px;
    background: none;
    padding: 0;
    font-size: .938rem;
    font-weight: 600;
    color: $gray;
    outline: none;
    border: none;
    cursor: pointer;

    svg {
      display: inline-block;
      vertical-align: middle;
    }
  }

  &__body {
    padding: 2.5rem;

    @media screen and (max-width: 639px) {
      padding: 0.938rem;
    }
  }

  &__footer {
    padding: 0 2.5rem 2.5rem;

    @media screen and (max-width: 639px) {
      padding: 0.938rem;
    }

    &--flex {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .btn {
        margin: 0 .75rem;

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  &--loginModal {
    .loginWrap {
      // display: flex;

      @media screen and (max-width: 767px) {
        flex-wrap: wrap;
      }

      &__item {
        // width: 100%;
        padding: 1.875rem 1.563rem 2rem;

        &--bg {
          padding: 3.75rem;
          display: flex;
          align-items: center;
          flex: 0 0 490px;
          width: 490px;
          // background: url(../../images/login-bg.png) no-repeat;
          background-position: center center;
          background-size: cover;
          position: relative;

          @media screen and (max-width: 991px) {
            flex: 0 0 360px;
            width: 360px;
          }

          @media screen and (max-width: 767px) {
            flex: 0 0 100%;
            width: 100%;
            padding: 3.75rem 1.563rem;
          }

          @media screen and (max-width: 639px) {
            display: none;
          }

          &::before {
            content: '';
            background: rgba($blue, .85);
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: 1;
          }

          &::after {
            content: '';
            // background: url(../../images/login-vactor-circle.png) no-repeat;
            background-position: center center;
            background-size: 100% auto;
            position: absolute;
            width: 82px;
            height: 90px;
            left: 0;
            bottom: 10px;
            z-index: 11;
          }
        }
      }

      &__testimonial {
        position: relative;
        z-index: 11;

        &::before {
          content: '';
          // background: url(../../images/login-dots.png) no-repeat;
          background-position: center center;
          background-size: 100% auto;
          position: absolute;
          width: 45px;
          height: 42px;
          right: 50px;
          top: -50px;
          z-index: 11;
        }

        &__bio {
          position: relative;
          padding-top: 5rem;
          padding-bottom: 5rem;
          font-size: .938rem;
          color: $white;
          font-weight: 400;

          @media screen and (max-width: 767px) {
            padding-top: 2.5rem;
            font-size: .875rem;
          }

          &::before {
            content: '';
            // background: url(../../images/quote-icon.png) no-repeat;
            background-position: center center;
            background-size: 100% auto;
            position: absolute;
            width: 19px;
            height: 15px;
            left: 0;
            top: 0;
            z-index: 11;
          }

          &::after {
            content: '';
            // background: url(../../images/login-vactor.png) no-repeat;
            background-position: center center;
            background-size: 100% auto;
            position: absolute;
            width: 50px;
            height: 50px;
            right: 0;
            bottom: 0;
            z-index: 11;
          }
        }

        &__shortText {
          margin-bottom: 1rem;

          p {
            line-height: 28px;
            margin-bottom: .5rem;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }

        &__bioName {
          display: flex;
          align-items: center;
          font-size: 0.875rem;
          font-weight: 500;
          gap: 8px;

          svg {
            display: inline-block;
            vertical-align: middle;
            flex: 0 0 18px;
            width: 18px;
            height: 18px;
          }
        }
      }

      &__branding {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 15px;
        position: relative;
        margin-bottom: 1.875rem;

        .backbtn {
          margin-top: .313rem;
        }

        .logo {
          max-width: 160px;
          margin-left: auto;

          a {
            display: inline-block;
            vertical-align: middle;
          }

          &__img {
            max-width: 100%;
          }
        }
      }

      &__titlebox {
        margin-bottom: 1.875rem;

        @media screen and (max-width: 767px) {
          margin-bottom: 1.125rem;
        }

        .title {
          font-size: 1.75rem;
          font-weight: 700;
          // color: $blackColor;
          color: #5E5F5F;
          margin-bottom: .625rem;

          @media screen and (max-width: 991px) {
            font-size: 1.5rem;
          }

          @media screen and (max-width: 767px) {
            font-size: 1.125rem;
          }
        }

        .para {
          font-size: 1rem;
          font-weight: 400;
          color: $gray;
          margin-bottom: 0;

          @media screen and (max-width: 767px) {
            font-size: .875rem;
          }
        }
      }

      &__form {
        padding-left: 2.188rem;
        padding-right: 2.188rem;

        @media screen and (max-width: 991px) {
          padding-left: 0;
          padding-right: 0;
        }

        @media screen and (max-width: 767px) {
          padding-left: 2.188rem;
          padding-right: 2.188rem;

          .form__label {
            font-size: .875rem;
          }
        }

        @media screen and (max-width: 639px) {
          padding-left: 0;
          padding-right: 0;
        }

        .forgotText {
          font-size: .75rem;
          font-weight: 500;
          color: $black;
          margin-bottom: 0;
          padding-top: 10px;
          text-align: right;

          &__link {
            display: inline-block;
            vertical-align: top;
            text-decoration: none;
            color: $pink;
          }
        }
      }

      &__footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 2rem;
        padding-top: 3rem;

        .orText {
          width: 85%;
          margin: 0 auto;
          border-top: 1px solid $border;
          position: relative;

          &__text {
            display: inline-block;
            vertical-align: middle;
            background: $white;
            font-size: .875rem;
            font-weight: 500;
            color: $gray;
            position: absolute;
            width: 80px;
            left: 50%;
            top: -10px;
            margin-left: -40px;
            z-index: 1;
          }
        }

        .textBox {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          background: $white;
          padding: 1rem;
          box-shadow: 0px 3.6390583515167236px 9.09764575958252px 0px #00000014;
          min-height: 58px;
          border-radius: .5rem;

          .para {
            font-size: .875rem;
            font-weight: 700;
            color: $black;
            margin-bottom: 0;

            &__link {
              display: inline-block;
              vertical-align: top;
              text-decoration: none;
              color: $pink;
            }
          }
        }
      }
    }
  }

  &--paymentModal {
    .cartWrap {
      .checkoutWrap {
        flex-direction: row-reverse;

        @media screen and (max-width:1023px) {
          flex-direction: column;

          .col {
            width: 100%;
          }
        }

        .orderSummry {
          height: 100%;

          .btnClose {
            font-size: 2rem;
            font-weight: 400;
            line-height: 1;
            position: absolute;
            right: 30px;
            top: 29px;
            cursor: pointer;

            @media screen and (max-width:991px) {
              top: 17px;
            }

            @media screen and (max-width:991px) {
              top: 17px;
            }
          }

          @media screen and (max-width:1199px) {
            padding: 1.875rem;
          }

          @media screen and (max-width:767px) {
            padding: 1.25rem 0.938rem;
          }

          &__header {
            .title {
              font-size: 1.5rem;
              font-weight: 600;
              line-height: 1.1;

              @media screen and (max-width:991px) {
                font-size: 1.25rem;
              }

              @media screen and (max-width:639px) {
                font-size: 1.125rem;
              }
            }
          }

          &__footer {
            .orderSummry__row {
              margin-bottom: 3rem;

              &:empty {
                display: none;
              }

              @media screen and (max-width:1023px) {
                margin-bottom: 1.25rem;
              }
            }

            .text {
              &__taxesvalue {
                color: rgba($black, .62);
              }
            }

            .value {
              font-size: 1rem;
              font-weight: 500;
              color: rgba($black, .62);

              &--bold {
                font-weight: 700;
              }
            }
          }
        }

        .orderPrograms {
          &__card {
            .pic {
              flex: 0 0 70px;
              width: 70px;

              @media screen and (max-width:767px) {
                flex: 0 0 100%;
                width: 100%;
              }
            }

            .detail {
              &__row {
                .para {
                  font-size: 1rem;
                }

                .totalValue {
                  color: #5E5F5F
                }
              }
            }
          }
        }

        .form {
          padding: 2rem 1.5rem 2.5rem;

          @media screen and (max-width:1199px) {
            padding: 1.875rem;
          }

          @media screen and (max-width:767px) {
            padding: 1.25rem 0.938rem;
          }

          &__row {
            margin-bottom: 1.5rem;

            &:last-child {
              margin-bottom: 0;
            }
          }

          .title {
            font-size: 1.5rem;
            font-weight: 600;
            line-height: 1.1;
            padding-bottom: 1rem;
            margin-bottom: 1.5rem;
            border-bottom: 1px solid #A0A0A0;

            @media screen and (max-width:991px) {
              font-size: 1.25rem;
            }

            @media screen and (max-width:639px) {
              font-size: 1.125rem;
            }
          }

          &__group {
            &--flex {
              gap: 10px;

              @media screen and (max-width:639px) {
                flex-wrap: wrap;

              }
            }

            @media screen and (max-width:639px) {
              margin-bottom: 0.938rem;

              &__col {
                width: 100%;
              }
            }
          }

          &__input {
            height: 44px;
          }
        }
      }

    }
  }

  &--successModal {
    .modal__content {
      background: #FBFBFB;
      text-align: center;

      .pic {
        margin-bottom: 2rem;

        &__img {
          max-width: 220px;
        }
      }

      .title {
        font-size: 2rem;
        color: $succes;
        margin-bottom: 0;

        @media screen and (max-width: 991px) {
          font-size: 1.5rem;
        }

        @media screen and (max-width: 767px) {
          font-size: 1.125rem;
        }
      }
    }
  }

  &--oderconfirmedModal {
    .modal__content {
      background: #F3F3F3;
    }

    .orderConfirmed {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 20px;
      text-align: center;

      .title {
        font-size: 1rem;
        font-weight: 400;
        color: $black;
        margin-bottom: 0;
      }

      &__detail {
        .title {
          font-size: 1.875rem;
          font-weight: 500;
          color: #0A0D13;
          margin-bottom: 1rem;

          @media screen and (max-width: 991px) {
            font-size: 1.5rem;
          }

          @media screen and (max-width: 767px) {
            font-size: 1.125rem;
          }
        }

        .pic {
          margin-bottom: 1rem;

          &__img {
            max-width: 220px;
          }
        }

        .btn {
          display: block;
          font-size: 1rem;
          font-weight: 700;
          border-radius: 4px;
          margin-bottom: 1rem;
        }
      }
    }
  }

  &--open {
    display: block;
    overflow-x: hidden;
    overflow-y: auto;
    opacity: 1;

    &__dialog {
      -webkit-transform: none;
      transform: none;
    }
  }
}