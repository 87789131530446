.staff_name_container{
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 3%;
    width: 100%;
}
.staff_info_container_box{
    display: flex;
    flex-direction: column;
    width: 22%;
    border: 1px solid #939393;;
    padding: 10px;
    gap: 10px;
    border-radius: 0.5rem;
    margin-bottom: 20px;
}
.button_container_cms{
    display: flex;
    justify-content: space-between;
}
.add_new_teacher_btn{
    margin: 10px 0px;
    float: right;
}