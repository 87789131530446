@use "./variables" as *;

$field-height: 55px;
$field-height-sm: 45px;
$field-font: 1rem;
$field-font-size-sm: 0.8rem;
$field-bg: $white;
$field-radius: 0.5rem;
$field-full-radius: 1.5rem;
$field-padding-x: 1rem;
$field-padding-y: 0.5rem;
$btn-font-size: 1rem;
$btn-radius: .625rem;
$field-margin-bottom: 1.25rem;
$label-margin-bottom: 0.25rem;





.form {

  &__group {
    margin-bottom: $field-margin-bottom;
    position: relative;

    &:last-child {
      margin-bottom: 0;
    }

    &--flex {
      display: flex;
      gap: 20px;
    }

    &__col {
      width: calc(100% / 2);
    }

    &--right {
      text-align: right;
    }
  }

  &__paswordfield {
    position: relative;

    .form__input {
      padding-right: 4.5rem;
    }

    .btn {
      outline: none !important;
      background: none;
      padding: .5rem;
      min-width: 1px;
      font-size: 1rem;
      font-weight: 400;
      color: $black;
      position: absolute;
      right: 1rem;
      top: 6px;
      z-index: 1;

      @media screen and (max-width:991px) {
        font-size: .875rem;
      }
    }
  }

  &__input {
    width: 100%;
    height: $field-height;
    padding: $field-padding-y $field-padding-x;
    font-size: $field-font;
    border: 1px solid $gray;
    border-radius: $field-radius;
    background-color: $field-bg;

    @media screen and (max-width:991px) {
      font-size: .875rem;
    }

    &--fullrounded {
      border-radius: $field-full-radius;
      padding: $field-padding-y $field-padding-x * 1.5;
    }

    &--sm {
      height: $field-height-sm;
      font-size: $field-font-size-sm;
    }

    &--hidden {
      display: none;
      visibility: hidden;
      overflow: hidden;
    }
  }

  // Date picker
  &__date {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: $field-height;
    padding: $field-padding-y $field-padding-x $field-padding-y $field-padding-x*2.5;
    font-size: $field-font;
    border: 1px solid $gray;
    border-radius: $field-radius;
    background: $field-bg url('../../images/calender.svg') 1rem center no-repeat;
    background-size: 16px;

    @media screen and (max-width:991px) {
      font-size: .875rem;
    }

    &--sm {
      height: $field-height-sm;
      font-size: $field-font-size-sm;
    }

    &::-webkit-calendar-picker-indicator {
      background: transparent;
      color: transparent;
      cursor: pointer;
      height: auto;
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      top: 0;
      width: auto;
    }
  }

  &__select {
    width: 100%;
    height: $field-height;
    padding: $field-padding-y $field-padding-x*2 $field-padding-y $field-padding-x;
    font-size: $field-font;
    border: 1px solid $gray;
    border-radius: $field-radius;
    cursor: pointer;
    -webkit-appearance: none;
    background: $field-bg url('../../images/down-arrow-circle.svg') right 0.2rem center no-repeat;

    @media screen and (max-width:991px) {
      font-size: .875rem;
    }

    &--fullrounded {
      border-radius: $field-full-radius;
      padding: $field-padding-y $field-padding-x * 1.5;
    }

    &--sm {
      height: $field-height-sm;
      font-size: $field-font-size-sm;
    }
  }

  &__textarea {
    width: 100%;
    height: $field-height;
    min-height: 120px;
    padding: $field-padding-y $field-padding-x;
    font-size: $field-font;
    border: 1px solid $gray;
    border-radius: $field-radius;
    background-color: $field-bg;

    @media screen and (max-width:991px) {
      font-size: .875rem;
    }

    &--fullrounded {
      border-radius: $field-full-radius;
      padding: $field-padding-y $field-padding-x * 1.5;
    }

    &--sm {
      height: $field-height-sm;
      font-size: $field-font-size-sm;
    }
  }

  &__label {
    display: block;
    // font-size: $field-font;
    font-size: 0.7rem;
    color: $black;
    font-weight: 500;
    margin-bottom: 5px;

    @media screen and (max-width:991px) {
      font-size: .875rem;
    }

    &--sm {
      font-size: $field-font-size-sm;
      margin-bottom: 0px;
    }

    &__asterisks {
      display: inline-block;
      vertical-align: top;
    }
  }

  &__radio {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: $field-margin-bottom;

    &__option {
      display: flex;
      align-items: flex-start;
      position: relative;
      margin-right: 15px;
      margin-bottom: 10px;
    }

    &__label {
      position: relative;
      font-size: 0.875rem;
      color: $lightgray;
      line-height: 22px;
      padding-left: 30px;
      cursor: pointer;

      &::before {
        content: "";
        position: absolute;
        width: 18px;
        height: 18px;
        left: 0;
        top: 0;
        border: 2px solid $border;
        border-radius: 50px;
      }
    }

    &__input {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      opacity: 0;
      z-index: 1;
      cursor: pointer;

      &:checked {
        +.form__radio__label {
          &::before {
            border-color: $border;
          }

          &::after {
            content: "";
            background-color: $pink;
            display: inline-block;
            position: absolute;
            width: 10px;
            height: 10px;
            left: 4px;
            top: 4px;
            border-radius: 50px;
          }
        }
      }
    }
  }

  &__checkbox {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: $field-margin-bottom;

    &__option {
      display: flex;
      align-items: flex-start;
      position: relative;
      margin-right: 15px;
      margin-bottom: 10px;
    }

    &__label {
      position: relative;
      font-size: 0.875rem;
      color: $lightgray;
      padding-left: 30px;
      cursor: pointer;

      &::before {
        content: "";
        position: absolute;
        width: 18px;
        height: 18px;
        left: 0;
        top: 0;
        border: 1px solid $border;
        border-radius: 6px;
      }
    }

    &__labelText {
      font-size: 0.875rem;
      color: $lightgray;
    }

    &__input {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      opacity: 0;
      z-index: 1;
      cursor: pointer;

      &:checked {
        +.form__checkbox__label {
          &::before {
            background: $pink;
            border-color: $pink;
          }

          &::after {
            content: "";
            background: url(../../images/check-icon.svg) no-repeat;
            background-position: center center;
            display: inline-block;
            position: absolute;
            width: 8px;
            height: 8px;
            left: 6px;
            top: 6px;
            border-radius: 50px;
          }
        }
      }

      &--withoutlabel {
        width: 18px;
        height: 18px;
      }
    }
  }
}

.btn {
  outline: none !important;

  display: inline-block;
  vertical-align: middle;
  text-decoration: none;
  padding: .768rem 1rem .65rem;
  border-radius: $btn-radius;
  border: 0px;
  font-size: $btn-font-size;
  font-weight: 500;
  transition: background-color $ease-time $easing, color $ease-time $easing;
  min-width: 145px;
  text-align: center;
  outline: none !important;
  cursor: pointer;

  @media screen and (max-width:991px) {
    font-size: .875rem;
  }

  &--full {
    min-width: 1px;
    width: 100%;
  }

  &--primary {
    background-color: $pink;
    color: $white;
    border: 2px solid $pink;

    &:hover {
      background-color: darken($pink, 6%);
      text-decoration: none;
    }

    &--outline {
      background: transparent;
      color: $pink;

      &:hover {
        background-color: $pink;
        color: $white;
        text-decoration: none;
      }
    }
  }

  &--secondary {
    background-color: $secondary;
    color: $white;
    border: 2px solid $secondary;

    &:hover {
      background-color: darken($secondary, 6%);
      text-decoration: none;
    }

    &--outline {
      background: transparent;

      &:hover {
        background-color: $secondary;
        text-decoration: none;
      }
    }
  }
}


/* CSS */
.owl-carousel .owl-nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  color: white;
  font-size: 21px;


}

.owl-carousel .owl-nav .owl-prev {
  position: absolute;
  left: -100px;
  z-index: 10;

}

.owl-prev:hover {
  background-color: red;
}

.owl-carousel .owl-nav .owl-next {
  position: absolute;
  right: -100px;
  z-index: 10;
}


.newButton {
  background: url(../../images/newBg-button.png);
  background-size: cover;
  padding: 12px;
  position: relative;
  top: -87px;
  left: 53%;
  text-align: center;
  width: min-content;
  transform: rotate(1deg);
  color: white;

}

.newButton button span {
  font-size: 1.3rem;
}

.newButton button:hover {
  color: black;
}


.newButton-image1 {
  background: url(../../images/newBg-button.png);
  background-size: cover;
  padding: 12px;
  position: relative;
  top: -65px;
  left: 53%;
  text-align: center;
  width: min-content;
  transform: rotate(1deg);
  color: white;
}

.newButton-image2 {
  background: url(../../images/bg-button-green.png);
  background-size: cover;
  padding: 12px;
  position: relative;
  top: -49px;
  left: 53%;
  text-align: center;
  width: min-content;
  transform: rotate(1deg);
  color: white;

  @media screen and (max-width:450px) {
     left: 48%;
  }
}

.newButton-image3 {
  background: url(../../images/bg-button-orange.png);
  background-size: cover;
  padding: 12px;
  position: relative;
  top: -87px;
  left: 53%;
  text-align: center;
  width: min-content;
  transform: rotate(1deg);
  color: white;
}

