/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
@use "./variables" as *;

@import url('https://fonts.googleapis.com/css2?family=Catamaran:wght@400;500;600;700;900&display=swap');

::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  background-color: $gray;
}
::-webkit-scrollbar{
  width: 6px;
  height: 6px;
  background-color: $gray;
}
::-webkit-scrollbar-thumb{
  background-color: rgba($gray, 30%);
}
body{
  font-family: 'Catamaran', sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.333;
  color:$black !important;
  overflow-x: hidden;
}
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  box-sizing: border-box;
}
* {
  font-family: 'Catamaran', sans-serif;
  font-size: inherit;
  box-sizing: border-box;
  &:focus,
  &:hover {
      outline: none;
  }
}
h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
  font-weight: normal;
  margin-bottom: 15px;
}
a {
  text-decoration: none;
  -webkit-transition: all 0.3s ease-out; 
  transition: all 0.3s ease-out; 
  &:hover {
      text-decoration: underline;
  }
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

ol, ul, li {
  list-style: none;
}

blockquote, q {
  quotes: none;
}
img {
  max-width: 100%;
}
blockquote:before, blockquote:after {
  content: '';
  content: none;
}

q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}
.mb__0 {
  margin-bottom: 0!important;
}
.containerWidth1600 {
  max-width: 1600px;
  margin: 0 auto;
}
.buttonbox {
  .readmoreLink {
    display: inline-block;
    vertical-align: middle;
    text-decoration: none;
    font-size: 1rem;
    font-weight:500;
    color: $primary;
    position: relative;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out; 
    &::before {
      content:'';
      background:$primary;
      position: absolute;
      height:2px;
      width:0;
      left:0;
      bottom: 0;
      z-index:1;
      -webkit-transition: 0.5s all ease-in;
      transition: 0.5s all ease-in; 
    }
    &__icon {
      display: inline-block;
      vertical-align: middle;
      margin-left: 5px;
      path {
        fill:$primary;
      }
    }
    &:hover {
      &::before {
        width:calc(100% - 22px);
      }
    }
  }
  &--center {
    text-align: center;
  }
}
.contentwraper {
  margin-top: 90px;
  @media screen and (max-width:991px) {
    margin-top:80px;
  }
}
@media screen and (max-width:1165px) {
  .siteheader {
    .container {
      width: 100%;
      max-width:100%;
      padding-left: 0;
      padding-right: 0;
    }
  }
  .menu-open {
    overflow: hidden;
    .siteheader {
      .navbar {
          &__menu {
            .nav {
              &:first-of-type{
                background:$white;
                z-index: 99;
                opacity: 1;
                transform: translateX(0);
                visibility: visible;
                overflow-y: auto;
              }
              &__item {
                &--sideBarMenu {
                  @media screen and (max-width:1199px) {
                    height: 80px;
                  }
                  .menubtn {
                    background: $pink;
                    &__line {
                      background: none;
                      &:before {
                        background: $white;
                        top:0px;
                        transform: rotate(45deg);
                      }
                      &:after {
                        background: $white;
                        top:0px;
                        transform: rotate(-45deg);
                      }
                    }
                  }
                }
              }
            }  
          }
        }
    }
  }
}