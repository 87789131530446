@use './variables'as *;

.camp_container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    gap: 20px;

    @media screen and (max-width: 630px) {
        grid-template-columns: repeat(auto-fill, minmax(320px, 1fr)) !important;
    }

    @media screen and (min-width: 391px) {
        padding: 0px 35px;
    }

}


.about_firt_col {
    @media screen and (max-width: 391px) {
        padding: 0px 0px !important;
        gap: 0px;
    }
}


.bday_Div,
.extr-Act  {
    @media screen and (max-width: 391px) {
        padding: 0px 35px !important;
        gap: 0px;
    }
    @media screen and (min-width: 391px) {
        padding: 0px 35px !important;
        gap: 0px;
    }
}

.camp_pic {

    @media screen and (min-width: 430px) {
        padding: 0px 0px !important;
        gap: 0px;
    }
    @media screen and (min-width: 391px) {
        padding: 0px 35px;
        gap: 0px;
    }
}

.camp_main_pic_div {

    img {
        min-width: 100px;
        height: 80px;
    }



    @media screen and (min-width: 391px) {
        padding: 0px 35px;
        gap: 0px !important;
    }
}

.camp_title {

    @media screen and (min-width: 391px) {
        padding: 0px !important;

    }
}

.title--withicon {
    @media screen and (min-width: 391px) {
        padding: 0px 35px;
    }
}

.camp_logo {
    @media screen and (max-width:393px) and (min-width:391px) {
        max-width: 100px;
        height: 45px;
    }
}

.camp_sec {
    @media screen and (max-width: 630px) {
        padding: 0px 35px !important;
    }

    @media screen and (max-width:393px) and (min-width:391px) {
        padding: 0px !important;
    }
}

.camp_item {
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex-wrap: wrap;
}

.camp_item img {
    max-width: 100%;
    height: auto;
}

.content h3 {
    font-size: 13px;
}


.camp-heading {
    font-size: 18px;
    font-weight: 500;
    margin: 0;
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 20px;
}


.camp_card {
    display: flex;
    flex-direction: column;
    gap: 20px;
    background: #fff;
    padding: 20px;
    height: 500px;
    overflow: hidden;
}

.camp_title {
    font-weight: 600;

    p {
        font-size: 0.5rem;
    }

    &__content {
        font-size: 1.2rem;
    }
}

.email {
    font-weight: 600;
}

.enrichment_para_content {
    font-size: 14px;
    line-height: 1.5;
}

.camp_cardData {
    display: flex;
    justify-content: space-around;
}

.camp_image {
    width: 100%;
    height: auto;
    border-radius: 3px solid gray;
}


.camp-buttons {
    background: purple;
    clip-path: polygon(6% 0, 90% 0, 100% 100%, 0% 100%);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% auto;
    height: 100%;
    width: 100%;
    font-size: 1.3rem;
    padding: 12px;
    border: none;
    font-weight: 800;
    border-radius: 0;
}





.camp_card {
    display: flex;
    flex-direction: column;
    gap: 20px;
    background: #fff;
    padding: 20px;
}

.camp_list {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    flex-wrap: wrap;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
}

.camp-details {
    display: flex;
    flex-direction: column;
}

.camp-btn {
    position: relative;
    bottom: 29px;
    left: 92px;

    @media screen and (max-width:1400px) {
        left: 121px;
    }
    @media screen and (max-width:1200px) {
        left: 97px;
    }
    @media screen and (max-width:550px) {
        left: 55px;
    }
}



.enrichment_para_content {
    font-size: 14px;
    line-height: 1.5;
    margin-top: auto;
}