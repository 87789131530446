.blogList__card {
  position: relative;
}

.pagination-1 {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}
.pagination-1 > li > a {
  border: none;
  padding: 0.5rem 0.875rem;
  color: #5e5f5f;
}

.pagination-1 > .active-1 > a,
.pagination-1 > .active > a:focus {
  border: none !important;
  outline: none;
  border-radius: 6px;
  background-color: #ea519c;
  color: white;
  font-size: 15px;
  font-weight: 600;
}
