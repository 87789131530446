.footer_editable_container{
    display: flex;
    justify-content: space-between;
    gap: 30px;
}
.footer_description_container{
    width: 100%;
}
.footer_contact_us_editable_container{
    width: 100%;
}
.input_box_container{
    margin-bottom:10px ;
}
.activity_list{
    margin-top: 50px;
    display: flex;
    width: 30%;
    gap: 20px;
}