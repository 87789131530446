.makeupContainer {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  gap: 20px;
  width: 100%;
  margin: 20px 0px;
  flex-wrap: wrap;
}

.makeup_card {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: start;

  padding: 10px;
  max-width: 300px;
  width: 300px;
  border: 1px solid #eee;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  position: relative;
}

.makeupText {
  font-size: 1rem;
}

.close_div {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0px; /* Adjust as needed */
  right: 0px; /* Adjust as needed */
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.close_div:hover {
  border: 1px solid red;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}
