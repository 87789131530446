// Variables
$primary: #00ace5;
$secondary: #5d60ef;
$black: #000000;
$white: #ffffff;
$gray: #dddddd;
$border: #dadafd;
$textColor: #4f4f4f;
$inputColor: #3b3b3b;
$textDarkGray: #656565;
$danger: #F00;

// Easing
$ease-time: 300ms;
$easing: ease-in-out;

// Mixin
@mixin flex($alignItems, $justifyContent) {
  display: flex;
  align-items: $alignItems;
  justify-content: $justifyContent;
}
