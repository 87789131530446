@use "../../abstracts/admin-variables" as *;

.navigation {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background-color: $white;
  padding: 0.45rem;
  overflow: auto;
  transition: all 0.3s ease;

  /* width */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    display: none;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    display: none;
  }

  &__logo {
    margin-bottom: 25px;
    text-align: center;

    img {
      max-width: 200px;
      margin: auto;
    }
  }

  &__list {
    &--item {
      overflow: hidden;
      margin-bottom: 2px;
      .navigation__link {
        padding: 1rem 1.25rem;
        // color: $primary;
        color: #5497C7;
        font-size: 0.875rem;
        font-weight: 500;
        line-height: 24px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 8px;
        background-color: $white;
        width: 100%;
        text-decoration: none;
        transition: all 0.5s ease;

        svg {
          transition: all 0.3s ease;
          path {
            transition: all 0.5s ease;
          }
        }

        &:hover {
          // background-color: $primary;
          background-color: #5497C7;
          color: $white;
          transition: all 0.5s ease;

          svg {
            path {
              fill: $white;
              transition: all 0.5s ease;
            }
          }
        }

        &--active {
          // background-color: $primary;
          background-color: #5497C7;
          color: $white;
          transition: all 0.5s ease;

          svg {
            transform: rotate(180deg);
            transition: all 0.3s ease;
            path {
              fill: $white;
              transition: all 0.5s ease;
            }
          }
        }
      }
    }
  }

  &__dropdown {
    border-radius: 8px;
    // background-color: $primary;
    background-color: #5497C7;
    max-height: 0;
    margin-top: 2px;
    overflow: hidden;
    transition: all 0.3s ease;

    ul {
      li {
        a {
          padding: 0.75rem 1.25rem;
          color: $white;
          display: block;
          width: 100%;
          text-decoration: none;
          transition: all 0.5s ease;

          &:hover {
            background-color: rgb(0 185 246);
            transition: all 0.5s ease;
          }
        }
      }
    }

    &--active {
      max-height: 500px;
      transition: all 0.3s ease;
    }
  }
}
.navigation__dropdown--active-index{
  padding: 1rem 1.25rem;
  color: white;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 24px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  /* background-color: #ffffff; */
  width: 100%;
  text-decoration: none;
  transition: all 0.5s ease;
  background-color: #5497c7;
  max-height: 500px;
  transition: all 0.3s ease;
}
.navigation__dropdown--active-index ul li{
  &:hover{
    background-color:  rgb(0 185 246);
  }
}
.navigation__dropdown--active-index ul li a{
  padding: 1rem 1.25rem;
  color: white;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 24px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  width: 100%;
  text-decoration: none;
  transition: all 0.5s ease;
  background-color: #5497c7;
  max-height: 500px;
  transition: all 0.3s ease;
}