/* body { font-size: 9pt !important; } */
@page {
  sheet-size: A3-L !important;
}
table {
  width: 95%;
}
th {
  background-color: none !important;
}
td {
  padding-bottom: 0px;
  /* border: 1px solid black; */
}
.page {
  page-break-after: always;
  overflow: auto;
}

.category_summary_row td{
   padding: 2px !important;
}

@media print {
  td{
    border: 1px solid black;
  }
}