@use '../abstracts/variables' as *;

.siteheader {
  background: $white;
  padding: 1rem 1.875rem;
  position: fixed;
  width: 100%;
  height: 90px;
  right: 0;
  left: 0;
  top: 0;
  box-shadow: 0px 3px 3px #00000012;
  z-index: 999;

  @media screen and (max-width:1365px) {
    padding-left: 0.938rem;
    padding-right: 0.938rem;
  }

  @media screen and (max-width:1165px) {
    padding-right: 0;
    height: 80px;
  }

  .container {
    height: 100%;
  }

  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    height: 100%;

    @media screen and (max-width:639px) {
      width: 100%;
      gap: 0;
    }

    &__branding {
      max-width: 215px;
      width: 100%;
    }

    &__logo {
      height: 52px;
      width: auto;
      display: block;

      @media screen and (max-width:639px) {
        height: 38px;
      }
    }

    &__menu {
      display: flex;
      justify-content: space-between;
    }

    .nav {
      display: flex;
      align-items: center;
      position: relative;

      @media screen and (max-width:1165px) {
        &:first-of-type {
          position: fixed;
          transform: translateX(-110%);
          opacity: 0;
          visibility: hidden;
          transition: all 0.2s linear;
          -webkit-transition: all 0.2s linear;
          -moz-transition: all 0.2s linear;
          left: 0px;
          top: 0px;
          right: 48px;
          bottom: 0px;
          max-width: 320px;
          flex-direction: column;
          justify-content: flex-start;
          padding-bottom: 1rem;

          .nav__item {
            width: calc(100% - 2em);
            margin: 0;
          }

          .nav__link {

            background: none;
            padding: 15px 0;
            font-size: 0.938rem;
            border-bottom: 1px solid $primary;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            text-align: left;
            height: auto;
            -webkit-transition: all 0.3s ease-out;
            transition: all 0.3s ease-out;
          }
        }

        &__item {
          &--sideBarMenu {
            display: block !important;
            margin-left: 0;

            .menubtn {
              &.btn {
                padding: 10px 15px;
                height: 40px;
              }
            }
          }
        }
      }

      &__item {
        position: relative;
        margin-left: 1rem;



        @media screen and (max-width:1199px) {
          margin-left: .625rem;
        }

        @media screen and (max-width:639px) {
          margin-left: .5rem;


        }

        &:first-child {
          margin-left: 0;
        }

        &--active,
        &:hover {
          .nav__link {
            // background: none;
            color: $pink;
            text-decoration: none;
            position: relative;

            &--login {
              color: $white;
            }
          }
        }

        &:last-of-type {
          padding-right: 0;
        }

        &--language {
          position: relative;

          &--hideDeskTopView {
            display: none !important;
          }

          @media screen and (max-width:639px) {
            display: none;

            &--hideDeskTopView {
              margin-top: 10px !important;
              display: block !important;

              a {
                width: 100%;
              }
            }
          }

          .languagetoggle {
            display: inline-flex;
            align-items: center;
            text-decoration: none;
            font-size: 1rem;
            font-weight: 700;
            // color: $blackColor;
            color: #5e5f5f;
            padding: .5rem 1rem;
            min-height: 48px;
            border: 1px solid $pink;
            border-radius: 10px;

            @media screen and (max-width:1439px) {
              font-size: .875rem;
            }

            @media screen and (max-width:639px) {
              font-size: .875rem;
              padding-left: .625rem;
              padding-right: .625rem;
            }

            .iconbox {
              display: flex;
              align-items: center;
              margin-right: 8px;

              &__svg {
                display: inline-block;
                vertical-align: middle;
              }

              &--arrow {
                margin-left: 8px;
              }
            }
          }

          .languagedropdown {
            display: none;
            padding: 0 1rem;
            background: $white;
            position: absolute;
            right: 0;
            left: 0;
            top: 100%;
            border: 1px solid $pink;
            border-top: 0;
            border-radius: 0 0 10px 10px;
            box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.0705882353);
            z-index: 1;

            &__item {
              display: block;
              border-bottom: 1px solid $pink;

              &:last-child {
                border-bottom: none;
              }
            }

            &__link {
              display: block;
              text-decoration: none;
              padding: 0.625rem 0;
              font-size: 1rem;
              font-weight: 600;
              color: $blackColor;
              text-transform: capitalize;
              cursor: pointer;

              @media screen and (max-width:1439px) {
                font-size: .875rem;
              }

              @media screen and (max-width:1365px) {
                font-size: .813rem;
              }

              @media screen and (max-width:639px) {
                font-size: .875rem;
              }
            }
          }

          &.open {
            .languagetoggle {
              border-radius: 10px 10px 0 0;
              border-bottom: 0;
            }

            .languagedropdown {
              display: block;
            }
          }
        }

        &--sideBarMenu {
          display: none;
          width: 55px;
          height: 40px;
          position: relative;

          .menubtn {
            display: flex;
            align-items: center;
            justify-content: center;
            background: none;
            padding: 12px 15px;
            position: absolute;
            width: 100%;
            height: 100%;
            border: 0;
            top: -1px;

            &:hover,
            &:focus {
              outline: 0px;
            }

            &__line {
              width: 24px;
              height: 2px;
              background: $blackColor;
              position: relative;
              -webkit-transition: all 0.3s ease-out;
              transition: all 0.3s ease-out;

              &:before,
              &:after {
                content: '';
                display: block;
                background: $blackColor;
                position: absolute;
                width: 100%;
                height: 2px;
                left: 0;
                -webkit-transition: all 0.3s ease-out;
                transition: all 0.3s ease-out;
              }

              &:before {
                top: -8px;
                transform: rotate(0deg);
              }

              &:after {
                top: 8px;
                transform: rotate(0deg);
              }
            }
          }
        }

        &:hover {
          .submenu {
            display: block;

            @media screen and (max-width:1165px) {
              display: none;
            }
          }
        }

        .toggleSubmenu {
          display: inline-block;
          vertical-align: middle;
          background: url(../../images/down-arrow-black.svg) no-repeat;
          background-size: 10px auto;
          background-position: center center;
          width: 10px;
          height: 6px;
          margin-left: .313rem;
        }

        .submenu {
          background: $white;
          padding: .938rem .938rem 0;
          display: none;
          position: absolute;
          left: 0;
          top: auto;
          min-width: 195px;
          border-bottom: 10px solid $pink;
          border-radius: 10px;
          box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.0705882353);
          z-index: 1;
          margin: 0;

          @media screen and (max-width:1165px) {
            background: $white;
            padding: 0;
            display: none;
            position: static;
            left: 0;
            right: 0;
            width: 100%;
            min-width: 1px;
            border-bottom: none;
            box-shadow: none;
          }

          &--program {
            padding: 1.5rem;
            min-width: 600px;

            @media screen and (max-width:1165px) {
              min-width: 1px;
              padding: 1.5rem 1rem;
            }

            .flexbox {
              margin-top: 0px;
              display: flex;
              justify-content: space-between;
              gap: 100px;
              position: relative;
              align-items: flex-start;

              @media screen and (max-width:1165px) {
                flex-direction: column;
                gap: 20px;
              }

              &::after {
                content: '';
                border-right: 1px dotted $blackColor;
                position: absolute;
                width: 1px;
                height: 84%;
                left: 50%;
                top: 50px;
                bottom: 0;
                z-index: 1;

                @media screen and (max-width:1165px) {
                  display: none;
                }
              }

              &::before {
                content: '';
                // background: $blackColor;
                position: absolute;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                left: 50%;
                top: 50px;
                margin-left: -2px;
                bottom: 0;
                z-index: 1;

                @media screen and (max-width:1165px) {
                  display: none;
                }
              }

              .item {
                width: 50%;
                position: relative;

                @media screen and (max-width:1165px) {
                  width: 100%;
                }

                &:first-child {
                  &::after {
                    content: '';
                    // background: $blackColor;
                    position: absolute;
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    right: -56px;
                    bottom: 50px;
                    bottom: 12px;
                    z-index: 1;

                    @media screen and (max-width:1165px) {
                      display: none;
                    }
                  }
                }
              }
            }

            .title {
              font-size: 1.25rem;
              font-weight: 600;
              color: $pink;
              margin-bottom: .625rem;

              @media screen and (max-width:1165px) {
                font-size: 1.125rem;
                // margin-top: -35px;
              }
            }
          }

          &__item {
            display: block;
            border-bottom: 1px solid $pink;

            &:last-child {
              border-bottom: none;

              @media screen and (max-width:1165px) {
                border-bottom: 1px solid $pink;
              }
            }

            @media screen and (max-width:1165px) {
              display: block;
              width: 100%;
            }

            &__link {
              display: block;
              text-decoration: none;
              padding: 0.625rem 0;
              font-size: 0.85rem;
              font-weight: 600;
              // color: $blackColor;
              color: #5e5f5f;
              ;
              text-transform: capitalize;
              -webkit-transition: all 0.3s ease-out;
              transition: all 0.3s ease-out;

              @media screen and (max-width:1165px) {
                padding: 0.938rem;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                font-size: .875rem;
                text-align: left;
                height: auto;
                border-bottom: none;
                -webkit-transition: all 0.3s ease-out;
                transition: all 0.3s ease-out;
              }
            }

            &:hover {
              .submenu__item__link {
                color: $pink;

                @media screen and (max-width:1165px) {
                  border-bottom-color: none;
                }
              }
            }
          }
        }
      }

      &__link {
        background: none;
        text-decoration: none;
        height: 100%;
        padding: 0;
        font-size: 0.91rem;
        font-weight: 600;
        // color: $blackColor;
        color: #5e5f5f;
        text-transform: capitalize;
        position: relative;
        transition: color $ease-time $easing, background-color $ease-time $easing;

        @media screen and (max-width:1439px) {
          font-size: .875rem;
        }

        @media screen and (max-width:1365px) {
          font-size: .813rem;
        }

        @media screen and (max-width:991px) {
          display: block;
          color: #5e5f5f;
        }

        &--login {
          display: inline-flex;
          align-items: center;
          padding: .5rem 2.188rem;
          background-color: $pink;
          font-weight: 500;
          color: $white;
          text-transform: uppercase;
          border-radius: 10px;
          min-height: 48px;
          transition: background-color $ease-time $easing, color $ease-time $easing;

          &:hover {
            background-color: darken($pink, 6%);
          }

          @media screen and (max-width:1439px) {
            padding-left: 1rem;
            padding-right: 1rem;
          }

          @media screen and (max-width:639px) {
            font-size: .75rem;
            padding-left: .625rem;
            padding-right: .625rem;
          }
        }
      }
    }

    .navLogin {
      display: flex;
      align-items: center;
      position: relative;
    }
  }
}



/* Add this CSS to control submenu visibility */
@media screen and (max-width: 1165px) {
  .submenu--program {
    display: none;
    /* Other styles for hidden submenu */
  }

  .submenu--program.show-submenu {
    display: block;
    /* Other styles for visible submenu */
  }
}


.show-submenu {
  display: flex;
  flex-direction: column;
}