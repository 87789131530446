@media print{
    .page_mailing_list{
        transform: scaleX(0.7);
        transform-origin: top left;
        page-break-inside: avoid; 
        width: 115%;
    }
    .mailing_list_pdf_heading{
        font-size: 1.3rem;
    }
}