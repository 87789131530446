.mailing_logo_container{
  margin-top: 20px;
    display: flex;
    margin-bottom: 1rem;
    height: 20px;
    width: 250px;
}
.mailing_logo_container img{
  /* display: none; */
  height: 30px !important;
  width: 100px !important;
}
.mailing_data_header{
    display: flex;
    justify-content: space-between;
}
.mailing_class{
    color: black;
    font-size: 20px;
    font-weight: 600;
}
.mailing_address{
    color: rgb(26, 26, 26);
    font-size: 13px;
}
.Invoide_mailing_meta_data .mailing_Invoice_heading {
    font-size: 24px;
    color: black;
    font-weight: 600;
}
.Invoide_mailing_meta_data .Invoice_date{
    color: black;
    font-size: 13px;
}
.to_for_mailing_section{
    margin-top:1rem ;
    display: flex;
    gap: 10rem;
}
.to_mailing_heading{
    color: black;
    font-weight: 600;
}
.mailing_table_list{
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  .mailing_table_list th{
    background-color: #c3d940;
  }
  .mailing_table_list td,.mailing_table_list th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 15px;
    color: black;
    min-width: none;
  }
  .Admin-modal__head-send_Email_Modal{
    padding: 9px;
  }
  .mailing_total_amount td{
    color: black;
    font-weight: 600;
  }
  .tax_id_heading{
    font-weight: 700;
    font-size: 12px;
  }
  .greeting_mailing{
    font-weight: 700;
    color: black;
  }
  .greeting_container{
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
  }
  .gymtime_mailing_info{
    font-size: 12px;
  }
  .mailing_class_metadata_subtitle{
    color: black;
  }
  .due_meta_data_info_mailing td{
    border: none;
  }
  .total_rates_mailing_table{
    font-size: 13px;
    font-weight: 600;
  }
  .mailing_table_list tr th , .mailing_table_list tr td{
     min-width: 0;
  }