.shimmer_UI_container {
  height: 100%;
  width: 100%;
  margin-top: 5.6rem;
  margin-bottom: 5rem;
  overflow: hidden;
  position: relative;
  z-index: 10;
}

.shimmer_header_container {
  height: 5rem;
  width: 100%;
  height: 100%;
  background-color: #f0f0f0;
  position: relative;
}

.shimmer_header_container,
.image_banner_container,
.browse_by_box_container,
.whats_new_container {
  position: relative;
  overflow: hidden;
}

.shimmer_effect {
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to right,
    transparent 0%,
    white 60%,
    transparent 100%
  );
  animation: shimmer-animation 7s infinite linear;
}

@keyframes shimmer-animation {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
.image_banner_container {
  height: 30rem;
  background-color: #f0f0f0;

  clip-path: polygon(0 12%, 100% 1%, 100% 85%, 0 100%);
}
.browse_by_box_container {
  display: flex;
  gap: 6rem;
  padding: 20px 100px;
}
.first_browse_by_box,
.second_browse_by_box,
.third_browse_by_box {
  height: 20rem;
  width: 100%;
  background-color: #f0f0f0;
  border-radius: 1rem;
}
.whats_new_container {
  margin-top: 2rem;
  height: 35rem;
  background-color: #f0f0f0;
  clip-path: polygon(0 10%, 100% 0, 100% 100%, 0 100%);
}
@keyframes shimmer-animation {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.shimmer_admin_ui_container {
  width: 100vw;
  height: 100vh;
  padding: 1.125rem;
  background-color: white;
}
.shimmer_grid_container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 245px 1fr;
  gap: 20px;
}
.navigation_shimmer {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background-color: #f0f0f0;
  padding: 0.75rem;
  overflow: auto;
  transition: all 0.3s ease;
}
.admin_shimmer {
  width: 100%;
  height: 100%;
  overflow: auto;
}
.admin_header_shimmer {
  padding: 3.75rem 0.875rem 0.75rem 1.875rem;
  background-color: #f0f0f0;
  border-radius: 40px;
}
.admin_page_shimmer_ui {
  width: 100%;
  height: calc(100% - 84px);
  background-color: #f0f0f0;
  border-radius: 12px;
  margin-top: 10px;
  padding: 0.875rem 1.5rem;
}
.table_heading_shimmer {
  height: 8rem;
  width: 100%;
  background-color: #f0f0f0;
  border-radius: 10px;
}
.About_us_lower_section {
  margin-top: 3rem;
  display: flex;
  justify-content: center;
}

._shimmer_about_us_section {
  display: flex;
  gap: 70px;
}
._shimmer_about_image_section {
  height: 25rem;
  border-radius: 10px;
  width: 25rem;
  background-color: #f0f0f0;
  /* transform: rotate(65deg); */
}
.shimmer_content_line {
  height: 2rem;
  width: 25rem;
  border-radius: 20px;
  margin-bottom: 10px;
  background-color: #f0f0f0;
}
._shimmer_staff_name_list {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}
._shimmer_staff_name {
  height: 12rem;
  width: 25rem;

  background-color: #f0f0f0;
  border-radius: 10px;
}

/* .birthday_page_shimmer_lower_section {
  display: flex;
  justify-content: center;
} */
._shimmer_heading_section {
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  
}
.birthday_header_shimmer {
  height: 100px;
  width: 75%;
  border-radius: 10px;
  background-color: #f0f0f0;
  
}
.birthday_header_shimmer-1 {
  height: 10px;
  width: 60%;
  border-radius: 10px;
  background-color: #f0f0f0;
}

.birthday_big_box_shimmers{
  display: flex;
  margin-top: 30px;
  gap: 20px;
  justify-content: center;
}
.birthday_box_shimmer-1{
  height: 50rem;
  border-radius: 20px;
  width: 20%;
  background-color: #f0f0f0;
}

._shimmer_program_heading_container{
  display: flex;
  flex-direction: column;
}

.program_class_shimmer_container{
  width: 75%;
  margin: auto;
}

.program_list_shimmer-1{
  margin-top: 30px;
  height: 250px;
  background-color: #f0f0f0;
  border-radius: 20px;
}