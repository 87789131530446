@import url("https://fonts.googleapis.com/css2?family=Neuton:wght@700&display=swap");

.nestedSelectBox {
  border: 1px solid #dadafd;
  padding: 2px;
  padding: 10px;
  background-color: #f1f1ff;
  border-radius: 5px;
  width: 15.4rem;
  height: 300px;
  overflow-y: auto;
  /* border-bottom: 5px solid rgb(177, 177, 177); */
}
.reportHeading {
  margin-top: 20px;
  margin-bottom: 5px;
  font-size: 2.5rem;
  text-align: center;
  font-weight: 600;
}
.reportSubtitle {
  margin-bottom: 20px;
  text-align: center;
}
.parentCheckboxContainer {
  display: flex;
  align-items: center;
  font-size: 1rem;
  gap: 5px;
}
.childCheckBoxContainer {
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  gap: 5px;
  margin-top: 10px;
}
.parentCheckbox {
  height: 20px;
  width: 30px;
}
.select_report_title {
  display: flex;
  justify-content: center;
}
.NonSelectReportHeading {
  color: #5497c7;
  padding: 20px;
  margin-top: 4rem;
  text-align: center;
  font-size: 4.5rem;
  font-weight: 600;
  border-bottom: 0.6rem solid #5497c7;
  border-radius: 1.5rem;
  width: fit-content;
}

.closeCalenderBtn {
  position: absolute;
  top: -20px;
  right: 0;
  background-color: #5497c7;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 8px;
  border-radius: 100%;
}
.categoryItemContainer {
  margin-top: 20px;
  position: relative;
}
.closeItemBtn {
  color: white;
  position: absolute;
  top: -19px;
  left: 11.5rem;
  left: 11.5rem;
  background-color: #5497c7;
  color: black;
  padding: 6px 8px;
  border-radius: 50px;
  z-index: 100;
}
.radioLabel {
  margin-left: 2px;
  margin-right: 10px;
  color: gray;
  font-size: 1rem;
  font-weight: 500;
  line-height: normal;
}
.radioContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}
/* .nestedSelectBox input[type="checkbox"], */
.nestedSelectBox input[type="radio"] {
  margin-top: 0;
  height: 20px;
  width: 20px;
}
.nestedSelectBox input[type="checkbox"] {
  margin-top: 0;
}
.selectedCategoryContainerFluid {
  margin-top: 13px;
  display: flex;
  justify-content: center;
  margin-bottom: 21px;
}
.selectedCategoryContainer {
  display: flex;
  gap: 14px;
}
.headingItem {
  color: #4f4f4f;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: normal;
  text-align: center;
}
.reportHeading {
  text-decoration: underline;
  margin-bottom: 10px;
  color: #4f4f4f;
  font-size: 2.5rem;
  font-weight: 700;
  line-height: normaly;
  font-family: "Neuton", serif;
}
.rollSheet_details {
  line-height: 2rem;
}
.rollSheet_metaDetail {
  display: flex;
  justify-content: flex-start;
  gap: 3rem;
}
.makeup_trails_heading {
  font-size: 4.5rem;
  font-family: "Neuton", serif;
  font-weight: 800;
  text-decoration: underline;
}
.calenderContainer {
  width: 247px;
  position: relative;
}

/* .rdrDateRangePickerWrapper{
  position: relative;
} */

.closeCalenderBtn {
  position: absolute;
  z-index: 10;
}

.closeCalenderBtn button {
  background: none;
  border: none;
  cursor: pointer;
}

.closeCalenderBtn h2 {
  margin: 0;
}
.reportSubTitlecloseIcon {
  font-weight: 600;
  color: white;
}
.classNameSearchBox {
  margin-bottom: 5px;
  width: 10.5rem;
  padding: 3px;
  font-size: 15px;
  outline: none;
  border: none;
  border-bottom: 0.5px solid rgb(205, 202, 202);
  background-color: #f1f1ff;
}
.report_meta_info {
  text-decoration: underline;
}
.nestedSelectBox-notification {
  border: 1px solid #d7d7fa;
  padding: 2px;
  margin-top: 100%;
  padding: 10px;
  background-color: #f1f1ff;
  border-radius: 5px;
  width: 12.4rem;
  height: 250px;
  overflow-y: auto;
  position: absolute;
  z-index: 10;
  border-bottom: 5px solid rgb(177, 177, 177);
}
.spinner_loader_section {
  height: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.generate_report_select_report_dropdown {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.calender_radio_preabs_container {
  display: flex;
  align-items: flex-start;
  gap: 2rem;
}

.present_absent_container {
  background-color: #f1f1ff;
  border-radius: 10px;
  height: 50px;
  display: flex;
  justify-content: center;
  gap: 30px;
  border: 1px solid #dedeff;
}

.radioInput_pres_abs {
  transform: scale(1.7);
}

.radioInput:checked {
  background-color: #5497c7;
  border-color: #5497c7;
}

.input_present_container,
.input_absent_container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.generate_report_btn_container {
  margin-top: 15px;
  margin-bottom: 10px;
}
.category_search_params_container {
  display: flex;
  gap: 3rem;
  flex-wrap: wrap;
}

.dropOffSearchParams_container {
  display: flex;
  gap: 3rem;
  flex-wrap: wrap;
  flex-direction: column;
}

.mailing_list_upper_container {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.photo_release_searchParam_container {
  display: flex;
  gap: 4rem;
  flex-wrap: wrap;
}

.class_rollsheet_search_params_container {
  display: flex;
  gap: 3rem;
}
.age_selection {
  width: 200px;
}
.rdrDefinedRangesWrapper {
  display: none;
}

.rdrCalendarWrapper {
  border: 1px solid #dadafd;
  border-radius: 10px;
  overflow: hidden;
}

.category_search_bar {
  outline: none;
  background-color: #f1f1ff;
  border: none;
}

.active_inactive_container {
  background-color: #f1f1ff;
  padding: 10px 20px;
  border: 1px solid #dadafd;
  border-radius: 10px;
}
.gender_photo_release_section {
  padding: 10px 20px;
  border: 1px solid black;
  border-radius: 10px;
}

.attendance_summary_search_param {
  display: flex;
  gap: 20px;
  align-items: flex-start;
  flex-wrap: wrap;
}

.reports_label {
  font-size: 0.9rem;
  font-weight: 600;
}

.openDate_range_attendance_summary {
  height: 45px;
  text-align: center;
  cursor: pointer;
  background-color: #f1f1ff;
  width: fit-content;
  padding: 0.625rem 0.75rem;
  color: gray;
  border-radius: 10px;
  font-size: 0.75rem;
  width: 335px;
  margin-bottom: 1px;
  border: 1px solid #dadafd;
}
.student_status_radio_title {
  display: flex;
  align-items: center;
  text-align: center;
  cursor: pointer;
  background-color: #f1f1ff;
  width: fit-content;
  padding: 0.625rem 0.75rem;
  color: gray;
  border-radius: 10px;
  font-size: 0.75rem;
  width: 335px;
  margin-bottom: 1px;
  border: 1px solid #dadafd;
  height: 45px;
}
.category_list_title {
  cursor: pointer;
  display: flex;
  align-items: center;
  text-align: center;
  background-color: #f1f1ff;
  padding: 10px 20px;
  border-radius: 10px;
  color: gray;
  font-size: 0.85rem;
  border: 1px solid #dadafd;
  margin-bottom: 1px;
  height: 45px;
  min-width: 230px;
  width: 246px;
  overflow: auto;
}
.dropOff_classlist_title {
  display: flex;
  align-items: center;
  /* min-width: 247px; */
  width: 247px;
  padding: 10px 15px;
  border-radius: 10px;
  border: 1px solid #dadafd;
  background-color: #f1f1ff;
  margin-bottom: 1px;
  cursor: pointer;
  color: gray;
  font-size: 0.85rem;
  height: 45px;
  overflow: auto;
}
.gender_container {
  padding: 5px 10px;
  background-color: #f1f1ff;
  border: 1px solid #dadafd;
  border-radius: 10px;
}
.rollsheet_desclaimer {
  color: red;
  font-size: 0.8rem;
}

.react-datepicker-ignore-onclickoutside {
  display: flex;
  align-items: center;
  width: 247px;
  min-width: 100%;
  padding: 10px 15px;
  border-radius: 10px;
  border: 1px solid #dadafd;
  background-color: #f1f1ff;
  margin-bottom: 1px;
  cursor: pointer;
  color: gray;
  font-size: 0.85rem;
  height: 45px;
}

.class_rollsheet_search_params_container {
  display: flex;
}

._attendance_startDate {
  width: 247px !important;
}

.mailing_list_lower_section_container {
  margin-top: 10px;
  display: flex;
  gap: 20px;
}
.rollsheet_meta_details_row {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}
.rollsheet_metadata_heading {
  font-size: 1.3rem;
  font-weight: 600;
  background-color: #e9e7e7;
  margin-left: 4px;
  padding: 3px 10px;
  border-radius: 5px;
  /* font-family: 'Times New Roman', Times, serif; */
}

.rollsheet_pdf_data {
  font-size: 1.3rem;
}
.student_allergies {
  color: red;
}

.select_weekdays_container {
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  border-bottom: 0.6px solid gray;
  margin-bottom: 20px;
  padding: 10px 0px;
}
.roll_sheet_weekday span {
  margin-left: 4px;
}
.generate_pdf_btn {
  float: right;
}
.no_data_found_title {
  margin-top: 50px;
  font-size: 3.5rem;
  font-weight: 600;
  color: #5497c7;
  text-align: center;
  text-shadow: 4px 5px 7px rgba(84, 151, 199, 1);
}
.fade_loader_container {
  height: 150px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.selectAllClassContainer {
  display: flex;
  gap: 5px;
}
.particular_class_container {
  margin-top: 20px;
}
.active_class_label {
  font-weight: 600;
  margin-left: 5px;
}
.select_all_class_name_btn {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 3px 5px;
  border-radius: 5px;
  background-color: #5497c7;
  border: 3px solid #338dce;
  color: white;
  float: right;
}

._selected_cate_class_name {
  background-color: #cfcbcb;
  padding: 5px 10px;
  border-radius: 5px;
}
.cate_class_item {
  background-color: #ada9a9;
  padding: 5px 10px;
  border-radius: 10px;
}

.dropoff_search_params_upper_container {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
}
.dropoff_prev_year_season_search_container {
  display: flex;
  gap: 20px;
}
.dropoff_search_param_item {
  width: 60px !important;
}
.dropOffLabel_search_param {
  font-weight: 400;
  font-size: 0.81rem;
}

.selectedClassRollsheetContainer {
  border: 1px solid gray;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 10px;
}

.selectedClassRollsheetContainer .selectedClass {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 2px 5px;
  color: black;
}
/* .radio_make{
  background-color: rgb(241, 241, 255);
  width: 300px;
  padding: 10px 20px;
  border-radius: 10px;
  line-height: 30px;
  border: 0.51px solid gray;
} */
.radio_make label{
  margin-left: 20px;
  font-size: 1rem;
  color: gray;
  font-weight: 400;
  cursor: pointer;
}
.select_type_attendance_container{
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding: 0.625rem 0rem;
}
.attendance_option_dropdown_list{
  margin-top: 3px;
  height: 100px;
  padding: 10px 20px;
  border-radius: 10px;
  background-color: #f1f1ff;
  border: 1px solid #dadafd
}