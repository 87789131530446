// Variable
@use "../index"as *;

.blog-play-pause-icon {
  height: 45px;
}

.siteBanner {
  background: $green;
  height: 100%;
  position: relative;

  &__overlay {
    -webkit-clip-path: polygon(0 12%, 100% 1%, 100% 85%, 0 100%);
    clip-path: polygon(0 12%, 100% 1%, 100% 85%, 0 100%);
  }

  &__img {
    display: block;
    width: 100%;
    object-fit: fill;
    position: relative;

    // For Mobile Screen the Banner image should be More height;

    @media only screen and (min-device-width: 390px) and (max-device-width: 844px) and (-webkit-device-pixel-ratio: 3) {
      height: 197px;
    }
  }
}

.facility_sitebanner_overlay {
  margin-top: 5rem;
}

.featureSec {
  background: $white;
  padding: 30px 26px;
  -webkit-clip-path: polygon(0 0, 100% 8%, 100% 100%, 0 100%);
  clip-path: polygon(0 0, 100% 8%, 100% 100%, 0 100%);

  @media screen and (max-width: 991px) {
    padding: 35px 0px;
  }

  @media screen and (max-width: 450px) {
    padding: 0px 35px;
  }

  @media screen and (max-width: 390px) {
    padding: 0px 25px;
  }

  &__topblock {
    margin-bottom: 2.5rem;

    @media screen and (max-width: 1440px) {
      // max-width: 960px;
      margin-left: auto;
      margin-right: auto;
    }

    @media screen and (max-width: 991px) {
      margin-bottom: 1.875rem;
    }

    .title {
      color: $black;
      line-height: 1.1;
    }
  }

  .featureList {
    display: flex;
    justify-content: center;

    @media screen and (max-width: 1440px) {
      // max-width: 960px;
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;
    }

    @media screen and (max-width: 991px) {
      margin-top: 15%;
    }

    @media screen and (max-width: 768px) {
      margin-top: 5%;
    }

    &__item {
      width: calc(100% / 3);

      @media screen and (max-width: 991px) {
        padding: 0 15px;
      }

      @media screen and (max-width: 639px) {
        width: calc(100% / 3);
        padding: 0 2px;
      }
    }

    .card {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: $orange;
      padding: 45px;
      clip-path: polygon(0 0, 89% 0, 100% 100%, 0% 100%);
      margin-left: -26px;
      transform: scale(0.85);

      @media screen and (max-width: 1200px) {
        padding: 11px 45px;
      }

      @media screen and (max-width: 767px) {
        padding: 25px;
        margin-left: 0px;
        transform: scale(0.8);
      }

      @media screen and (max-width: 639px) {
        padding: 5px 15px;
        margin-left: 0px;
        transform: scale(0.95);
      }

      @media screen and (max-width: 440px) {
        padding: 20px 15px;
        margin-left: 0px;
        transform: scale(0.95);
      }

      @media screen and (max-width: 325px) {
        padding: 15px 11px;
      }

      &--pink {
        background: $pink;
        clip-path: polygon(5% 0, 100% 4%, 93% 100%, 0% 100%);
      }

      &--blue {
        background: $secondary;
        clip-path: polygon(13% 0, 94% 0, 100% 96%, 0% 100%);
        margin-left: -40px;

        @media screen and (max-width: 767px) {
          margin-left: 0px;
        }
      }

      &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 1rem;
        height: 180px;

        @media screen and (max-width: 767px) {
          height: 100px;
        }

        @media screen and (max-width: 639px) {
          height: 50px;
        }

        img {
          max-height: 100%;

          @media screen and (max-width: 767px) {
            height: 90px;
          }

          @media screen and (max-width: 639px) {
            height: 50px;
          }
        }
      }

      .title {
        font-size: 2.625rem;
        font-weight: 700;
        color: $white;
        text-transform: uppercase;
        margin-bottom: 0;

        @media screen and (max-width: 1365px) {
          font-size: 2.25rem;
        }

        @media screen and (max-width: 991px) {
          font-size: 1.75rem;
        }

        @media screen and (max-width: 767px) {
          font-size: 1.5rem;
        }

        @media screen and (max-width: 639px) {
          font-size: 0.813rem;
        }
      }
    }
  }
}

.whatsnewSec {
  background: $purple;
  padding: 80px 0 60px;
  position: relative;
  clip-path: polygon(0 10%, 100% 0, 100% 100%, 0 100%);

  .flexbox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;

    @media screen and (max-width: 767px) {
      flex-direction: column-reverse;
    }

    .content {
      flex: 0 0 46%;
      width: 46%;
      font-size: 1.75rem;
      font-weight: 400;
      color: $white;
      padding: 0 2rem 2rem 0;
      position: relative;

      @media screen and (max-width: 1199px) {
        font-size: 1.35rem;
      }

      @media screen and (max-width: 767px) {
        flex: 0 0 100%;
        width: 100%;
        padding: 0 14px;
        font-size: 1rem;
        line-height: 26px;
      }

      // &::before {
      //   content: "";
      //   background: url(../../images/brush-overlay.png);
      //   background-repeat: no-repeat;
      //   background-size: 100% auto;
      //   width: 100px;
      //   height: 120px;
      //   position: absolute;
      //   right: -15px;
      //   top: -15px;
      //   z-index: 1;

      //   @media screen and (max-width:1023px) {
      //     display: none;
      //   }
      // }

      .title {
        // font-size: 5rem;
        font-weight: 700;
        margin-bottom: 0.5rem;

        // @media screen and (max-width:1439px) {
        //   font-size: 4rem;
        // }

        // @media screen and (max-width:1199px) {
        //   font-size: 3.25rem;
        // }

        // @media screen and (max-width:991px) {
        //   font-size: 2.25rem;
        // }

        // @media screen and (max-width:767px) {
        //   font-size: 1.875rem;
        // }
      }

      .para {
        margin-bottom: 0;
        font-size: 1.45rem;
        font-weight: 300;
        line-height: 29px;
        text-align: left;
      }
    }

    .picbox {
      width: 54%;

      &__layerbox {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        background: url(../../images/whats-new-bglayer.png);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 90% 100%;
        height: 100%;
        width: 100%;
        min-height: 438px;
        padding: 45px 0 35px 45px;
        text-align: center;

        @media screen and (max-width: 991px) {
          min-height: 300px;
        }

        @media screen and (max-width: 767px) {
          min-height: 1px;
        }
      }

      @media screen and (max-width: 767px) {
        width: 100%;
      }

      img {
        clip-path: polygon(0 8%, 94% 0, 100% 92%, 0 100%);
        width: 84%;
        position: relative;
        top: -33px;
        left: -90px;
        rotate: -2deg;

        @media screen and (max-width: 991px) {
          top: -25px;
        }

        @media screen and (max-width: 767px) {
          top: -25px;
          left: -25px;
        }
      }
    }
  }
}

.party_carousel {
  margin-top: 7rem;

  .container {
    @media screen and (max-width: 435px) {
      width: auto;
    }
  }
}

.aboutusSec {
  padding: 20px 0px;

  @media screen and (max-width: 991px) {
    padding: 10px 0;
  }

  @media screen and (max-width: 450px) {
    padding: 5px 20px;
  }

  @media screen and (max-width: 400px) {
    padding: 10px 0;
  }

  .aboutData {
    @media screen and (max-width: 450px) {
      padding: 25px 0px;
      line-height: 35px;
      font-size: 1.2rem;
      text-align: justify;
    }

    @media screen and (max-width: 430px) {
      padding: 0px 0px !important;
    }

    @media screen and (max-width: 393px) {
      padding: 0px 10px;
    }

    @media screen and (max-width: 390px) {
      padding: 0px 23px !important;
      line-height: 35px;
      font-size: 1.2rem;
      text-align: justify;
    }

    @media screen and (min-width: 391px) {
      padding: 0px 26px;
    }

    &__box {
      padding-bottom: 80px;

      @media screen and (max-width: 767px) {
        padding-bottom: 30px;

        .title--h1 {
          margin-bottom: 15px !important;
          text-align: left !important;
          padding: 0px 0px;
        }
      }

      &:last-child {
        padding-top: 0px;
        padding-bottom: 60px;
      }

      @media screen and (max-width: 430px) {
        &:last-child {
          padding-bottom: 0px;
        }
      }

      &:nth-child(2n + 2) {
        .aboutData__row {
          padding: 20px 0px;
          flex-direction: inherit;
          align-items: center;

          @media screen and (max-width: 767px) {
            flex-direction: column;
          }
        }
      }
    }

    &__row {
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;
      align-items: center;
      gap: 42px;

      @media screen and (max-width: 767px) {
        flex-direction: column-reverse !important;
        gap: 20px;
      }

      @media screen and (max-width: 630px) {
        padding: 0px !important;
      }

      @media screen and (max-width: 450px) {
        padding: 0px 35px;
      }

      @media screen and (max-width: 414px) {
        padding: 0px 0px;
      }

      @media screen and (max-width: 393px) {
        padding: 0px 0px !important;
      }

      @media screen and (max-width: 391px) {
        padding: 0px 0px !important;
      }

      @media screen and (max-width: 390px) {
        padding: 0px 0px !important;
        gap: 63px;
      }
    }

    &__col {
      width: calc(100% / 2);

      @media screen and (max-width: 767px) {
        width: 100%;
      }

      @media screen and (max-width: 450px) {
        margin-top: -20px;
        margin-bottom: 20px;
      }
    }

    .content {
      .shortText {
        font-size: 1.15rem;
        font-weight: 400;

        @media screen and (max-width: 1023px) {
          font-size: 1rem;
        }

        @media screen and (max-width: 767px) {
          font-size: 0.985rem;
        }

        p {
          line-height: 1.4;
          margin-bottom: 0.625rem;

          &:last-child {
            margin-bottom: 0;
            margin-top: 10px;
            word-spacing: normal;
            font-size: 1.45rem !important;
            line-height: 30px;
            font-weight: 400;

            @media screen and (min-width: 2560px) and (min-height: 1600px) {
              width: 100%;
              font-size: 1.4rem !important;
              line-height: 30rem !important;
              text-align: left;
              font-weight: 400;
            }

            @media screen and (max-width: 1600px) {
              width: 100%;
              font-size: 1.4rem !important;
              line-height: 29px !important;
              text-align: left;
            }

            @media screen and (max-width: 1440px) {
              width: 100%;
              font-size: 1.1rem !important;
              line-height: 29px !important;
              text-align: left;
            }

            @media screen and (max-width: 430px) {
              font-size: 1.2rem;
              line-height: 35px;
              text-align: left;
            }

            @media screen and (max-width: 200px) {
              font-size: 1rem;
              line-height: 35px;
              text-align: left;
            }
          }
        }
      }
    }

    .picture {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      background: url(../../images/curve-layer-blue.png);
      background-repeat: no-repeat !important;
      background-position: center center !important;
      background-size: contain !important;
      height: 100%;
      width: 100%;
      min-height: 370px;
      padding: 37px 0 123px 99px;
      text-align: center;

      iframe {
        @media screen and (max-width: 1000px) {
          height: 280px;
        }
      }

      @media screen and (min-width: 1200px) and (max-width: 1400px) {
        padding: 35px 0 80px 79px;
      }

      @media screen and (max-width: 767px) {
        background-size: 100% auto !important;
      }

      @media screen and (max-width: 767px) {
        padding: 0 0 35px 0;
        min-height: 320px;
      }

      @media screen and (max-width: 450px) {
        padding: 0 0 35px 0;
        min-height: 340px !important;
      }

      &--cliplayerBlue {
        &::after {
          content: "";
          background: url(../../images/birthday-partybook-img01.png);
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 100% auto;
          position: absolute;
          width: 210px;
          height: 215px;
          left: 0;
          bottom: -140px;
          z-index: 1;

          @media screen and (max-width: 767px) {
            width: 115px;
            height: 110px;
            bottom: -60px;
          }

          @media screen and (max-width: 450px) {
            width: 115px;
            height: 110px;
            bottom: 0px;
          }
        }

        &--afterNone {
          &::after {
            display: none;
          }
        }
      }

      &--cliplayerPurple {
        background: url(../../images/curve-layer-purple.png);

        &::after {
          content: "";
          background: url(../../images/Brush.png);
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 100% auto;
          position: absolute;
          width: 155px;
          height: 248px;
          right: 20px;
          bottom: -140px;
          z-index: 1;

          @media screen and (max-width: 767px) {
            width: 70px;
            height: 110px;
            bottom: -60px;
          }

          @media screen and (max-width: 450px) {
            width: 70px;
            height: 110px;
            bottom: 0px;
          }
        }

        &--afterNone {
          &::after {
            display: none;
          }
        }
      }

      &--cliplayerPink {
        background: url(../../images/curve-layer-pink.png);

        &::after {
          content: "";
          background: url(../../images/music-overlay-01.png);
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 100% auto;
          position: absolute;
          width: 371px;
          height: 365px;
          left: -16px;
          bottom: -185px;
          z-index: 1;
          rotate: 6deg;

          @media screen and (max-width: 767px) {
            width: 160px;
            height: 92px;
            bottom: -60px;
          }

          @media screen and (max-width: 630px) {
            bottom: -29px;
          }

          @media screen and (max-width: 450px) {
            width: 160px;
            height: 92px;
            bottom: 0px;
          }

          @media screen and (max-width: 430px) {
            width: 160px;
            height: 185px;
          }

          @media screen and (max-width: 420px) {
            width: 160px;
            height: 92px;
            bottom: -67px !important;
          }
        }

        &--afterNone {
          &::after {
            display: none;
          }
        }
      }

      &--teamCheer {
        background: url(../../images/enrichment-images/team-cheer-pink.png);

        &::after {
          content: "";
          // background: url(../../images/music-overlay-01.png);
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 100% auto;

          @media screen and (max-width: 767px) {
            width: 100%;
            height: 92px;
            bottom: -60px;
          }
        }

        &--afterNone {
          &::after {
            display: none;
          }
        }
      }

      &--enrichment {
        background: url(../../images/enrichment-images/team-cheer-image-bg.png);
        width: 100%;
        height: auto;

        &::after {
          content: "";
          // background: url(../../images/music-overlay-01.png);
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 100% auto;

          @media screen and (max-width: 767px) {
            width: 100%;

            bottom: -60px;
            height: auto;
          }

          @media screen and (max-width: 1024px) {
            padding: 300px;
            // bottom: -60px;
          }
        }

        &--afterNone {
          &::after {
            display: none;
          }
        }
      }

      // CAMP MAIN PROGRMA IMAGE
      &--clipCampMain {
        background: url(../../images/camp-bg-blue.png);
        position: relative;
        width: 100%;

        &::after {
          content: "";
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 100% auto;

          @media screen and (max-width: 767px) {
            width: 100%;
            bottom: -60px;
            height: auto;
          }

          @media screen and (max-width: 1024px) {
            padding: 300px;
            // bottom: -60px;
          }
        }

        &--afterNone {
          &::after {
            display: none;
          }
        }
      }

      &--clipCampVersion {
        background: url(../../images/enrichment-images/orange-bg.png);
        position: relative;
        width: 100%;

        &::after {
          content: "";
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 100% auto;

          @media screen and (max-width: 767px) {
            width: 100%;
            bottom: -60px;
            height: auto;
          }

          @media screen and (max-width: 1024px) {
            padding: 300px;
            // bottom: -60px;
          }
        }

        &--afterNone {
          &::after {
            display: none;
          }
        }
      }

      &__img_boy {
        position: relative;
        display: block;
        margin: 0 auto;
        transform: rotate(-5deg);
        bottom: -25px;
        left: -70px;

        @media screen and (max-width: 639px) {
          width: 92%;
          left: 0;
        }
      }

      &__img {
        position: relative;
        display: block;
        margin: 0 auto;
        transform: rotate(6deg);
        bottom: -13px;
        // left: 40px;

        @media screen and (max-width: 639px) {
          width: 92%;
          left: 0;
        }
      }

      &__img_party {
        position: relative;
        display: block;
        margin: 0 auto;
        transform: rotate(-4deg);
        bottom: -27px;
        left: -98px;

        @media screen and (max-width: 639px) {
          width: 92%;
          left: 0;
        }
      }
    }

    .title {
      &--fixWidth {
        max-width: 760px;
        margin-left: auto;
        margin-right: auto;

        @media screen and (max-width: 767px) {
          font-size: 1.3rem !important;
          text-align: center !important;
        }

        span {
          display: block;
          text-align: right;
          padding-top: 5px;

          @media screen and (max-width: 767px) {
            text-align: center;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .camp-row {
    flex-direction: column-reverse;
    gap: 30px;
  }
}

@media screen and (max-width: 450px) {
  .enrichment_heading {
    gap: 23px;
  }
}

.staffData__col {
  .content {
    .shortText {
      font-size: 1.15rem;
      font-weight: 400;

      @media screen and (max-width: 1023px) {
        font-size: 1rem;
      }

      @media screen and (max-width: 767px) {
        font-size: 0.985rem;
      }

      p {
        line-height: 1.4;
        margin-bottom: 0.625rem;

        &:last-child {
          margin-bottom: 0;
          margin-top: 10px;
          line-height: 29px;
          word-spacing: 2px;
          font-size: 1rem;
        }
      }
    }
  }
}

.contactInfoSec {
  padding: 0px 0 80px 0px;

  @media screen and (max-width: 991px) {
    padding: 35px 0;
  }

  @media screen and (max-width: 767px) {
    padding-top: 0;
  }

  @media screen and (max-width: 450px) {
    padding: 20px 15px;
  }

  .contactInfo {
    display: flex;
    align-items: flex-start;
    gap: 30px;
    padding-top: 22px;

    @media screen and (max-width: 1199px) {
      padding-top: 0;
      flex-wrap: wrap;
    }

    @media screen and (max-width: 1023px) {
      flex-wrap: nowrap;
    }

    @media screen and (max-width: 767px) {
      flex-wrap: wrap;
      padding: 0px 35px;
    }

    &__item {
      display: flex;
      align-items: flex-start;
      width: calc(100% / 3);
      gap: 20px;

      @media screen and (max-width: 1199px) {
        width: auto;
      }

      @media screen and (max-width: 767px) {
        width: 100%;
        gap: 10px;
      }
    }

    &__icon {
      flex: 0 0 70px;
      width: 70px;

      @media screen and (max-width: 767px) {
        flex: 0 0 55px;
        height: 55px;
      }

      svg {
        @media screen and (max-width: 767px) {
          width: 45px;
          height: 45px;
        }
      }
    }

    &__text {
      font-size: 1.5rem;
      font-weight: 400;
      color: $black;

      @media screen and (max-width: 767px) {
        font-size: 1.125rem;
      }

      .title {
        font-size: 1.1rem;
        font-weight: 400;
        color: #141d28;
        margin-bottom: 0.125rem;

        @media screen and (max-width: 1365px) {
          font-size: 1.875rem;
        }

        @media screen and (max-width: 767px) {
          font-size: 1.25rem;
        }
      }

      .para {
        &__link {
          display: inline-block;
          vertical-align: middle;
          text-decoration: none;
          color: $black;
          font-size: 1.1rem;
        }
      }
    }
  }
}

.aboutvideoSec {
  background: $green;
  padding: 90px 0 60px;
  position: relative;
  clip-path: polygon(0 10%, 100% 0, 100% 100%, 0 100%);

  .flexBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 60px;

    @media screen and (max-width: 767px) {
      flex-direction: column;
      gap: 40px;
      padding: 10px 35px;
    }

    &__col {
      width: 50%;

      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }

    .title {
      font-size: 3.75rem;
      color: $white;
      margin-bottom: 0.938rem;

      @media screen and (max-width: 1365px) {
        font-size: 3rem;
      }

      @media screen and (max-width: 1165px) {
        font-size: 2.5rem;
      }

      @media screen and (max-width: 767px) {
        font-size: 1.5rem;
      }
    }

    .shortText {
      font-size: 1.2rem;
      font-weight: 500;
      color: $white;
      line-height: 30px;

      @media screen and (max-width: 1365px) {
        font-size: 1.15rem;
        line-height: 28px;
      }

      @media screen and (max-width: 1023px) {
        font-size: 1rem;
      }

      @media screen and (max-width: 991px) {
        font-size: 0.938rem;
        line-height: 24px;
      }

      p {
        margin-bottom: 0.625rem;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .videothumnialbox {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      background: url(../../images/about-video-bglayer.png);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 100% 100%;
      height: 100%;
      width: 100%;
      min-height: 480px;
      padding: 45px 0 35px 45px;
      text-align: center;

      @media screen and (max-width: 991px) {
        min-height: 1px;
      }

      &__pic {
        position: relative;
        width: 90%;
        top: -30px;
        left: -100px;
        transform: rotate(-4deg);

        @media screen and (max-width: 991px) {
          top: -10px;
          left: -30px;
        }

        .videoLink {
          // display: flex;
          // align-items: center;
          // justify-content: center;
          // position: absolute;
          // left: 0;
          // right: 0;
          // top: 0;
          // bottom: 0;
          // z-index: 1;

          &__svg {
            width: 80px;
            height: 80px;

            @media screen and (max-width: 767px) {
              width: 40px;
              height: 40px;
            }
          }
        }
      }
    }
  }
}

.contentwraper {
  padding-bottom: 100px;
}

.staff_contentwraper {
  padding-bottom: 0px !important;
}

.facilitiesSec {
  padding: 20px 20px 20px;

  @media screen and (max-width: 991px) {
    padding: 35px 0;
  }

  @media screen and (max-width: 450px) {
    padding: 0px 70px;
  }

  @media screen and (max-width: 430px) {
    padding: 7px 48px !important;
  }

  @media screen and (max-width: 420px) {
    padding: 15px 10px;
  }

  @media screen and (max-width: 414px) {
    padding: 15px 35px;
  }

  .title {
    font-size: 3rem;
    margin-bottom: 1.2rem;

    @media screen and (max-width: 1365px) {
      font-size: 2.3rem;
    }

    @media screen and (max-width: 1199px) {
      font-size: 3rem;
    }

    @media screen and (max-width: 991px) {
      font-size: 2.5rem;
    }

    @media screen and (max-width: 767px) {
      font-size: 1.5rem;
    }

    &--withicon {
      display: inline-block;
      vertical-align: middle;
      padding: 20px 160px 20px 0;
      position: relative;

      @media screen and (max-width: 1365px) {
        padding-right: 140px;
      }

      @media screen and (max-width: 991px) {
        padding-right: 110px;
      }

      @media screen and (max-width: 767px) {
        // padding-right: 80px;
        margin-bottom: 0;
      }

      // &::after {
      //   content: "";
      //   background: url(../../images/facility-overlay.png);
      //   background-repeat: no-repeat;
      //   background-size: 100% auto;
      //   width: 144px;
      //   height: 133px;
      //   position: absolute;
      //   right: 0;
      //   top: 0;
      //   z-index: 1;

      //   @media screen and (max-width: 1365px) {
      //     width: 115px;
      //     height: 105px;
      //   }

      //   @media screen and (max-width: 991px) {
      //     width: 90px;
      //     height: 85px;
      //   }

      //   @media screen and (max-width: 767px) {
      //     width: 65px;
      //     height: 60px;
      //   }
      // }
    }
  }

  .facility_gymTimeGuywithName {
    display: flex;
    gap: 20px;
    align-items: center;

    @media screen and (max-width: 1365px) {
      gap: 15px;
    }

    @media screen and (max-width: 991px) {
      gap: 10px;
    }

    @media screen and (max-width: 767px) {
      gap: 10px;
    }

    @media screen and (max-width: 450px) {
      margin-left: -25px;
    }
  }

  .gymTimeGuyIcon_fac {
    width: 120px;
    height: 120px;

    @media screen and (max-width: 1365px) {
      width: 115px;
      height: 105px;
    }

    @media screen and (max-width: 991px) {
      width: 90px;
      height: 85px;
    }

    @media screen and (max-width: 767px) {
      width: 76px !important;
      height: 60px;
    }

    @media screen and (max-width: 450px) {
      width: 100% !important;
      height: 100px !important;
    }
  }

  .shortText {
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 30px;
    margin-top: 2%;
    margin-bottom: 25px;

    @media screen and (max-width: 1365px) {
      font-size: 1.3rem;
      line-height: 35px;
    }

    @media screen and (max-width: 991px) {
      font-size: 1.1rem;
    }

    P {
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;

        @media screen and (min-width: 2560px) and (min-height: 1600px) {
          font-size: 1.1rem;
          line-height: 30px;
        }
      }
    }
  }

  .facilitiesList {
    padding: 45px 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 -8px;

    &__item {
      width: calc(100% / 5);
      padding: 15px 8px;
      cursor: pointer;

      @media screen and (max-width: 1000px) {
        width: 33.33%;
      }
    }

    &__card {
      display: flex;
      align-items: center;
      justify-content: center;
      height: auto;

      &__link {
        display: block;
        vertical-align: middle;
        height: auto;
      }

      &__img {
        max-width: 100%;
        height: auto;
      }
    }
  }

  .tabs {
    .tab-link {
      &.current {
        opacity: 0.7;

        @media screen and (max-width: 1000px) {
          width: 33.33%;
        }
      }
    }
  }

  .tab-contents {
    .tab-content {
      display: none;
      padding: 0px 80px;

      &.current {
        display: block;
        max-width: 100%;
      }
    }
  }

  .galleryGrid {
    .owl-nav {
      position: absolute;
      top: 50%;
      width: 100%;
      margin-top: -45px;
      z-index: 11;

      button {
        // background: #dcdcdc;
        position: absolute;
        left: -80px;
        width: 36px;
        height: 60px;
        outline: none;
        margin: 0;
        border-radius: 50px;

        @media screen and (max-width: 425px) {
          // background: #dcdcdc;
          position: absolute;
          left: -70px;
          width: 28px;
          height: 60px;
          outline: none;
          /* border-radius: 50px; */
          top: 59px;
        }

        &.owl-next {
          left: auto;
          right: -80px;

          @media screen and (max-width: 425px) {
            right: -70px;
          }

          span {
            transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
            -o-transform: rotate(-45deg);
            right: 4px;
            left: auto;
          }
        }

        span {
          content: "";
          display: inline-block;
          position: relative;
          width: 15px;
          height: 15px;
          font-size: 0;
          border-right: 3px solid #404040;
          border-bottom: 3px solid #404040;
          transform: rotate(-0deg);
          -webkit-transform: rotate(135deg);
          -moz-transform: rotate(135deg);
          -ms-transform: rotate(135deg);
          -o-transform: rotate(135deg);
          left: 4px;
          top: -7px;
        }
      }
    }
  }

  .maxwith1000 {
    max-width: 850px;
    margin: 0 auto;
  }

  .maxwith1100 {
    max-width: 900px;
    margin: 0 auto;
  }

  .commonList {
    padding: 45px 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    margin: 0 -8px;

    @media screen and (max-width: 991px) {
      padding: 20px 0;
    }

    &__item {
      width: calc(100% / 4);
      padding: 8px;
      cursor: pointer;

      @media screen and (max-width: 991px) {
        width: calc(100% / 3);
      }
    }

    &__card {
      display: flex;
      align-items: center;
      justify-content: center;
      height: auto;

      &__link {
        display: block;
        vertical-align: middle;
        height: auto;
      }

      &__img {
        max-width: 100%;
        height: auto;
      }
    }

    &--threecolumn {
      margin: 0 -5px;

      .commonList__item {
        width: calc(100% / 4);
        padding: 5px;
        cursor: pointer;
        margin-top: 20px;

        @media screen and (max-width: 991px) {
          width: calc(100% / 3);
        }

        @media screen and (max-width: 2560px) {
          margin: 0px;
        }
      }
    }
  }
}

.programList {
  margin-top: 60px;
  // padding: 30px 23px 0px 23px;

  @media screen and (max-width: 639px) {
    padding-top: 0px;
    padding-bottom: 30px;
  }

  &__item {
    margin-bottom: 30px;
    padding: 0px 16px;

    @media screen and (max-width: 435px) {
      padding: 0px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:nth-child(2n + 1) {
      .programList__card .picture .tagmark {
        background: $green;
      }

      .programList__card .detail .buttons .btn {
        background: $orange;
        clip-path: polygon(6% 0, 90% 0, 100% 100%, 0% 100%);
      }
    }

    &:nth-child(3n + 3) {
      .programList__card .picture .tagmark {
        background: $orange;
      }

      .programList__card .detail .buttons .btn {
        background: $purple;
        clip-path: polygon(6% 0, 90% 0, 100% 100%, 0% 100%);
      }
    }

    &:nth-child(4n + 4) {
      .programList__card .picture .tagmark {
        background: $purple;
      }

      .programList__card .detail .buttons .btn {
        background: $pink;
        clip-path: polygon(6% 0, 90% 0, 100% 100%, 0% 100%);
      }
    }
  }

  &__card {
    display: flex;
    align-items: center;
    gap: 2px;
    background: #fff;
    padding: 18px;
    box-shadow: 0px 24.2957763671875px 54px 0px #cdcdcd66;

    @media screen and (min-width: 1012px) and (max-width: 1030px) {
      padding: 0px;
    }

    @media screen and (max-width: 991px) {
      flex-direction: column;
      gap: 30px;
    }

    @media screen and (max-width: 639px) {
      padding: 20px;
    }

    .picture {
      flex: 0 0 340px;
      width: 350px;
      position: relative;
      padding-right: 40px;

      @media screen and (max-width: 1199px) {
        flex: 0 0 280px;
        width: 280px;
      }

      @media screen and (max-width: 991px) {
        flex: 0 0 100%;
        width: 100%;
        padding-right: 0;
      }

      &__img {
        max-width: 100%;
        width: 100%;
      }

      .tagmark {
        background: $pink;
        padding: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.875rem;
        font-weight: 800;
        color: $white;
        position: absolute;
        right: -20px;
        bottom: -10px;
        width: auto;
        min-width: 135px;
        height: 112px;
        text-align: center;
        clip-path: polygon(0 0, 100% 0, 92% 100%, 7% 100%);
        z-index: 1;

        @media screen and (max-width: 1439px) {
          font-size: 1.5rem;
        }

        @media screen and (max-width: 1199px) {
          font-size: 1.375rem;
        }

        @media screen and (max-width: 639px) {
          font-size: 1.25rem;
          min-width: 118px;
        }
      }
    }

    .detail {
      display: flex;
      align-items: center;
      width: 100%;
      gap: 40px;

      @media screen and (max-width: 639px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
      }

      .title {
        font-size: 2rem;
        line-height: 1;
        margin-bottom: 0.4rem;

        @media screen and (max-width: 1439px) {
          font-size: 2rem;
        }

        @media screen and (max-width: 1199px) {
          font-size: 1.875rem;
        }

        @media screen and (max-width: 991px) {
          font-size: 1.5rem;
        }

        @media screen and (max-width: 639px) {
          font-size: 1.25rem;
        }
      }

      .timeperiod {
        font-size: 1.1rem;
        font-weight: 600;
        line-height: 1.1;

        @media screen and (max-width: 1199px) {
          font-size: 1.5rem;
        }

        @media screen and (max-width: 991px) {
          font-size: 1.25rem;
        }

        @media screen and (max-width: 639px) {
          font-size: 1rem;
        }
      }

      .para {
        // font-size: 0.8rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 20px;
     

        @media screen and (max-width: 1199px) {
          font-size:1rem;
          line-height: 28px;
        }

        @media screen and (max-width: 991px) {
          font-size: 1rem;
          line-height: 24px;
        }
      }

      .buttons {
        .btn {
          // background: url(../../images/button-bg-orange-shape.png);
          background: #00bbe3;
          clip-path: polygon(6% 0, 90% 0, 100% 100%, 0% 100%);
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 100% auto;
          height: 100%;
          width: 100%;
          font-size: 1.3rem;
          padding: 12px;
          border: none;
          font-weight: 800;
          border-radius: 0;

          @media screen and (max-width: 991px) {
            font-size: 0.875rem;
          }
        }
      }
    }
  }
}

.commonSec {
  padding: 29px 23px;

  @media screen and (max-width: 630px) {
    padding: 0px 35px;
  }

  @media screen and (max-width: 991px) {
    padding: 35px 0;
  }

  @media screen and (max-width: 450px) {
    padding: 35px 25px;
  }

  @media screen and (max-width: 430px) {
    padding: 30px 35px;
  }

  @media screen and (max-width: 400px) {
    padding: 0px 35px !important;
  }

  @media screen and (max-width: 391px) {
    padding: 10px 35px;
  }

  @media screen and (max-width: 375px) {
    padding: 0px 0px !important;
  }

  .title {
    font-size: 4rem;

    @media screen and (max-width: 1365px) {
      font-size: 3.5rem;
    }

    @media screen and (max-width: 1199px) {
      font-size: 3rem;
    }

    @media screen and (max-width: 991px) {
      font-size: 2rem;
    }

    @media screen and (max-width: 390px) {
      padding: 0px 25px;
    }
  }

  .shortText {
    font-size: 1.075rem;
    font-weight: 400;
    line-height: 36px;

    @media screen and (max-width: 1365px) {
      font-size: 1.25rem;
      line-height: 28px;
    }

    @media screen and (max-width: 991px) {
      font-size: 0.938rem;
    }

    h3 {
      font-weight: 600;
      margin-bottom: 0;
    }

    h4 {
      font-weight: 600;
      margin-bottom: 15px;
    }

    P {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .contentWithImg {
    .title {
      font-size: 4rem;
      text-align: left;
      margin-bottom: 40px;

      @media screen and (max-width: 1439px) {
        font-size: 2.5rem;
      }

      @media screen and (max-width: 1199px) {
        font-size: 1.875rem;
      }

      @media screen and (max-width: 767px) {
        font-size: 1.5rem;
        margin-bottom: 20px;
        padding: 0px;
      }

      @media screen and (max-width: 390px) {
        font-size: 1.5rem;
        margin-bottom: 20px;
        padding: 0px;
      }
    }

    &__row {
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;
      align-items: center;
      gap: 80px;

      @media screen and (max-width: 1023px) {
        gap: 40px;
      }

      @media screen and (max-width: 767px) {
        flex-direction: column-reverse;
        gap: 30px;
      }
    }

    &__col {
      width: calc(100% / 2);

      @media screen and (max-width: 767px) {
        width: 100%;
        // display: none;
      }
    }

    .content {
      .shortText {
        font-size: 1.1rem;
        font-weight: 400;

        @media screen and (max-width: 1365px) {
          font-size: 1.1rem;
          line-height: 30px;
        }

        @media screen and (max-width: 1199px) {
          font-size: 1rem;
        }

        @media screen and (max-width: 991px) {
          font-size: 0.938rem;
        }

        p {
          line-height: 1.6;
          margin-bottom: 0.625rem;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    .picture {
      &__img {
        display: block;
        margin: 0 auto;
        max-width: 380px;

        @media screen and (max-width: 1023px) {
          max-width: 100%;
        }
      }
    }
  }
}

.staff-content {
  .shortText {
    font-size: 1.15rem;
    font-weight: 400;

    @media screen and (max-width: 1023px) {
      font-size: 1rem;
    }

    @media screen and (max-width: 767px) {
      font-size: 0.985rem;
    }

    p {
      line-height: 1.4;
      margin-bottom: 0.625rem;

      &:last-child {
        margin-bottom: 0;
        margin-top: 10px;
        line-height: 29px;
        word-spacing: 2px;
        font-size: 1rem;
      }
    }
  }
}

.staffSec {
  padding: 20px 0px;

  @media screen and (max-width: 991px) {
    padding: 35px 0;
  }

  @media screen and (max-width: 450px) {
    padding: 10px 15px;
  }

  @media screen and (max-width: 420px) {
    padding: 10px 0px;
  }

  .staffData {
    &__row {
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;
      gap: 35px;
      margin-bottom: 60px;
      align-items: center;

      @media screen and (max-width: 767px) {
        flex-direction: column;
        gap: 40px;
      }

      @media screen and (max-width: 430px) {
        flex-direction: column;
        gap: 15px;
        padding: 0px 2px !important;
      }

      @media screen and (max-width: 450px) {
        flex-direction: column;
        gap: 15px;
        padding: 0px 35px;
      }
    }

    &__col {
      width: calc(100% / 2);

      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }

    .content {
      .shortText {
        font-size: 1.1rem;
        font-weight: 400;

        @media screen and (max-width: 1365px) {
          font-size: 1.15rem;
          line-height: 28px;
        }

        @media screen and (max-width: 991px) {
          font-size: 0.938rem;
        }

        p {
          line-height: 1.4;
          margin-bottom: 0.625rem;

          &:last-child {
            margin-bottom: 0;
            margin-top: 10px;
            line-height: 29px;
            font-size: 1.45rem;

            @media screen and (max-width: 500px) {
              font-size: 1rem;
              line-height: 1.6rem;
            }
          }
        }
      }
    }

    .picture {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: relative;
      // background: url(../../images/staff-layer-main.png);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 100% auto;
      height: 100%;
      width: 100%;
      min-height: 432px;
      padding: 10px;
      text-align: center;

      @media screen and (max-width: 1365px) {
        min-height: 350px;
      }

      @media screen and (max-width: 1165px) {
        min-height: 280px;
      }

      @media screen and (max-width: 767px) {
        min-height: 100px;
        padding-bottom: 20px;
      }

      @media screen and (max-width: 393px) {
        padding-bottom: 0px;
      }

      &__box {
        // margin: -30px 0 35px;

        @media screen and (max-width: 1365px) {
          margin-bottom: 20px;
        }

        @media screen and (max-width: 767px) {
          margin-bottom: 10px;
        }
      }

      &__img {
        display: block;
        margin: 0 auto;
        position: relative;
        right: -33px;
        transform: rotate(2deg);
        margin-top: 50px;
      }

      .name {
        font-size: 2.25rem;
        font-weight: 700;
        color: $white;
        margin-bottom: 1rem;

        @media screen and (max-width: 1365px) {
          font-size: 1.5rem;
        }

        @media screen and (max-width: 1165px) {
          font-size: 1.125rem;
        }

        @media screen and (max-width: 767px) {
          margin-bottom: 0.625rem;
        }
      }

      .position {
        font-size: 2.25rem;
        font-weight: 500;
        color: $white;
        line-height: 1;
        margin-bottom: 0;

        @media screen and (max-width: 1365px) {
          font-size: 1.5rem;
        }

        @media screen and (max-width: 1165px) {
          font-size: 1.125rem;
        }
      }
    }
  }

  .ourStaff {
    padding: 0px 35px;

    @media screen and (min-width: 2560px) and (min-height: 1600px) {
      padding: 0px 220px;
    }

    &__topblock {
      margin-bottom: 15px;

      @media screen and (max-width: 450px) {
        text-align: center;
      }

      .title {
        margin-bottom: 0;

        @media screen and (max-width: 450px) {
          font-size: 2.2rem !important;
          text-align: left;
        }

        @media screen and (max-width: 390px) {
          font-size: 2rem;
        }
      }
    }

    &__list {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 4rem;

      &__item {
        padding: 0 8px;
        width: calc(100% / 4);

        @media screen and (max-width: 1023px) {
          width: calc(100% / 2);
        }

        @media screen and (max-width: 567px) {
          width: calc(100% / 1);

          &::last-child {
            .ourStaff__list__card {
              padding-bottom: 0;
            }
          }
        }
      }

      &__card {
        padding: 15px 0;
      }
    }
  }
}

.staff_name {
  font-size: 1.4rem;
  font-weight: 600;
}

.staff_position {
  // text-align: center;
  margin-top: -7px;
  font-size: 1.1rem;
  font-weight: 500;
  color: rgb(173, 169, 169);
}

.staff_email {
  // text-align: center;
  margin-top: -7px;
  font-size: 1.1rem;
  font-weight: 500;
  color: rgb(173, 169, 169);
}

.programDetialSec {
  padding: 40px 0px 100px;

  @media screen and (max-width: 991px) {
    padding: 30px 0;
  }

  @media screen and (max-width: 450px) {
    padding: 10px 35px;
  }

  @media screen and (max-width: 390px) {
    padding: 10px 35px !important;
  }

  @media screen and (max-width: 400px) {
    padding: 30px 35px;
  }

  .programCard {
    margin-bottom: 80px;

    @media screen and (max-width: 767px) {
      margin-bottom: 30px;
      padding: 0px 20px;
    }

    @media screen and (max-width: 414px) {
      padding: 0px 0px;
    }

    .flexbox {
      display: flex;
      flex-direction: row-reverse;
      gap: 40px;
      align-items: flex-start;

      @media screen and (max-width: 767px) {
        flex-direction: column;
        flex-direction: column-reverse;
        gap: 30px;
      }

      .picture {
        flex: 0 0 600px;
        width: 600px;
        position: relative;
        padding-right: 40px;

        @media screen and (max-width: 1199px) {
          flex: 0 0 400px;
          width: 400px;
        }

        @media screen and (max-width: 767px) {
          flex: 0 0 100%;
          width: 100%;
          padding-right: 0;
        }

        &__img {
          max-width: 100%;
          width: 100%;
        }

        .tagmark {
          background: $pink;
          padding: 15px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1.875rem;
          font-weight: 800;
          color: $white;
          position: absolute;
          right: -20px;
          bottom: -10px;
          width: 162px;
          height: 112px;
          text-align: center;
          clip-path: polygon(0 0, 100% 0, 92% 100%, 7% 100%);
          z-index: 1;

          @media screen and (max-width: 1439px) {
            font-size: 1.5rem;
          }

          @media screen and (max-width: 1199px) {
            font-size: 1.375rem;
          }

          @media screen and (max-width: 639px) {
            font-size: 1.25rem;
            right: -15px;
          }
        }
      }
    }

    .title {
      position: relative;
      // color: #252b42;
      padding-left: 39px;
      margin-bottom: 25px;
      min-height: 140px;

      @media screen and (max-width: 1439px) {
        padding-left: 34px;
        min-height: 100px;
      }

      @media screen and (max-width: 991px) {
        min-height: 60px;
        font-size: 2rem;
        width: 100%;
        padding: 0px;
      }

      .timeperiod {
        display: block;
        font-size: 1.875rem;
        font-weight: 600;
        color: #6e6e6e;

        @media screen and (max-width: 1439px) {
          font-size: 1.5rem;
        }
      }
    }

    .shortText {
      width: calc(100% - 600px);
      font-size: 1.5rem;
      font-weight: 400;
      line-height: 36px;

      @media screen and (max-width: 1365px) {
        font-size: 1.25rem;
        line-height: 28px;
      }

      @media screen and (max-width: 1199px) {
        width: calc(100% - 400px);
      }

      @media screen and (max-width: 991px) {
        font-size: 0.938rem;
      }

      @media screen and (max-width: 767px) {
        width: 100%;
      }

      p {
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .classAvailability {
    &__header {
      display: flex;
      align-items: center;
      gap: 15px;
      margin-bottom: 40px;

      @media screen and (max-width: 1365px) {
        margin-bottom: 30px;
      }

      @media screen and (max-width: 639px) {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 25px;
      }

      .title {
        &--h4 {
          font-size: 2.875rem;
          font-weight: 700;
          margin-bottom: 0;

          @media screen and (max-width: 1365px) {
            font-size: 2.25rem;
          }

          @media screen and (max-width: 991px) {
            font-size: 1.875rem;
          }

          @media screen and (max-width: 767px) {
            font-size: 1.375rem;
          }
        }
      }
    }

    .title {
      &--extrabold {
        @media screen and (max-width: 1365px) {
          font-size: 2rem !important;
        }

        @media screen and (max-width: 991px) {
          font-size: 1.875rem !important;
        }

        @media screen and (max-width: 767px) {
          font-size: 1.5rem !important;
        }

        @media screen and (max-width: 450px) {
          font-size: 2rem !important;
        }
      }
    }

    .addStudentList {
      margin-bottom: 40px;

      @media screen and (max-width: 991px) {
        margin-bottom: 30px;
      }

      &__item {
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      &__card {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
        padding: 20px;
        box-shadow: 0px 2.0239179134368896px 16.191343307495117px 0px #00000040;
        border-left: 16px solid $pink;
        border-radius: 5px;
        height: 160px;

        @media screen and (max-width: 991px) {
          padding: 15px;
          gap: 15px;
        }

        .flexbox {
          display: flex;
          align-items: center;
          gap: 15px;
        }

        .pic {
          width: 85px;
          height: 85px;
          border: 6px solid $white;
          border-radius: 50%;
          box-shadow: 3.2895123958587646px 3.2895123958587646px 5.756647109985352px 0px #384d6c40;

          &__img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
          }
        }

        .name {
          font-size: 1rem;
          font-weight: 700;
          margin-bottom: 2px;
        }

        .para {
          font-size: 1rem;
          font-weight: 400;
          margin-bottom: 2px;

          &--lightColor {
            font-size: 0.813rem;
            color: rgba($black, 0.62);
            text-transform: uppercase;
            margin-bottom: 0;
          }
        }

        .btnActions {
          display: flex;
          align-items: center;
          gap: 12px;

          .btnTransparent {
            background: none;
            border: none;
            cursor: pointer;
          }
        }

        .form {
          &__radio {
            &__label {
              &::before {
                border: 2px solid $black;
                top: 50%;
                margin-top: -9px;
              }
            }

            &__input {
              &:checked {
                +.form__radio__label {
                  &::after {
                    background-color: $black;
                    top: 50%;
                    margin-top: -3px;
                  }
                }
              }
            }
          }
        }
      }
    }

    &__list {
      display: flex;
      flex-direction: column;
      gap: 40px;

      &__card {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        background: #fff;
        padding: 30px;
        box-shadow: 0px 25.02981948852539px 50.05963897705078px 0px #89898966;

        @media screen and (max-width: 991px) {
          flex-direction: column;
          align-items: flex-start;
          padding: 20px;
          gap: 15px;
          font-size: 0.97rem;
        }

        .item {
          width: 35%;

          &:last-child {
            width: 100%;

            @media screen and (max-width: 1199px) {
              width: 65%;
            }

            @media screen and (max-width: 991px) {
              width: 100%;
            }
          }

          @media screen and (max-width: 991px) {
            width: 100%;
          }

          &--flexbox {
            display: flex;
            align-items: center;
            gap: 30px;

            @media screen and (max-width: 767px) {
              gap: 15px;
            }

            &--spacebetween {
              justify-content: space-between;

              @media screen and (max-width: 767px) {
                flex-direction: column;
                gap: 15px;
                align-items: flex-start;
              }
            }
          }

          .buttonsInfo {
            text-align: center;

            @media screen and (max-width: 767px) {
              width: 100%;
            }

            .spotLeft {
              font-size: 1.375rem;
              font-weight: 700;
              color: $pink;
              margin-bottom: 0.5rem;

              @media screen and (max-width: 1439px) {
                font-size: 1.25rem;
              }

              @media screen and (max-width: 639px) {
                font-size: 1rem !important;
              }

              &--gray {
                color: rgba($black, 0.62);
              }
            }

            .btn {
              background: transparent;
              color: $pink;
              border: 1px solid $pink;
              font-weight: 800;
              border-radius: 12px;
              min-width: 160px;
              // -webkit-transform: skew(-15deg);
              // -moz-transform: skew(-15deg);
              // transform: skew(-15deg);

              @media screen and (max-width: 767px) {
                width: 100%;
              }

              &--primary,
              &:hover {
                background: $pink;
                color: $white;
              }

              &__text {
                display: inline-block;
                vertical-align: middle;
                -webkit-transform: skew(15deg);
                -moz-transform: skew(15deg);
                transform: skew(15deg);
              }
            }
          }
        }

        .number {
          display: flex;
          justify-content: center;
          align-items: center;
          flex: 0 0 92px;
          width: 92px;
          height: 92px;
          font-size: 2.5rem;
          font-weight: 400;
          color: $black;
          border: 1px solid $orange;
          border-radius: 50%;

          @media screen and (max-width: 767px) {
            flex: 0 0 70px;
            width: 70px;
            height: 70px;
            font-size: 1.875rem;
          }
        }

        .languageText {
          display: inline-flex;
          background: #e9eef266;
          padding: 10px 15px;
          font-size: 0.688rem;
          font-weight: 700;
          color: #3c4852;
          text-transform: uppercase;
          border-radius: 0.35rem;
          margin-bottom: 15px;

          @media screen and (max-width: 767px) {
            margin-bottom: 10px;
          }
        }

        .title {
          font-size: 1.475rem;
          font-weight: 700;
          line-height: 1.1;
          max-width: 350px;
          margin-bottom: 10px;

          @media screen and (max-width: 1439px) {
            font-size: 1.5rem !important;
          }

          @media screen and (max-width: 1199px) {
            font-size: 1.375rem !important;
          }

          @media screen and (max-width: 639px) {
            font-size: 1.26rem !important;
          }

          &--h5 {
            font-size: 1.25rem;
          }

          .timing {
            font-size: 1.25rem;

            @media screen and (max-width: 767px) {
              font-size: 1rem;
            }
          }
        }

        .ages {
          @media screen and (max-width: 600px) {
            font-size: 1rem;
          }
        }

        .weekDays {
          @media screen and (max-width: 600px) {
            font-size: 1rem;
          }
        }

        .weekDays {
          @media screen and (max-width: 320px) {
            font-size: 0.9rem !important;
          }
        }

        .time {
          @media screen and (max-width: 600px) {
            display: flex;
            align-items: center;

            font-size: 1rem !important;
          }
        }

        .timeperiod {
          font-size: 1.25rem;
          font-weight: 600;
          color: #5e5f5f9e;
          margin-bottom: 0;

          @media screen and (max-width: 1365px) {
            font-size: 1rem;
          }
        }

        .price {
          font-size: 1.5rem;
          font-weight: 700;
          margin-bottom: 0;

          @media screen and (max-width: 1365px) {
            font-size: 1.25rem !important;
          }

          @media screen and (max-width: 767px) {
            font-size: 1.125rem;
          }
        }
      }
    }
  }
}

.Class_tuition_fess {
  font-size: 25px;
  font-weight: 600;
}

.ourClassesSec {
  background: $secondary;
  padding: 90px 0 100px;
  position: relative;
  clip-path: polygon(0 10%, 100% 0, 100% 100%, 0 100%);
  overflow: hidden;

  @media screen and (max-width: 767px) {
    clip-path: polygon(0 5%, 100% 0, 100% 100%, 0 100%);
  }

  .title {
    font-size: 3.438rem;
    font-weight: 700;
    color: $white;
    margin-bottom: 40px;

    @media screen and (max-width: 1365px) {
      font-size: 2.25rem !important;
    }

    @media screen and (max-width: 991px) {
      font-size: 1.875rem !important;
      margin-bottom: 25px;
    }

    @media screen and (max-width: 767px) {
      font-size: 1.5rem !important;
    }

    @media screen and (max-width: 450px) {
      text-align: center;
    }
  }

  .classesList {
    // display: flex;
    // flex-wrap: wrap;
    // margin: 0 -25px;

    &__item {
      width: calc(100% / 4);
      padding: 12px 25px;

      @media screen and (max-width: 1023px) {
        width: calc(100% / 2);
      }

      @media screen and (max-width: 639px) {
        width: 100%;
      }

      &:nth-child(2n + 2) {
        .classesCard .picture .tagmark {
          background: $orange;
        }
      }

      &:nth-child(3n + 3) {
        .classesCard .picture .tagmark {
          background: $green;
        }
      }

      &:nth-child(4n + 4) {
        .classesCard .picture .tagmark {
          background: $pink;
        }
      }
    }

    .owl-item {
      &:nth-child(2n + 2) {
        .classesCard .picture .tagmark {
          background: $orange;
        }
      }

      &:nth-child(3n + 3) {
        .classesCard .picture .tagmark {
          background: $green;
        }
      }

      &:nth-child(4n + 4) {
        .classesCard .picture .tagmark {
          background: $pink;
        }
      }
    }

    .classesCard {
      width: 100%;

      .picture {
        width: 100%;
        position: relative;

        &__img {
          max-width: 100%;
          width: 100%;
        }

        .tagmark {
          background: $purple;
          padding: 15px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1.875rem;
          font-weight: 800;
          color: $white;
          position: absolute;
          right: -20px;
          bottom: -10px;
          min-width: 172px;
          width: auto;
          min-height: 112px;
          text-align: center;
          clip-path: polygon(0 0, 100% 0, 92% 100%, 7% 100%);
          z-index: 1;

          @media screen and (max-width: 1439px) {
            font-size: 1.5rem;
          }

          @media screen and (max-width: 1199px) {
            font-size: 1.375rem;
          }

          @media screen and (max-width: 767px) {
            right: 0px;
            word-break: break-word;
          }

          @media screen and (max-width: 639px) {
            font-size: 1.25rem;
          }
        }
      }
    }
  }
}

.parentAccountSec {
  padding: 40px 0;

  &__topblock {
    margin-bottom: 3.75rem;

    @media screen and (max-width: 991px) {
      margin-bottom: 2.188rem;
      padding: 0px 25px;
    }

    .title {
      &--h1 {
        font-size: 2.5rem;
        margin-bottom: 0;

        @media screen and (max-width: 1365px) {
          font-size: 2.25rem !important;
        }

        @media screen and (max-width: 1199px) {
          font-size: 1.875rem !important;
        }

        @media screen and (max-width: 1023px) {
          font-size: 1.5rem !important;
        }
      }
    }
  }

  .tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 100px;
    margin-bottom: 3.75rem;

    @media screen and (max-width: 991px) {
      margin-bottom: 2.188rem;
    }

    @media screen and (max-width: 767px) {
      gap: 18px;
    }

    .tab-link {
      position: relative;
      cursor: pointer;

      .tabs__card {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 10px;
        width: 172px;
        height: 220px;
        padding: 20px;
        border: 1px solid $pink;
        border-radius: 0.75rem;
        text-align: center;
        position: relative;

        @media screen and (max-width: 767px) {
          padding: 15px;
          width: 120px;
          height: 140px;
        }

        @media screen and (max-width: 767px) {
          width: 120px;
          height: 140px;
        }

        @media screen and (max-width: 600px) {
          width: 100px;
          height: 129px;
        }

        &__icon {
          display: flex;
          align-items: flex-end;
          justify-content: center;
          min-height: 136px;

          @media screen and (max-width: 767px) {
            min-height: 75px;
          }
        }

        &__img {
          display: inline-block;
          vertical-align: middle;

          @media screen and (max-width: 767px) {
            max-width: 70px;
          }
        }

        &__text {
          font-size: 1.625rem;
          font-weight: 500;
          color: $pink;

          @media screen and (max-width: 1023px) {
            font-size: 1.25rem;
          }

          @media screen and (max-width: 767px) {
            font-size: 1rem;
          }
        }

        &::before {
          content: "";
          background: #d9d9d9;
          position: absolute;
          left: 240px;
          width: 16px;
          height: 16px;
          top: 50%;
          margin-top: -7px;
          border-radius: 50%;
          z-index: 1;

          @media screen and (max-width: 767px) {
            display: none;
          }
        }
      }

      &::after {
        content: "";
        position: absolute;
        left: 185px;
        width: 70px;
        border: 1px dotted #d9d9d9;
        top: 50%;
        z-index: 1;

        @media screen and (max-width: 767px) {
          display: none;
        }
      }

      &::before {
        content: "";
        background: #d9d9d9;
        position: absolute;
        left: 185px;
        width: 16px;
        height: 16px;
        top: 50%;
        margin-top: -7px;
        border-radius: 50%;
        z-index: 1;

        @media screen and (max-width: 767px) {
          display: none;
        }
      }

      &:last-child {
        &::after {
          display: none;
        }

        &::before {
          display: none;
        }

        .tabs__card {
          &:last-child {
            &::before {
              display: none;
            }
          }
        }
      }

      &.current {
        .tabs__card {
          background: $pink;

          &__text {
            color: $white;
          }

          &::before {
            background: $pink;
          }
        }

        &::after {
          border: 1px dotted $pink;
        }

        &::before {
          background: $pink;
        }
      }
    }
  }

  .title {
    &--h2 {
      font-size: 2.5rem;
      color: $red;
      margin-bottom: 3.75rem;

      @media screen and (max-width: 1365px) {
        font-size: 2.25rem !important;
      }

      @media screen and (max-width: 1199px) {
        font-size: 1.875rem !important;
      }

      @media screen and (max-width: 1023px) {
        font-size: 1.5rem !important;
      }

      @media screen and (max-width: 991px) {
        font-size: 1.25rem !important;
        margin-bottom: 2.188rem;
      }
    }

    &--center {
      text-align: center !important;
    }
  }

  .tab-contents {
    .tab-content {
      display: none;

      &.current {
        display: block;
      }

      .form {
        &__group {
          margin-bottom: 15px;

          &--flex {
            @media screen and (max-width: 767px) {
              flex-direction: column;
              gap: 15px;
            }
          }

          &__col {
            @media screen and (max-width: 767px) {
              width: 100%;
            }
          }
        }

        &__card {
          margin-bottom: 3.75rem;

          @media screen and (max-width: 991px) {
            margin-bottom: 2.188rem;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }

        &__header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 20px;
          margin-bottom: 3.75rem;

          @media screen and (max-width: 991px) {
            margin-bottom: 2.188rem;
          }

          @media screen and (max-width: 639px) {
            flex-direction: column;
            align-items: flex-start;
          }

          &--justifynone {
            justify-content: inherit;
          }

          .title {
            background: none;
            font-size: 2.625rem;
            font-weight: 700;
            color: $pink;
            margin-bottom: 0;

            @media screen and (max-width: 1365px) {
              font-size: 2.25rem;
            }

            @media screen and (max-width: 1199px) {
              font-size: 1.875rem;
            }

            @media screen and (max-width: 1023px) {
              font-size: 1.5rem;
            }
          }

          .btn {
            font-size: 1.25rem;
            font-weight: 700;

            @media screen and (max-width: 1023px) {
              font-size: 0.875rem;
            }
          }
        }

        .profileBox {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 20px;
          margin-bottom: 3.75rem;

          @media screen and (max-width: 991px) {
            margin-bottom: 2.188rem;
          }

          @media screen and (max-width: 767px) {
            flex-direction: column;
            align-items: flex-start;
            gap: 15px;
            margin-bottom: 1.563rem;
          }

          &__info {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 20px;

            @media screen and (max-width: 1023px) {
              gap: 15px;
            }

            .pic {
              flex: 0 0 160px;
              width: 160px;
              height: 160px;
              border: 6px solid $white;
              box-shadow: 6.211818218231201px 6.211818218231201px 10.870681762695312px 0px #384d6c40;
              border-radius: 50%;

              @media screen and (max-width: 1023px) {
                flex: 0 0 100px;
                width: 100px;
                height: 100px;
              }

              @media screen and (max-width: 767px) {
                flex: 0 0 80px;
                width: 80px;
                height: 80px;
              }

              &__img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 50%;
              }
            }

            .detail {
              font-size: 1.625rem;
              font-weight: 400;

              @media screen and (max-width: 1023px) {
                font-size: 1.5rem;
              }

              @media screen and (max-width: 991px) {
                font-size: 1.25rem;
              }

              .name {
                font-size: 1.625rem;
                font-weight: 700;
                margin-bottom: 0;

                @media screen and (max-width: 1023px) {
                  font-size: 1.5rem;
                }

                @media screen and (max-width: 991px) {
                  font-size: 1.25rem;
                }
              }

              .para {
                margin-bottom: 2px;

                &--position {
                  font-size: 1.25rem;

                  @media screen and (max-width: 1023px) {
                    font-size: 1rem;
                  }
                }

                &:last-child {
                  margin-bottom: 0;
                }
              }
            }
          }

          &__actions {
            display: flex;
            align-items: center;
            gap: 30px;

            @media screen and (max-width: 1023px) {
              gap: 10px;
            }

            @media screen and (max-width: 767px) {
              justify-content: flex-start;
            }

            input[type="file"] {
              display: none;
            }

            .btn {
              padding: 1rem;
              font-size: 1.125rem;
              font-weight: 700;
              min-width: 200px;

              @media screen and (max-width: 1023px) {
                font-size: 0.875rem;
                min-width: 110px;
              }

              @media screen and (max-width: 600) {
                min-width: 160px;
              }
            }
          }
        }

        &__group {
          &--right {
            .btn {
              font-size: 1.25rem;
              font-weight: 700;
              min-width: 200px;

              @media screen and (max-width: 1023px) {
                font-size: 0.875rem;
                min-width: 160px;
              }
            }
          }
        }
      }

      .studentEnrolled {
        .title {
          font-size: 3rem;
          font-weight: 700;
          margin-bottom: 2rem;

          @media screen and (max-width: 1023px) {
            font-size: 1.5rem;
          }

          @media screen and (max-width: 991px) {
            font-size: 1.25rem;
          }

          @media screen and (max-width: 767px) {
            margin-bottom: 1.25rem;
          }
        }

        &__list {
          display: flex;
          flex-direction: column;
          gap: 50px;

          &__card {
            background: #fff2f8;
            padding: 1.25rem;
            border-top: 35px solid $pink;
            border-radius: 10px 10px 0 0;

            @media screen and (max-width: 767px) {
              padding: 1.25rem 0.938rem;
              border-top: 20px solid $pink;
            }

            .profileBox {
              display: flex;
              align-items: center;
              justify-content: space-between;
              gap: 20px;
              margin-bottom: 1.5rem;

              @media screen and (max-width: 1023px) {
                margin-bottom: 1rem;
              }

              &__info {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 20px;

                .pic {
                  flex: 0 0 120px;
                  width: 120px;
                  height: 120px;
                  border: 6px solid $white;
                  box-shadow: 6.211818218231201px 6.211818218231201px 10.870681762695312px 0px #384d6c40;
                  border-radius: 50%;

                  @media screen and (max-width: 1023px) {
                    flex: 0 0 80px;
                    width: 80px;
                    height: 80px;
                  }

                  &__img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 50%;
                  }
                }

                .detail {
                  font-size: 1.625rem;
                  font-weight: 400;

                  @media screen and (max-width: 1023px) {
                    font-size: 1.25rem;
                  }

                  .name {
                    font-size: 1.625rem;
                    font-weight: 700;
                    color: $black;
                    margin-bottom: 0;

                    @media screen and (max-width: 1023px) {
                      font-size: 1.25rem;
                    }
                  }

                  .para {
                    margin-bottom: 0;

                    &--position {
                      font-size: 1.25rem;
                      color: rgba($black, 0.62);

                      @media screen and (max-width: 1023px) {
                        font-size: 1rem;
                      }
                    }

                    &:last-child {
                      margin-bottom: 0;
                    }
                  }
                }
              }
            }

            .seInfoCard {
              padding: 0 1.25rem;

              @media screen and (max-width: 991px) {
                padding-left: 0;
                padding-right: 0;
              }

              &__header {
                display: flex;
                align-items: center;
                justify-content: space-around;
                padding: 1rem 2rem;
                font-size: 1.875rem;
                font-weight: 600;

                @media screen and (max-width: 1199px) {
                  font-size: 1.25rem;
                }

                @media screen and (max-width: 767px) {
                  font-size: 1.125rem;
                  display: none;
                }

                &__col {
                  width: 25%;
                  text-align: center;

                  &:first-child {
                    width: 50%;
                    text-align: left;
                  }
                }
              }

              &__body {
                background: $white;
                padding: 1rem;
                border-left: 16px solid $pink;
                border-radius: 10px 0 0 10px;

                .flexbox {
                  display: flex;

                  @media screen and (max-width: 767px) {
                    flex-direction: column;
                    gap: 15px;
                  }

                  &__col {
                    width: 25%;
                    text-align: center;

                    @media screen and (max-width: 767px) {
                      width: 100%;
                      text-align: left;
                    }

                    &:first-child {
                      width: 50%;
                      text-align: left;

                      @media screen and (max-width: 767px) {
                        width: 100%;

                        &:last-child {
                          .btn {
                            width: 100%;
                          }
                        }
                      }
                    }

                    .btn {
                      padding: 0.32rem 1rem 0.32rem;
                      min-width: 110px;

                      @media screen and (max-width: 767px) {
                        width: 100%;
                      }
                    }

                    .dateInfo {
                      font-size: 1.625rem;
                      font-weight: 500;
                      color: $succes;
                      margin-bottom: 0.5rem;

                      @media screen and (max-width: 1023px) {
                        font-size: 1.25rem;
                        line-height: 1.1;
                        margin-bottom: 5px;
                      }
                    }

                    .invoiceInfo {
                      &__btn {
                        display: inline-block;
                        vertical-align: middle;
                        background: #252b42;
                        color: $white;
                        padding: 0.32rem 1rem 0.32rem;
                        margin-bottom: 0.75rem;
                      }
                    }
                  }
                }

                .infobox {
                  display: flex;
                  gap: 15px;

                  .icon {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: $pink;
                    width: 100px;
                    height: 100px;
                    text-align: center;
                    border-radius: 20px;

                    &__img {
                      max-width: 100%;
                    }
                  }

                  &__content {
                    font-size: 1.875rem;
                    font-weight: 400;

                    @media screen and (max-width: 1023px) {
                      font-size: 1.5rem;
                    }

                    @media screen and (max-width: 991px) {
                      font-size: 1rem;
                    }

                    .title {
                      background: none;
                      font-size: 1.875rem;
                      font-weight: 600;
                      margin-bottom: 0;

                      @media screen and (max-width: 1023px) {
                        font-size: 1.5rem;
                      }

                      @media screen and (max-width: 991px) {
                        font-size: 1.25rem;
                      }
                    }

                    .para {
                      color: rgba($black, 0.62);
                      margin-bottom: 1rem;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.cart_price_text {
  font-size: 1.3rem;
  line-height: 1.7rem;
}

.cartWrap {
  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    max-width: 1000px;
    margin: 0 auto;
    margin-bottom: 3rem;

    @media screen and (max-width: 767px) {
      flex-direction: column;
      gap: 10px;
      margin-bottom: 1.5rem;
    }

    .item {
      font-size: 2rem;
      font-weight: 400;
      color: $black;
      margin-bottom: 0;

      @media screen and (max-width: 1165px) {
        font-size: 1.5rem;
      }

      @media screen and (max-width: 767px) {
        font-size: 1.25rem;
      }

      &--outline {
        background: $gray;
        width: 380px;
        height: 1px;

        @media screen and (max-width: 1165px) {
          width: 220px;
        }

        @media screen and (max-width: 767px) {
          width: 100px;
        }
      }

      &--current {
        color: rgba($black, 0.62);
      }
    }
  }

  &__body {
    &__flex {
      display: flex;
      gap: 80px;

      @media screen and (max-width: 1199px) {
        gap: 15px;
      }

      @media screen and (max-width: 991px) {
        flex-direction: column;
        gap: 30px;
      }

      .col {
        width: 100%;

        @media screen and (max-width: 991px) {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }

    .orderPrograms {
      &__list {
        display: flex;
        flex-direction: column;
        gap: 25px;
        padding-bottom: 1.5rem;
        margin-bottom: 1.5rem;
        border-bottom: 1px solid #a0a0a0;
      }

      &__card {
        display: flex;
        gap: 20px;

        @media screen and (max-width: 767px) {
          flex-direction: column;
          gap: 15px;
        }

        .pic {
          flex: 0 0 108px;
          width: 108px;

          @media screen and (max-width: 767px) {
            flex: 0 0 100%;
            width: 100%;

            img {
              max-width: 100%;
              width: 100%;
            }
          }
        }

        .detail {
          width: 100%;

          &__row {
            display: flex;
            justify-content: space-between;
            gap: 20px;
            margin-bottom: 6px;

            .para {
              font-size: 1.25rem;
              font-weight: 500;
              color: $black;

              @media screen and (max-width: 1199px) {
                font-size: 1.125rem;
                gap: 200px;
              }
            }

            .para-data {
              font-weight: 400;
              font-size: 1Prem;

              @media screen and (max-width: 1199px) {
                font-size: 1.1rem;
              }
            }

            .totalValue {
              font-size: 1.375rem;
              font-weight: 500;
              color: $black;
            }

            .btn {
              background: none;
              padding: 0;
              border: none;
              flex: 0 0 28px;
              width: 28px;
              min-width: 28px;
              height: 30px;
            }

            &:nth-child(2n + 2) {
              align-items: center;

              .para {
                font-size: 1.125rem;
                font-weight: 500;
                color: $black;

                @media screen and (max-width: 1199px) {
                  font-size: 1rem;
                }
              }
            }
          }
        }
      }

      &__info {
        &__row {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 10px;
          margin-bottom: 1.5rem;

          &:last-child {
            margin-bottom: 0;
          }
        }

        &__text {
          font-size: 1.5rem;
          font-weight: 400;
          color: $black;

          @media screen and (max-width: 991px) {
            font-size: 1.25rem;
          }
        }

        &__value {
          font-size: 1.5rem;
          font-weight: 400;
          color: $black;

          @media screen and (max-width: 991px) {
            font-size: 1.5rem;
          }
        }

        &__action {
          .btn {
            display: block;
            color: $black;
            width: 100%;
            max-width: 300px;
            text-transform: uppercase;
            margin: 0 auto;
            border-radius: 0.5rem;

            &:hover {
              color: $white;
            }
          }
        }
      }
    }

    .orderSummry {
      background: #f4f4f4;
      padding: 2rem 1.25rem 3rem;

      @media screen and (max-width: 991px) {
        padding: 1.25rem 0.938rem;
      }

      .title {
        font-size: 1.375rem;
        font-weight: 400;
        margin-bottom: 1rem;
      }

      &__detail {
        padding-bottom: 1rem;
        margin-bottom: 1rem;
        border-bottom: 1px solid #a0a0a0;
      }

      &__row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        margin-bottom: 1rem;

        &:last-child {
          margin-bottom: 0;
        }

        .btn {
          display: block;
          width: 100%;
          text-transform: uppercase;
          border-radius: 0.5rem;
        }
      }

      .text {
        font-size: 1rem;
        font-weight: 400;
        // color: #282828;
        color: #5e5f5f;
      }

      .value {
        font-size: 1rem;
        font-weight: 500;
        color: #5e5f5f;
      }

      &__footer {
        .text {
          font-size: 1rem;
          font-weight: 500;
          // color: #282828;
          color: #5e5f5f;
        }

        .value {
          font-size: 2rem;
          font-weight: 500;
          color: #5e5f5f;

          @media screen and (max-width: 991px) {
            font-size: 1.5rem;
          }
        }
      }
    }

    .checkoutWrap {
      display: flex;
      background: $white;
      box-shadow: 0px 2px 58px 0px #b4b4b440;

      .col {
        width: calc(100% / 2);
        padding: 0;
      }

      .orderPrograms {
        &__list {
          display: flex;
          flex-direction: column;
          gap: 25px;
          padding-bottom: 1.5rem;
          margin-bottom: 1.5rem;
          border-bottom: 1px solid #a0a0a0;
        }

        &__card {
          display: flex;
          gap: 20px;

          .pic {
            flex: 0 0 80px;
            width: 80px;
          }

          .detail {
            &__row {
              display: flex;
              justify-content: space-between;
              gap: 20px;
              margin-bottom: 8px;

              .para {
                font-size: 1.25rem;
                font-weight: 600;
                color: $black;
              }

              .totalValue {
                font-size: 1.25rem;
                font-weight: 600;
                color: $black;
              }

              &:nth-child(2n + 2) {
                align-items: center;

                .para {
                  font-size: 1.125rem;
                  font-weight: 500;
                  color: $black;
                }

                .totalValue {
                  font-size: 1.25rem;
                  font-weight: 500;
                  color: rgba($black, 0.62);
                }
              }
            }
          }
        }
      }

      .orderSummry {
        background: #f9fafa;
        padding: 4rem 1rem 3rem;
        border: 1px solid #d9d9d9;

        &__header {
          padding-bottom: 1rem;
          margin-bottom: 2rem;
          border-bottom: 1px solid #acacac;

          .title {
            font-size: 1.625rem;
            font-weight: 600;
            margin-bottom: 0;
          }
        }

        &__detail {
          padding-bottom: 1rem;
          margin-bottom: 1rem;
          border-bottom: 1px solid #acacac;
        }

        &__row {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 10px;
          margin-bottom: 1rem;

          &:last-child {
            margin-bottom: 0;
          }
        }

        .text {
          font-size: 1.125rem;
          font-weight: 500;
          color: $black;
        }

        .value {
          font-size: 1.125rem;
          font-weight: 500;
          color: $black;
        }

        &__footer {
          .text {
            font-size: 1.125rem;
            font-weight: 500;
            color: #282828;

            &__taxesvalue {
              display: block;
              font-size: 1rem;
              font-weight: 400;
            }
          }

          .value {
            font-size: 2.25rem;
            font-weight: 500;
            color: #5e5f5f;
          }
        }

        &__actions {
          padding-top: 1rem;

          .btn {
            display: block;
            width: 100%;
            border-radius: 0.5rem;
            margin-bottom: 1rem;
          }

          .para {
            font-size: 0.938rem;
            color: rgba($black, 0.62);
            margin-bottom: 0;
          }
        }
      }

      .paymentWrap {
        padding: 1.5rem 2rem;

        &__info {
          padding: 2rem 1.25rem;
          border: 1px solid $pink;
          border-radius: 0.625rem;
          margin-bottom: 2rem;

          .title {
            font-size: 2rem;
            font-weight: 400;
            color: $black;
            margin-bottom: 2rem;
          }

          &__list {
            display: flex;
            flex-direction: column;
            gap: 1rem;

            li {
              font-size: 1.375rem;
              font-weight: 400;
              color: $black;
              padding-left: 2rem;
              position: relative;

              &::after {
                content: "";
                background: url(../../images/expand-arrow.png);
                background-repeat: no-repeat;
                background-position: center center;
                background-size: 100% auto;
                width: 12px;
                height: 19px;
                position: absolute;
                left: 0;
                top: 5px;
                z-index: 1;
              }
            }
          }
        }

        &__cards {
          background: #f4f4f4;
          padding: 3rem 1.25rem;
          border-radius: 0.625rem;

          .title {
            font-size: 1.375rem;
            font-weight: 400;
            color: $black;
            margin-bottom: 2rem;
          }

          &__list {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 10px;
            margin-bottom: 0;

            li {
              margin-bottom: 0;

              img {
                display: inline-block;
                vertical-align: middle;
                max-width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

.faqsData {
  @media only screen and (max-width: 375px) {
    padding: 0px;
  }

  .faqs {
    min-height: 200px;
  }

  .btnWrap {
    text-align: right;

    .btn {
      background: $pink;
      color: $white;
      border: 1px solid $pink;
      font-weight: 800;
      border-radius: 0;
      min-width: 160px;
      transform: skew(15deg);
    }
  }
}

.party_det,
.birthdayActivity__list {
  @media screen and (max-width: 450px) {
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: left;
    padding: 0px 20px;
    flex-direction: column;
  }
}

.dj_flex {
  @media screen and (max-width: 450px) {
    margin-top: 0px !important;
  }
}

.dj_flex {
  .column {
    @media screen and (max-width: 450px) {
      width: 80px !important;
    }
  }
}

.birthdaySec {
  padding: 15px 0;

  @media screen and (max-width: 767px) {
    padding: 30px 0;
  }

  .title {
    font-size: 2.4rem;
    margin-bottom: 20px;
    text-align: left;

    @media screen and (max-width: 1365px) {
      font-size: 2.25rem;
    }

    @media screen and (max-width: 1023px) {
      font-size: 1.875rem;
    }

    @media screen and (max-width: 767px) {
      font-size: 1.5rem;
      margin-bottom: 15px !important;
      padding: 0px 0px;
    }
  }

  .birthdayCalender {
    margin-bottom: 80px;

    @media screen and (max-width: 991px) {
      margin-bottom: 45px;
    }

    &__row {
      display: flex;
      gap: 60px;

      @media screen and (max-width: 1199px) {
        gap: 30px;
      }

      @media screen and (max-width: 991px) {
        flex-direction: column;
        margin-bottom: 20px;
      }

      &__item {
        width: 50%;

        @media screen and (max-width: 991px) {
          width: 100%;
        }
      }
    }

    &__calenderCard {
      background: $orange;
      padding: 2rem;
      clip-path: polygon(0 1%, 98% 0, 100% 100%, 3% 99%);
      min-height: 450px;

      @media screen and (max-width: 991px) {
        min-height: 1px;
      }
    }

    &__calenderTimeCard {
      background: $pink;
      padding: 2rem 3rem 2rem 2rem;
      clip-path: polygon(-90% 6%, 96% 1%, 100% 100%, 0 100%);
      min-height: 450px;

      @media screen and (max-width: 991px) {
        min-height: 1px;
      }
    }

    .birthdayPartyTimes {
      background: $white;
      padding: 1.5rem;
      // border-radius: 0.5rem;
      box-shadow: 0px 6.354854583740234px 47.661407470703125px 0px #00000040;
      min-height: 385px;

      @media screen and (max-width: 991px) {
        min-height: 1px;
        padding: 1.25rem 0.938rem;
      }

      .title {
        font-size: 2.5rem;
        font-weight: 800;
        margin-bottom: 1.5rem;

        @media screen and (max-width: 1439px) {
          font-size: 2.25rem;
        }

        @media screen and (max-width: 1365px) {
          font-size: 1.875rem;
        }

        @media screen and (max-width: 1199px) {
          font-size: 1.675rem;
        }

        @media screen and (max-width: 991px) {
          font-size: 1.5rem;
          margin-bottom: 1.25rem;
        }

        @media screen and (max-width: 767px) {
          font-size: 1.25rem;
        }
      }

      .react-calendar {
        width: 100%;
      }

      .form {
        &__group {
          margin-bottom: 0;
        }

        &__checkbox {
          align-items: flex-start;
          flex-direction: column;
          margin-bottom: 0;

          &__option {
            margin-right: 0;
            margin-bottom: 1rem;

            &:last-child {
              margin-bottom: 0;
            }
          }

          &__label {
            display: flex;
            align-items: center;
            font-size: 2.25rem;
            color: $black;
            line-height: 1.1;
            padding-left: 55px;

            @media screen and (max-width: 1365px) {
              font-size: 1.875rem;
              line-height: 38px;
            }

            @media screen and (max-width: 1023px) {
              font-size: 1.5rem;
            }

            @media screen and (max-width: 991px) {
              font-size: 1.25rem;
            }

            cursor: pointer;

            &::before {
              content: "";
              position: absolute;
              width: 38px;
              height: 38px;
              left: 0;
              top: 0;
              border: 1px solid $blackColor;
              border-radius: 6px;
            }
          }

          &__input {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            opacity: 0;
            z-index: 1;
            cursor: pointer;
            margin: 0;

            &:checked {
              +.form__checkbox__label {
                &::before {
                  background: $pink;
                  border-color: $pink;
                }

                &::after {
                  content: "";
                  background: url(../../images/check-icon.svg) no-repeat;
                  background-position: center center;
                  background-size: 24px;
                  display: inline-block;
                  position: absolute;
                  width: 24px;
                  height: 24px;
                  left: 10px;
                  top: 7px;
                  border-radius: 50px;
                }
              }
            }
          }
        }
      }
    }
  }

  // DJ SECTIon

  .input_div {
    @media only screen and (max-width: 480px) {
      display: flex !important;
      flex-direction: row !important;
      width: 168px;
      gap: 12px;
    }
  }

  .dj_content_div {
    width: 100%;

    @media only screen and (max-width: 480px) {
      width: 120px;
      font-size: 10px !important;
      margin-top: 0px;
    }
  }

  .dj_content {
    color: white;
    width: 400px;
    font-size: 1.2rem;
    text-align: left;

    @media only screen and (max-width: 480px) {
      font-size: 10px !important;
      margin-top: 20px;
    }
  }

  .djSection {
    padding: 69px;
    background: url(../../images/birthday-icons/dj-bg.png) no-repeat center center;
    background-size: 100%;
    width: 100%;
    height: 104px;
    margin-bottom: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    // padding: 15px 30px 0px 82px;
    // padding: 0px 8px 0px 96px;
    //

    @media screen and (max-width: 991px) {
      margin-bottom: 40px;
    }

    @media only screen and (max-width: 480px) {
      padding: 30px;
      height: 60px;
    }

    @media screen and (width: 2560px) and (height: 1600px) {
      padding: 15px 30px 0px 82px;
    }

    .dj-title {
      font-size: 1.4rem;
      width: 100%;

      @media only screen and (max-width: 630px) {
        width: 100%;
        font-size: 13px !important;
      }

      @media only screen and (max-width: 480px) {
        width: 100%;
        font-size: 1rem !important;
        margin: 0px 15px;
      }

      @media only screen and (max-width: 390px) {
        width: 100%;
        font-size: 1rem !important;
        margin: 0px 0px;
      }
    }

    .music_dj_birthday_Page {
      @media screen and (width: 2560px) and (height: 1600px) {
        font-size: 1.3rem !important;
      }
    }

    .third-sec input {
      @media screen and (width: 2560px) and (height: 1600px) {
        width: 45px;
      }
    }
  }

  .djSection {
    @media screen and (width: 2560px) and (height: 1600px) {
      gap: 35px;
    }
  }

  .dj-title {
    @media screen and (width: 2560px) and (height: 1600px) {
      font-size: 1.6rem;
    }
  }

  .left h2,
  .right h2 {
    margin: 0px;
  }

  .left,
  .right {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media only screen and (max-width: 480px) {
      padding: 0px;
    }

    // max-width: ;
  }

  .left h2 {
    text-align: center;
  }

  .djBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 3px;
  }

  .third-sec input {
    padding: 5px;
    width: 30px;
    height: 55px;
  }

  .djBox p {
    width: 100%;
  }

  .left img {
    height: 45px;

    @media screen and (max-width: 500px) {
      margin-bottom: 10px;
      height: 30px;
      padding: 6px;
      float: left;

      @media only screen and (max-width: 480px) {
        padding: 0px;
      }
    }
  }

  .left h2 {
    font-size: 1.6rem;

    width: 199px;
  }

  .second h2 {
    margin: 0px;
  }

  .third-sec {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 13px;
    color: white;
    font-size: 1.8rem;
    line-height: 55px;
    font-weight: 600;

    @media screen and (max-width: 500px) {
      margin-left: -24px !important;
    }

    p {
      width: max-content;
    }
  }

  // PACKAGE---

  .birthdayPackages {
    margin-bottom: 80px;
    flex-direction: column;
    display: flex;
    align-items: left;
    gap: 15px;

    padding: 0px 35px;

    @media screen and (max-width: 991px) {
      margin-bottom: 40px;
      padding: 0px 20px;
    }

    @media screen and (max-width: 400px) {
      margin-bottom: 40px;
      padding: 0px 35px;
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -10px;

      &__col {
        width: calc(100% / 3);
        padding: 10px;

        @media screen and (max-width: 991px) {
          width: calc(100% / 2);
        }

        @media screen and (max-width: 767px) {
          width: calc(100% / 1);
        }

        .packageCard {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          background: $pink;
          color: $white;
          padding: 2.5rem 2rem;
          clip-path: polygon(0 0, 93% 0, 100% 100%, 0% 100%);
          height: 100%;

          &__title {
            font-size: 3rem;
            font-weight: 800;
            text-align: center;
            margin-bottom: 30px;

            @media screen and (max-width: 1439px) {
              font-size: 2.25rem;
            }

            @media screen and (max-width: 1365px) {
              font-size: 1.875rem;
            }

            @media screen and (max-width: 991px) {
              font-size: 1.5rem;
              margin-bottom: 10px;
            }
          }

          .shortText {
            font-size: 1.2rem;
            font-weight: 500;
            margin-bottom: 3rem;
            min-height: 510px;

            @media screen and (max-width: 1365px) {
              font-size: 1.25rem;
              line-height: 28px;
            }

            @media screen and (max-width: 1199px) {
              font-size: 1rem;
              min-height: 420px;
              margin-bottom: 20px;
            }

            @media screen and (max-width: 991px) {
              font-size: 0.938rem;
            }

            @media screen and (max-width: 767px) {
              min-height: 1px;
              line-height: 24px;
            }

            .para {
              margin-bottom: 1.5rem;

              @media screen and (max-width: 767px) {
                margin-bottom: 1.25rem;
              }

              &:last-child {
                margin-bottom: 0;
              }
            }
          }

          &__price {
            margin-top: auto;

            .form {
              &__checkbox {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                margin-bottom: $field-margin-bottom;
                top: 10px;

                &__option {
                  display: flex;
                  align-items: flex-start;
                  position: relative;
                  margin-right: 0;
                  margin-bottom: 0;
                }

                &__label {
                  position: relative;
                  font-size: 3.75rem;
                  color: $white;
                  padding-left: 75px;
                  cursor: pointer;
                  top: -9px;

                  @media screen and (max-width: 767px) {
                    padding-left: 55px;
                  }

                  &::before {
                    content: "";
                    background: $white;
                    position: absolute;
                    width: 55px;
                    height: 55px;
                    left: 0;
                    top: 0;
                    border: 1px solid $white;
                    border-radius: 6px;

                    @media screen and (max-width: 767px) {
                      width: 40px;
                      height: 40px;
                    }
                  }
                }

                &__labelText {
                  font-size: 3rem;
                  color: $white;
                  font-weight: 700;
                  line-height: 1.1;

                  @media screen and (max-width: 1439px) {
                    font-size: 2.5rem;
                    line-height: 55px;
                  }

                  @media screen and (max-width: 1165px) {
                    font-size: 2rem;
                    line-height: 55px;
                  }

                  @media screen and (max-width: 767px) {
                    font-size: 1.5rem;
                    line-height: 38px;
                  }
                }

                &__input {
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  left: 0;
                  top: 0;
                  opacity: 0;
                  z-index: 1;
                  cursor: pointer;
                  margin: 0;

                  &:checked {
                    +.form__checkbox__label {
                      &::before {
                        background: $green;
                        border-color: $green;
                      }

                      &::after {
                        content: "";
                        background: url(../../images/check-icon.svg) no-repeat;
                        background-position: center center;
                        background-size: 24px;
                        display: inline-block;
                        position: absolute;
                        width: 24px;
                        height: 24px;
                        left: 16px;
                        top: 15px;
                        border-radius: 50px;

                        @media screen and (max-width: 767px) {
                          left: 8px;
                          top: 8px;
                        }
                      }
                    }
                  }

                  &--withoutlabel {
                    width: 55px;
                    height: 55px;

                    @media screen and (max-width: 767px) {
                      width: 44px;
                      height: 44px;
                    }
                  }
                }
              }
            }
          }
        }

        &:nth-child(2) {
          .packageCard {
            background: $secondary;
            clip-path: polygon(0 2%, 100% 0, 100% 100%, 4% 98%);
          }
        }

        &:nth-child(3) {
          .packageCard {
            background: $purple;
            clip-path: polygon(3% 0, 94% 0, 100% 97%, 0 100%);
          }
        }
      }
    }
  }

  .party_det {
    @media screen and (max-width: 435px) {
      padding: 0px 35px;
    }
  }

  // ACTIVIYT
  .birthdayActivity {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 0px 35px;

    @media screen and (max-width: 767px) {
      margin-bottom: 40px;
      padding: 0px 0px;
    }

    @media screen and (max-width: 390px) {
      padding: 0px 0px;
    }

    @media screen and (max-width: 393px) {
      padding: 0px 0px;
    }

    &__list {
      display: flex;
      margin: 0 -30px;
      padding-bottom: 60px;

      @media screen and (max-width: 1023px) {
        margin: 0 -15px;
      }

      @media screen and (max-width: 767px) {
        flex-direction: column;
        margin-bottom: 0;
        padding-bottom: 25px;
      }

      &__col {
        width: calc(100% / 4);
        padding: 15px;

        @media screen and (max-width: 1023px) {
          padding: 35px 25px;
          width: calc(100% / 2) !important;
        }

        @media screen and (max-width: 767px) {
          width: 100% !important;
        }
      }
    }

    &__card {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      align-items: flex-start;

      .tittleBox {
        display: flex;
        align-items: center;
        gap: 15px;
        margin-bottom: 1.2rem;
        justify-content: center;

        @media screen and (max-width: 767px) {
          margin-bottom: 10px;
        }

        @media screen and (max-width: 430px) {
          justify-content: flex-start;
          gap: 25px !important;
        }

        @media screen and (max-width: 393px) {
          justify-content: flex-start;
          gap: 30px !important;
        }

        &--Cake {
          font-size: 12px;
        }

        .icon {
          display: flex;
          align-items: center;
          height: 100px;
          width: 100px;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }

          &--music {
            img {
              height: 260px !important;

              @media screen and (max-width: 1365px) {
                height: 98px !important;
              }

              @media screen and (max-width: 1023px) {
                height: 80px !important;
              }

              @media screen and (max-width: 767px) {
                height: 60px !important;
              }
            }
          }

          // &--cake {
          //   img {
          //     height: 260px !important;
          //     width: auto;

          //     @media screen and (max-width: 1365px) {
          //       height: 90px !important;
          //     }

          //     @media screen and (max-width: 1023px) {
          //       height: 80px !important;
          //     }

          //     @media screen and (max-width: 767px) {
          //       height: 60px !important;
          //     }
          //   }
          // }

          @media screen and (max-width: 1365px) {
            height: 105px;
          }

          @media screen and (max-width: 1023px) {
            height: 90px;
          }

          @media screen and (max-width: 767px) {
            height: auto;
            justify-content: flex-start;
          }

          img {
            max-width: 109%;
            height: 100%;

            @media screen and (max-width: 1365px) {
              height: 100%;
              width: 315px;
            }

            @media screen and (max-width: 1023px) {
              height: 80px;
              width: 315px;
            }

            @media screen and (max-width: 767px) {
              height: 60px;
              width: 315px;
            }

            @media screen and (max-width: 450px) {
              height: 100px;
              width: 275px;
              margin-bottom: 20px;
            }

            @media screen and (max-width: 400px) {
              height: 100px;
              width: 275px;
              margin-bottom: 0px;
            }
          }
        }

        .title {
          font-size: 1.275rem !important;
          font-weight: 800;
          color: $secondary;
          margin-left: -8px;
          margin-bottom: 0;

          &--art {
            margin-left: -20px !important;

            @media screen and (max-width: 1365px) {
              margin-left: -16px !important;
              font-size: 1.275rem;
            }
          }

          &--cake {
            width: 100%;

            @media screen and (max-width: 1365px) {
              margin-left: -10px !important;
              font-size: 1.275rem !important;
            }
          }

          &--cooking {
            margin-left: -20px !important;

            @media screen and (max-width: 1365px) {
              margin-left: -10px !important;
              font-size: 1.275rem;
              font-weight: 800;
            }
          }

          &--music {
            margin-left: -61px !important;

            @media screen and (max-width: 1365px) {
              margin-left: -16px !important;
              font-size: 1.275rem !important;
            }
          }

          @media screen and (max-width: 1365px) {
            font-size: 1.875rem;
            margin-left: 0px;
          }

          @media screen and (max-width: 991px) {
            font-size: 1.5rem;
            margin-left: 0px;
          }

          @media screen and (max-width: 767px) {
            text-align: center;
            margin-left: 0px;
            margin-bottom: 0 !important;
          }
        }
      }

      // .shortText {
      //   font-size: 1.2rem;
      //   font-weight: 500;
      //   word-spacing: 1px;
      //   line-height: 26px;

      //   @media screen and (max-width: 1365px) {
      //     font-size: 1.25rem;
      //     line-height: 28px;
      //   }

      //   @media screen and (max-width: 1199px) {
      //     font-size: 1rem;
      //   }

      //   @media screen and (max-width: 991px) {
      //     font-size: 0.938rem;
      //   }

      //   .para {
      //     margin-bottom: 1rem;
      //     line-height: 1.3rem;
      //     font-weight: 500 ;

      //     &__askmask {
      //       color: #ff0000;
      //     }

      //     &:last-child {
      //       font-weight: 400;
      //       margin-bottom: 0;
      //     }
      //   }
      // }

      .para {
        font-weight: 500;
        word-spacing: 1px;
        line-height: 25px;
        font-weight: 500;
        font-size: 1.1rem;
      }

      .form {
        .shortText {
          word-spacing: 1px;
          line-height: 26px;
          font-weight: 500;

          @media screen and (max-width: 1365px) {
            font-size: 1.25rem;
            line-height: 28px;
          }

          @media screen and (max-width: 1199px) {
            font-size: 1rem;
          }

          @media screen and (max-width: 991px) {
            font-size: 0.938rem;
          }

          .para {
            margin-bottom: 1rem;
            line-height: 1.3rem;
            font-weight: 500 !important;

            &__askmask {
              color: #ff0000;
            }

            &:last-child {
              font-weight: 400;
              margin-bottom: 0;
            }
          }
        }

        &__group {
          margin-top: auto;
          margin-bottom: 0;
          height: 100px;
        }

        &__checkbox {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          margin-bottom: 0;
          padding-top: 0.5rem;

          &__option {
            display: flex;
            align-items: flex-start;
            position: relative;
            margin-right: 0;
            margin-bottom: 0;
          }

          &__label {
            position: relative;
            font-size: 2.813rem;
            color: $black;
            padding-left: 75px;
            cursor: pointer;

            @media screen and (max-width: 767px) {
              padding-left: 55px;
            }

            &::before {
              content: "";
              position: absolute;
              width: 55px;
              height: 55px;
              left: 0;
              top: 0;
              border: 1px solid $blackColor;
              border-radius: 6px;

              @media screen and (max-width: 767px) {
                width: 40px;
                height: 40px;
              }
            }
          }

          &__labelText {
            font-size: 2.813rem;
            color: $black;
            font-weight: 700;
            line-height: 1.1;

            @media screen and (max-width: 1439px) {
              font-size: 2.5rem;
              line-height: 55px;
            }

            @media screen and (max-width: 1165px) {
              font-size: 2rem;
              line-height: 55px;
            }

            @media screen and (max-width: 767px) {
              font-size: 1.5rem;
              line-height: 38px;
            }
          }

          &__input {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            opacity: 0;
            z-index: 1;
            cursor: pointer;
            margin: 0;

            &:checked {
              +.form__checkbox__label {
                &::before {
                  background: $pink;
                  border-color: $pink;
                }

                &::after {
                  content: "";
                  background: url(../../images/check-icon.svg) no-repeat;
                  background-position: center center;
                  background-size: 24px 24px;
                  display: inline-block;
                  position: absolute;
                  width: 24px;
                  height: 24px;
                  left: 17px;
                  top: 16px;
                  border-radius: 50px;

                  @media screen and (max-width: 767px) {
                    left: 9px;
                    top: 8px;
                  }
                }
              }
            }

            &--withoutlabel {
              width: 55px;
              height: 55px;

              @media screen and (max-width: 767px) {
                width: 40px;
                height: 40px;
              }
            }
          }
        }
      }
    }

    &__totalprice {
      background: $white;
      padding: 1rem 1rem 0.8rem;
      border: 1px solid $gray;
      border-left: 8px solid #c3d940;
      border-right: 8px solid #c3d940;
      box-shadow: 0px 0px 9px 0px #0000004d;
      border-radius: 12px;

      &__text {
        display: flex;
        align-items: center;
        gap: 80px;
        font-size: 3rem;
        font-weight: 800;
        color: $black;

        @media screen and (max-width: 1365px) {
          font-size: 1.875rem;
        }

        @media screen and (max-width: 991px) {
          font-size: 1.5rem;
          gap: 30px;
        }

        @media screen and (max-width: 767px) {
          font-size: 1.125rem;
          gap: 15px;
        }

        span {
          display: inline-block;
          font-size: 2.5rem;
          font-weight: 600;
          line-height: 1.1;

          @media screen and (max-width: 1365px) {
            font-size: 1.875rem;
          }

          @media screen and (max-width: 991px) {
            font-size: 1.5rem;
          }

          @media screen and (max-width: 767px) {
            font-size: 1.25rem;
          }

          span {
            display: inline-block;
            font-weight: 400;
          }
        }
      }
    }
  }

  .birthdaypartyDetail {
    .title {
      margin-bottom: 1.5rem;
    }

    .shortText {
      font-size: 1.2rem;
      line-height: 28px;
      font-weight: 500;
      margin-bottom: 1rem;
      word-spacing: 3px;

      @media screen and (max-width: 1365px) {
        font-size: 1.25rem;
        line-height: 28px;
      }

      @media screen and (max-width: 1199px) {
        font-size: 1rem;
        word-spacing: 1px;
        line-height: 20px;
      }

      @media screen and (max-width: 991px) {
        font-size: 0.938rem;
      }

      .para {
        margin-bottom: 1rem;

        &__askmask {
          color: #ff0000;
        }

        &:last-child {
          font-weight: 400;
          margin-bottom: 0;
        }
      }
    }

    &__cardBox {
      background: $green;
      padding: 2.5rem 3rem;
      clip-path: polygon(3% 0, 97% 3%, 100% 97%, 0 100%);
      margin-top: 80px;

      @media screen and (max-width: 1199px) {
        margin-top: 50px;
      }

      @media screen and (max-width: 991px) {
        margin-top: 30px;
      }

      @media screen and (max-width: 767px) {
        padding: 1.875rem 1.25rem;
      }
    }

    &__whiteCard {
      background: $white;
      padding: 2.5rem 2rem;

      @media screen and (max-width: 991px) {
        padding: 1.563rem;
      }

      .birthdayDetail {
        display: flex;
        align-items: center;
        gap: 60px;

        @media screen and (max-width: 991px) {
          gap: 20px;
        }

        @media screen and (max-width: 767px) {
          flex-direction: column;
          align-items: flex-start;
        }

        &__item {
          width: 40%;

          @media screen and (max-width: 767px) {
            width: 100%;
          }

          &:first-child {
            width: 60%;

            @media screen and (max-width: 767px) {
              width: 100%;
            }
          }
        }

        .title {
          font-size: 3rem;

          @media screen and (max-width: 1439px) {
            font-size: 2.25rem;
            margin-bottom: 20px;
          }

          @media screen and (max-width: 1365px) {
            font-size: 1.875rem;
          }

          @media screen and (max-width: 991px) {
            font-size: 1.5rem;
            margin-bottom: 15px;
          }
        }

        .para {
          font-size: 1.875rem;
          font-weight: 600;
          margin-bottom: 2rem;

          @media screen and (max-width: 991px) {
            font-size: 1.5rem;
            margin-bottom: 15px;
          }

          @media screen and (max-width: 767px) {
            font-size: 1rem;
            margin-bottom: 15px;
          }

          span {
            display: block;
          }
        }

        .planName {
          font-size: 3rem;
          font-weight: 700;
          margin-bottom: 0;
          line-height: 1.1;

          @media screen and (max-width: 1439px) {
            font-size: 2.25rem;
          }

          @media screen and (max-width: 1365px) {
            font-size: 1.875rem;
          }

          @media screen and (max-width: 991px) {
            font-size: 1.5rem;
          }

          @media screen and (max-width: 767px) {
            font-size: 1.25rem;
          }

          span {
            display: block;
          }
        }

        &__bookNow {
          background: $secondary;
          padding: 3.5rem 2rem;
          max-width: 375px;
          color: $white;
          clip-path: polygon(1% 0, 97% 2%, 100% 97%, 0 100%);
          text-align: center;
          margin: 0 auto;

          &__title {
            font-size: 3rem;
            font-weight: 800;
            margin-bottom: 0.25rem;

            @media screen and (max-width: 1439px) {
              font-size: 2.25rem;
            }

            @media screen and (max-width: 1365px) {
              font-size: 1.875rem;
            }

            @media screen and (max-width: 991px) {
              font-size: 1.5rem;
            }
          }

          &__price {
            font-size: 3.5rem;
            font-weight: 700;
            margin-bottom: 0.25rem;

            @media screen and (max-width: 1439px) {
              font-size: 2.25rem;
            }

            @media screen and (max-width: 1365px) {
              font-size: 1.875rem;
            }

            @media screen and (max-width: 991px) {
              font-size: 1.5rem;
            }
          }

          &__deposit {
            font-size: 3rem;
            font-weight: 800;
            margin-bottom: 0;

            @media screen and (max-width: 1439px) {
              font-size: 2.25rem;
            }

            @media screen and (max-width: 1365px) {
              font-size: 1.875rem;
            }

            @media screen and (max-width: 991px) {
              font-size: 1.5rem;
            }
          }
        }
      }
    }
  }
}

.dj_mid_content {
  @media only screen and (max-width: 430px) and (max-width: 450px) {
    display: none;
  }
}

.dj-container {
  display: flex;
  align-items: center;

  @media only screen and (max-width: 480px) {
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 227px;
  }

  @media only screen and (max-width: 390px) {
    padding: 0px 17px;
  }

  @media only screen and (max-width: 430px) and (max-width: 450px) {
    height: 100%;
  }

  .djSection {
    width: 100%;
    padding: 21px 22px 0px 91px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 100px;

    @media only screen and (max-width: 430px) and (max-width: 450px) {
      padding: 0px 25px;
    }

    @media screen and (max-width: 639px) {
      gap: 10px !important;
    }
  }

  .bday_flex {
    @media screen and (max-width: 639px) {
      min-width: 88px !important;
    }
  }

  .dj_div {
    width: 100%;

    @media only screen and (max-width: 630px) {
      gap: 0px;
    }

    @media only screen and (max-width: 430px) and (max-width: 450px) {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;

      p {
        font-size: 1rem !important;
        line-height: 1.5rem !important;
        text-align: left !important;
      }

      h2 {
        width: 176px !important;
        line-height: 1rem;
        font-size: 1rem !important;
      }
    }

    @media screen and (max-width: 630px) {
      margin-top: 9px;
    }

    @media screen and (max-width: 390px) {
      margin-left: 0px;
      margin-top: 9px;
    }

    @media only screen and (max-width: 375px) {
      margin-left: -17px !important;
    }
  }

  .input_div {
    @media only screen and (max-width: 480px) {
      align-items: center;
    }
  }
}

.contentWithSidebar {
  display: flex;
  gap: 40px;

  .content {
    width: calc(100% - 400px);

    @media screen and (max-width: 1199px) {
      width: 100%;
    }

    .blog_newPage_container {
      /* border: 1px solid black; */
      /* background-color: #dde1e1; */
      position: absolute;
      right: -14rem;
      top: 24rem;
      padding: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 15px;

      @media screen and (min-width: 1200px) and (max-width: 1400px) {
        top: 12rem;
        padding: 10px;
        right: -22rem;
      }

      @media screen and (max-width: 1199px) {
        right: -40px;
      }

      @media screen and (max-width: 991px) {
        padding: 15px 0;
        position: relative;
        flex-direction: inherit;
        top: auto;
        left: auto;
        right: auto !important;

        div {
          width: 450px !important;
          height: 300px !important;
        }

        iframe {
          width: 450px !important;
          height: 300px !important;
        }
      }

      @media screen and (max-width: 767px) {
        div {
          width: 300px !important;
          height: 160px !important;
        }

        iframe {
          width: 300px !important;
          height: 160px !important;
        }
      }
    }

    .blog_video_container {
      /* border: 1px solid black; */
      /* background-color: #dde1e1; */
      position: absolute;
      right: -14rem;
      top: 24rem;
      padding: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 15px;

      @media screen and (min-width: 1200px) and (max-width: 1400px) {
        top: 12rem;
        padding: 10px;
        right: -22rem;
      }

      @media screen and (max-width: 1199px) {
        right: -40px;
      }

      @media screen and (max-width: 991px) {
        padding: 15px 0;
        position: relative;
        flex-direction: inherit;
        top: auto;
        left: auto;
        right: 12px;

        div {
          width: 450px !important;
          height: 300px !important;
        }

        iframe {
          width: 450px !important;
          height: 300px !important;
        }
      }

      @media screen and (max-width: 767px) {
        div {
          width: 300px !important;
          height: 160px !important;
        }

        iframe {
          width: 300px !important;
          height: 160px !important;
        }
      }
    }

    .video_Link_Subtitle {
      font-size: 20px;
    }

    .form {
      .bottomInfo {
        margin-bottom: 3rem;
      }

      .commentArea {
        padding: 2rem;

        &__title {
          font-size: 1.25rem;
          font-weight: 500;
          margin-bottom: 1.5rem;
        }
      }

      .btnActions {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 30px;

        .btn {
          display: inline-block;
          vertical-align: middle;
          font-size: 1.5rem;
          font-weight: 600;
          min-width: 220px;
          min-height: 55px;
          line-height: 1.1;
        }
      }
    }
  }

  .sidebar {
    width: 400px;

    @media screen and (max-width: 1199px) {
      width: 100%;
    }

    &__row {
      margin-bottom: 2rem;
    }

    &__search {
      background: #f9fafa;
      padding: 2rem 2rem 2.5rem;

      .form {
        &__group {
          margin-bottom: 0;
        }

        &__label {
          display: block;
          font-size: 1.75rem;
          font-weight: 500;
          color: $black;
        }

        &__search {
          position: relative;

          .btn {
            display: flex;
            align-items: center;
            justify-content: center;
            background: transparent;
            position: absolute;
            height: 53px;
            right: 0;
            top: 0;
            min-width: 1px;
            z-index: 1;
          }
        }

        &__input {
          font-size: 1.375rem;
          font-weight: 400;
          padding-right: 4.063rem;
        }
      }
    }

    &__title {
      font-size: 2.25rem;
      font-weight: 700;
      color: $black;
      margin-bottom: 1rem;

      &--underline {
        padding-bottom: 1rem;
        position: relative;

        &::after {
          content: "";
          background: $pink;
          position: absolute;
          width: 80px;
          height: 3px;
          left: 0;
          bottom: 0;
          z-index: 1;
        }
      }
    }

    .categories {
      &__item {
        display: block;
      }

      &__link {
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-decoration: none;
        background: url(../../images/arrow-pink-icon.svg) no-repeat;
        background-position: center left;
        border-top: 1px solid #d6d6d6;
        padding: 0.5rem 0 0.5rem 1.5rem;
        font-size: 1.313rem;
        font-weight: 400;
        width: 100%;
        color: $black;
        min-height: 70px;

        &__img {
          max-width: 100%;
        }

        &:hover {
          color: $pink;
        }
      }
    }

    .addressInfo {
      padding: 1rem 1.5rem;
      border-top: 2px dashed $black;
      border-bottom: 2px dashed $black;
      margin-bottom: 1rem;

      .sidebar__title {
        font-size: 2rem;
      }

      .para {
        font-size: 1.375rem;
        font-weight: 400;
        color: $black;
        margin-bottom: 0.5rem;

        &:last-child {
          margin-bottom: 0;
        }

        strong {
          display: inline;
          font-size: 1.875rem;
        }

        a {
          display: inline;
          text-decoration: none;
          color: $black;

          &:hover {
            color: $pink;
          }
        }
      }
    }

    .socialLinks {
      display: flex;
      align-items: center;
      gap: 30px;

      &__item {
        margin-top: 10px;
      }
    }

    .blogs {
      display: flex;
      flex-direction: column;
      gap: 30px;
      padding-top: 1.5rem;

      &__item {
        display: block;
      }

      &__link {
        display: flex;
        align-items: center;
        text-decoration: none;
        gap: 15px;
        color: $black;

        .pic {
          flex: 0 0 112px;
          width: 112px;

          img {
            display: block;
            max-width: 100%;
          }
        }

        .detail {
          display: block;

          .title {
            display: block;
            font-size: 1.625rem;
            font-weight: 400;
            line-height: 1.1;
            margin-bottom: 0.5rem;
          }

          .date {
            display: block;
            font-size: 1.125rem;
            font-weight: 400;
          }
        }

        &:hover {
          color: $pink;
        }
      }
    }

    .gallerys {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding-top: 1.5rem;

      &__item {
        margin: 0 -2px;
        width: calc(100% / 3);

        &__link {
          display: block;
          padding: 3px;

          .pic {
            display: block;
            width: 100%;
            max-width: 100%;
          }
        }
      }
    }
  }

  .blogList {
    display: flex;
    flex-direction: column;
    gap: 50px;

    @media screen and (max-width: 767px) {
      gap: 20px;
    }

    &__card {
      .picture {
        width: 90%;

        &__link {
          display: block;
          text-decoration: none;
          cursor: pointer;
          position: relative;
        }

        &__img {
          display: block;
          width: 100%;
          object-fit: cover;
        }

        .tagmark {
          background: $pink;
          padding: 0.62rem 1rem;
          display: inline-block;
          vertical-align: middle;
          font-size: 1.375rem;
          font-weight: 400;
          color: $white;
          text-align: center;
          position: absolute;
          min-width: 140px;
          left: -40px;
          top: -41px;
          z-index: 1;
          border-radius: 50%;

          @media screen and (min-width: 815px) and (max-width: 1550px) {
            font-size: 1.1rem;
            min-width: 107px;
          }

          @media screen and (min-width: 600px) and (max-width: 815px) {
            font-size: 1rem;
            min-width: 90px;
            margin-left: 10px;
          }

          @media screen and (max-width: 769px) {
            font-size: 1rem;
            margin-left: 10px;
            min-width: 100px;
          }

          @media screen and (max-width: 620px) {
            margin-left: 20px;
            min-width: 107px;
          }

          @media screen and (max-width: 570px) {
            margin-left: 27px;
            font-size: 0.65rem;
            min-width: 60px;
            top: -23px;
          }
        }
      }

      .contentBox {
        padding: 2rem;

        @media screen and (max-width: 1023px) {
          padding: 1.875rem 0.938rem;
        }

        @media screen and (max-width: 767px) {
          padding: 1rem 0 1.25rem;
          margin-bottom: 40px;
        }

        .title {
          position: relative;
          font-size: 3rem;
          font-weight: 500;
          padding: 0.5rem 2.5rem 0.5rem;
          margin-bottom: 0.5rem;
          width: 85%;

          @media screen and (max-width: 1365px) {
            font-size: 2.625rem;
          }

          @media screen and (max-width: 1199px) {
            font-size: 2.25rem;
            width: 56%;
          }

          @media screen and (max-width: 1023px) {
            font-size: 1.875rem;
            width: 50%;
          }

          @media screen and (max-width: 1000px) {
            font-size: 1.875rem;
            width: 100%;
          }

          @media screen and (max-width: 767px) {
            padding-left: 25px;
            padding-right: 0;
            font-size: 1.5rem;
          }

          &::after {
            content: "";
            background: $pink;
            position: absolute;
            width: 9px;
            left: 0;
            top: 0;
            bottom: 0;
            z-index: 1;
          }

          &__link {
            display: inline-block;
            vertical-align: middle;
            text-decoration: underline;
            color: $black;
          }
        }

        .infoBox {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-left: 2.5rem;
          margin-bottom: 1rem;
          gap: 15px;

          @media screen and (max-width: 767px) {
            flex-wrap: wrap;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            padding-left: 0;
            gap: 10px;

            .flexBox {
              flex-wrap: wrap;
              flex-direction: column;
            }
          }

          .loading-false {
            width: 200px;
            height: 50px;
            margin: 20px auto;
            position: relative;
            left: 2.5rem;
            bottom: 1.5rem;
          }

          .loading-false span {
            width: 9px;
            display: block;
            position: absolute;
            bottom: 0;
            height: 5px;
            background-color: #9b59b6;
            //  animation: waving 1.5s;
          }

          .loading-false span:nth-of-type(1) {
            left: 11px;
            animation-delay: 0.2s;
          }

          .loading-false span:nth-of-type(2) {
            left: 22px;
            animation-delay: 0.4s;
          }

          .loading-false span:nth-of-type(3) {
            left: 33px;
            animation-delay: 0.6s;
          }

          .loading-false span:nth-of-type(4) {
            left: 44px;
            animation-delay: 0.8s;
          }

          .loading-false span:nth-of-type(5) {
            left: 55px;
            animation-delay: 0.1s;
          }

          .loading-false span:nth-of-type(6) {
            left: 66px;
            animation-delay: 0.12s;
          }

          .loading-false span:nth-of-type(7) {
            left: 77px;
            animation-delay: 0.14s;
          }

          .loading-false span:last-of-type(8) {
            left: 88px;
            animation-delay: 0.16s;
          }

          // @keyframes waving
          //    {
          //      0%, 50%, 100%
          //      {
          //        height: 5px;
          //     //    transform: translatey(0);
          //        background-color: #9b9b9b; }
          //       25%
          //      { height: 50px;
          //     //    transform: translatey(15px);
          //        background-color: #9b59b6
          //      }
          //    }

          .loading {
            width: 200px;
            height: 50px;
            margin: 20px auto;
            position: relative;
            left: 2.5rem;
            bottom: 1.5rem;
          }

          .loading span {
            width: 9px;
            display: block;
            position: absolute;
            bottom: 0;
            height: 5px;
            background-color: #9b59b6;
            animation: waving 1.5s infinite ease-in-out;
          }

          .loading span:nth-of-type(1) {
            left: 11px;
            animation-delay: 0.2s;
          }

          .loading span:nth-of-type(2) {
            left: 22px;
            animation-delay: 0.4s;
          }

          .loading span:nth-of-type(3) {
            left: 33px;
            animation-delay: 0.6s;
          }

          .loading span:nth-of-type(4) {
            left: 44px;
            animation-delay: 0.8s;
          }

          .loading span:nth-of-type(5) {
            left: 55px;
            animation-delay: 0.1s;
          }

          .loading span:nth-of-type(6) {
            left: 66px;
            animation-delay: 0.12s;
          }

          .loading span:nth-of-type(7) {
            left: 77px;
            animation-delay: 0.14s;
          }

          .loading span:last-of-type(8) {
            left: 88px;
            animation-delay: 0.16s;
          }

          @keyframes waving {

            0%,
            100% {
              height: 5px;
              transform: translatey(0);
              background-color: #7f349d;
            }

            25% {
              height: 25px;
              transform: translatey(15px);
              background-color: #ea519c;
            }

            50% {
              height: 60px;
              transform: translatey(15px);
              background-color: #ea519c;
            }

            75% {
              height: 25px;
              transform: translatey(15px);
              background-color: #ea519c;
            }
          }

          &__item {
            .flexbox {
              display: flex;
              align-items: center;
              gap: 80px;
              font-size: 1.25rem;
              font-weight: 400;
              color: $black;

              @media screen and (max-width: 1199px) {
                font-size: 0.875rem;
                gap: 10px;
              }

              @media screen and (max-width: 767px) {
                flex-direction: column;
                align-items: flex-start;
              }

              p {
                display: flex;
                align-items: center;
                margin-bottom: 0;

                @media screen and (max-width: 767px) {
                  flex-direction: column;
                  align-items: flex-start;

                  div {
                    margin-left: 0 !important;
                    margin-top: 10px;
                  }
                }

                .audioImg {
                  display: inline;
                  max-width: 100%;
                }
              }
            }

            .socialShare {
              display: flex;
              align-items: center;
              gap: 15px;

              &__item {
                font-size: 1.375rem;
                font-weight: 400;
              }

              &__link {
                display: block;
                text-decoration: none;
                width: 32px;
                height: 32px;
                cursor: pointer;

                &--facebook {
                  background: url(../../images/facebook-icon.svg) no-repeat;
                  background-size: 100% auto;
                  background-position: center center;
                  background-repeat: no-repeat;
                }

                &--twitter {
                  background: url(../../images/twitter-circle-pink.svg) no-repeat;
                  background-size: 100% auto;
                  background-position: center center;
                  background-repeat: no-repeat;
                }
              }
            }
          }
        }

        .shortText {
          font-size: 1.25rem;
          font-weight: 400;
          color: $black;
          padding-left: 2.5rem;
          margin-bottom: 30px;

          @media screen and (max-width: 1365px) {
            font-size: 1.25rem;
            line-height: 28px;
          }

          @media screen and (max-width: 991px) {
            font-size: 0.938rem;
            margin-bottom: 20px;
          }

          @media screen and (max-width: 767px) {
            padding-left: 0;
          }

          h3,
          h4,
          h5 {
            font-size: 1.563rem;
            font-weight: 500;
            color: $black;
            margin-bottom: 0.25rem;
          }

          h2 {
            font-size: 1.375rem;
            font-weight: 600;
            color: $black;
            margin-bottom: 0.25rem;
          }

          p {
            font-size: 1.3rem;
            line-height: 30px;
            margin-bottom: 1.5rem;

            a {
              display: inline-block;
              text-decoration: underline;
              color: $pink;

              &:hover {
                opacity: 0.8;
              }
            }
          }

          ol {
            padding-left: 20px;
            margin-bottom: 1.5rem;

            li {
              list-style-type: decimal;
              margin-bottom: 0.25rem;
            }
          }

          ul {
            li {
              margin-bottom: 0.25rem;

              a {
                display: inline-block;
                text-decoration: underline;
                font-weight: 500;
                color: $pink;

                &:hover {
                  opacity: 0.8;
                }
              }
            }
          }
        }

        .bottomInfo {
          padding: 0;

          // border: 1px solid $border;
          // border-radius: .5rem;
          &__list {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            text-align: center;
            gap: 0;

            &__item {
              width: calc(100% / 6);

              @media screen and (max-width: 767px) {
                width: calc(100% / 3);
              }
            }

            &__link {
              display: inline-flex;
              align-items: center;
              text-decoration: none;
              gap: 10px;
              font-size: 1.25rem;
              font-weight: 400;
              color: $black;
              -webkit-transition: all 0.3s ease-out;
              transition: all 0.3s ease-out;

              @media screen and (max-width: 767px) {
                font-size: 0.875rem;
              }

              .svg {
                height: 25px;
                display: inline-block;
                vertical-align: middle;
                -webkit-transition: all 0.3s ease-out;
                transition: all 0.3s ease-out;

                path {
                  opacity: 0.5;
                }
              }

              &:hover {
                cursor: pointer;
                color: #129bf7;
                opacity: 1;

                .svg {
                  path {
                    fill: #129bf7 !important;
                    opacity: 1;
                  }
                }
              }

              &--blue {
                color: #129bf7;
                opacity: 1;

                .svg {
                  path {
                    fill: #129bf7 !important;
                    opacity: 1;
                  }
                }
              }

              &--pink {
                color: $pink;
                opacity: 1;

                .svg {
                  path {
                    fill: $pink !important;
                    opacity: 1;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.icon.icon--music img,
.icon.icon--music {
  width: 100%;
  height: 100%;
}

.icon.icon--cake img,
.icon.icon--cake {
  width: 100%;
  height: 100%;
}

.icon.icon--music img,
.icon.icon--music {
  width: 100%;
  height: 100%;
}

._blog_creation_day {
  font-size: 4rem;
  font-weight: 600;

  @media screen and (max-width: 620px) {
    font-size: 2.5rem;
  }

  @media screen and (max-width: 450px) {
    font-size: 1.5rem;
  }
}

._blog_creation_date {
  line-height: 40px;

  @media screen and (max-width: 620px) {
    line-height: 30px;
  }
}

@font-face {
  font-family: myFonts;
  src: url("../../../../../public//SundayGrapes.ttf");
}

.navbar_heading_siteBanner_img {
  position: absolute;
  padding: 0 15px;
  right: 10rem;
  bottom: -5px;

  @media screen and (max-width: 767px) {
    font-size: 1.2rem;
    width: 100%;
    right: auto;
    left: auto;
    text-align: right;
  }
}

.navbar_heading_siteBanner_img .navbar_heading_siteBanner_img_heading {
  font-family: myFonts;
  text-transform: capitalize;
  font-size: 9.5rem;
  font-weight: 300;
  color: #00bbe3;
  transform: rotate(-5deg);
  text-transform: uppercase;

  @media screen and (max-width: 2000px) and (min-width: 1750px) {
    font-size: 9rem;
  }

  @media screen and (max-width: 1750px) and (min-width: 1500px) {
    font-size: 7.5rem;
  }

  @media screen and (max-width: 1500px) and (min-width: 1200px) {
    font-size: 6.5rem;
  }

  @media screen and (max-width: 1200px) and (min-width: 800px) {
    font-size: 5.5rem;
  }

  @media screen and (max-width: 800px) and (min-width: 600px) {
    font-size: 4rem;
  }

  @media screen and (max-width: 600px) {
    font-size: 3.5rem;
  }

  @media screen and (max-width: 767px) {
    font-size: 2rem;
    margin-left: 150px !important;
  }
}

.edit_delete_image_container {
  display: flex;
  gap: 1rem;
}

.carasoul_classname_value {
  color: white;
  font-size: 1.7rem;
  font-weight: 800;
  float: left;
}

.owl-prev,
.owl-next {
  margin-top: -68px !important;
  color: #565555 !important;
  padding: 10px 10px !important;
  border-radius: 50% !important;
  font-size: 5rem !important;
}

// MY CSS ----=>

.cheer-carousel {
  padding: 66px 71px;
}

.galleryGrid__item {
  height: 330px;

  @media screen and (max-width: 425px) {
    height: 130px;
  }
}

// BLUE SECTIOn
.blue-section {
  background: url(../../images/enrichment-images/background-blue.png) center/contain no-repeat;
  padding: 17vh 15vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.blue-section {
  .container {
    background: url(../../images/birthday-icons/white-bg.png);
    padding: 20px;
  }

  .container img {
    width: 100%;
    height: 165px;
  }
}

.blue-section .container {
  max-width: 50%;
  height: auto;
  display: flex;
  justify-content: center;
}

.blue-section img {
  max-width: 70%;
  height: auto;
}

.blue-section h1 {
  font-weight: 400;
  line-height: normal;
}

@media screen and (max-width: 780px) {
  .blue-section h1 {
    font-size: 18px;
  }

  .blue-section p {
    color: red;
  }
}

.blue-section h1,
.blue-section p {
  color: #ffffff;
}

// CAMP VERSION CSS ::

.camp-box {
  padding: 30px 120px 100px 120px;
  display: flex;
  justify-content: center;
}

.image-tag {
  width: 20%;
  height: 40%;
  text-align: right;
}

.image-section {
  background: url(../../images/enrichment-images/orange-bg.png);
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 300px;
}

@media screen and (max-width: 780px) {
  .picture--clipCampVersion {
    display: flex;
    flex-direction: column;
  }

  .camp-row {
    display: flex !important;
    align-items: center;
    flex-direction: column !important;
  }
}

.aboutData__row {
  display: flex;
}

@media screen and (max-width: 1024px) {
  .camp-box {
    padding: 20px 60px 10px 60px;
    /* Adjust padding for 1024px wide screens */
  }
}

@media screen and (max-width: 780px) {
  .aboutData__row {
    flex-direction: column;
  }
}

@media screen and (max-width: 480px) {
  .picture--clipCampVersion {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .dummy-image {
    margin-left: 0;
    margin-top: 20px;
  }
}

@media screen and (max-width: 1024px) {
  .aboutusSec .aboutData .picture--clipCampVersion::after {
    padding: 0px;
  }

  .dummy-image {
    width: 70%;
    height: auto;
  }
}

@media screen and (max-width: 450px) {
  .aboutusSec .aboutData .picture--clipCampVersion::after {
    padding: 0px;
  }

  .dummy-image {
    width: 100%;
    height: 100%;
  }
}

// CAMP VERSION 4.11 CSS, BOXES

.two-boxes-container {
  display: flex;
  align-items: center;
  padding: 20px 80px;
  gap: 40px;
}

@media screen and (max-width: 425px) {
  .firstbox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: auto;
  }

  .secondbox {
    width: 100%;
  }
}

@media screen and (max-width: 780px) {
  .two-boxes-container {
    height: 100%;
    width: auto;
    flex-direction: column;
  }

  .firstbox,
  .secondbox {
    height: 100% !important;
    width: auto !important;
    margin-top: 0px;
  }
}

@media screen and (max-width: 425px) {
  .two-boxes-container {
    height: 100%;
    width: auto;
    flex-direction: column;
  }

  .firstbox,
  .secondbox {
    height: 100% !important;
    width: auto !important;
    margin-top: 0px;
  }
}

.firstbox {
  background: url(../../images/enrichment-images/bg-green.png);
  background-size: 100% auto;
  height: 590px;
  width: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-direction: column;
}

.secondbox {
  background: url(../../images/enrichment-images/blue-box-bg.png);
  background-size: 100%;
  background-position: center;
  height: 554px;
  width: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-direction: column;
}

.content-camp {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.content-image {
  height: 100%;
  max-width: 70%;
  padding: 31px;
}

.contentBtn {
  color: #ffffff;
  background: url(../../images/enrichment-images/button-bg-pink.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% auto;
  height: 100%;
  width: 100%;
  font-size: 1.3rem;
  padding: 32px;
  border: none;
  font-weight: 800;
  border-radius: 0;

  @media screen and (max-width: 991px) {
    font-size: 0.875rem;
  }
}

.contentBtn2 {
  color: #ffffff;
  background: url(../../images/enrichment-images/button-bg-blue.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% auto;
  height: 100%;
  width: 100%;
  font-size: 1.3rem;
  padding: 32px;
  border: none;
  font-weight: 800;
  border-radius: 0;

  @media screen and (max-width: 991px) {
    font-size: 0.875rem;
  }
}

.owl-prev,
.owl-next {
  margin: 0px 30px;
}

.aboutusSec .aboutData .picture--enrichment {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: url(../../images/curve-layer-blue.png);
  background-repeat: no-repeat !important;
  background-position: center center !important;
  background-size: cover !important;
  height: auto;
  width: 100%;
  min-height: 309px;
  padding: 18px 0 42px 60px;
  text-align: center;

  @media screen and (max-width: 1260px) {
    min-height: 120px !important;
  }
}

.enrichment_combo__row {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row-reverse;

  @media screen and (max-width: 800px) {
    flex-direction: column-reverse;
  }
}

.enrichment_combos_shorttext {
  margin-top: -10px;
  font-size: 1.75rem !important;
  line-height: 37px !important;
  word-spacing: 10px;

  @media screen and (min-width: 420px) and (max-width: 450px) {
    width: 400px;
  }
}

.enrichment_title_heading {
  font-size: 2.8rem;
  font-weight: 600;
}

.picture_enrichment {
  margin: 15px;
}

._enrichment_programList__card {
  display: flex;
  align-items: center;
  gap: 2px;
  padding: 35px;
  background: #fff;
  box-shadow: 0px 24.2957763672px 54px 0px rgba(205, 205, 205, 0.4);
}

.enrichment_para_content {
  font-size: 1rem;
  font-weight: 400;
  padding: 0px 10px 0px 0px;
}

.facilitiesSec_enrichment {
  padding: 30px 0px !important;
}

.ourStaff_picture_name {
  width: fit-content !important;
}

.cheerleading_title {
  font-size: 1.8rem !important;
}

.cheerleading_upper_content_row {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  gap: 80px;

  @media screen and (max-width: 450px) {
    flex-direction: column-reverse !important;
    gap: 20px;
  }
}

.aboutusSec .cheer_upper_content {
  width: 60%;
}

.cheeer_img_background {
  background: url("../../images/curve-layer-pink.png") !important;
}

.aboutusSec .cheer_img {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: url("../../images/camp-bg-blue.png");
  background-repeat: no-repeat !important;
  // background-position: center center !important;
  background-size: contain !important;
  height: 100%;
  width: 100%;
  min-height: 480px;
  padding: 45px 0 35px 43px;
  text-align: center;

  @media screen and (max-width: 450px) {
    margin-bottom: -187px;
  }

  img {
    margin-left: -150px;
    width: 539px;
    height: 351px;
    transform: rotate(3deg);

    @media screen and (max-width: 1440px) {
      margin-left: 0px;
    }

    @media screen and (max-width: 1000px) {
      margin-top: -100px;
      margin-left: 23px;
      height: 300px;
    }

    @media screen and (max-width: 600px) {
      width: 430px;
      margin-left: -52px;
      height: 328px;
    }

    @media screen and (max-width: 450px) {
      margin-top: -245px;
      width: 251px;
      margin-left: -52px;
      height: 170px;
      // margin-bottom: -187px;
    }
  }
}

.class_list_item_container_row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2px;
}

.class_list_item_container_col {
  width: 30%;

  .class_camp_slot_left {
    font-weight: 700;
    color: #ea519c;
    margin-left: 10px;
  }

  ._add_to_cart_camp_btn {
    margin-top: -15px;
    background-color: white;
    color: #ea519c;
    padding: 5px 10px;
    border-radius: 10px;
    border: 1px solid #ea519c;
    font-weight: 600;

    &:hover {
      color: white;
      background-color: #ea519c;
    }
  }

  .classDetail_week_number {
    font-weight: 600;
    font-size: 1.1rem;
    margin-right: 5px;
  }
}

.camp_horizontal_line {
  height: 2px;
  width: 100%;
  background-color: rgb(195, 192, 192);
  border-radius: 10px;
  margin-bottom: 2rem;
}

.camp_class_date,
.camp_class_type {
  margin-top: 10px;
}

.class_availabilty_container {
  margin-bottom: 4rem;
}

.camp_pinkBg-section {
  position: relative;
}

.full_day_signUp_btn {
  position: absolute;
  bottom: 34px;
  right: 53px;
  background: #f79727;
  clip-path: polygon(6% 0, 90% 0, 100% 100%, 0% 100%);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% auto;
  font-size: 1.3rem;
  padding: 1rem 1.5rem;
  border: none;
  font-weight: 800;
  border-radius: 0;
  color: white;
}

// .owl-carousel .owl-item img {
//   display: block;
//   width: 100%;
//   border: 2px solid gray;
//   padding: 64px;
// }

.gir_gymnastics_title_container {
  display: flex;
  flex-direction: column;
  margin-left: -20px;
}

.pagination-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sub-pagination-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}

// =================POPUP Page styling============================

.popup_title_container .pop_up_title {
  font-size: 2.2rem;
  font-weight: 700;
  color: #5e5f5f;
  line-height: 1.1;
  margin-bottom: 20px;
}

.popUp_shortText .para {
  font-size: 1.4rem;
  color: #515151;
  font-weight: 400;
  margin-bottom: 4rem;
  line-height: 36px;

  @media screen and (max-width: 580px) {
    font-size: 1.2rem;
    line-height: 35px;
  }
}

.pop_up_body_box_row {
  display: flex;
  gap: 3%;
  justify-content: center;
  margin-bottom: 4rem;
  max-width: 76%;
  height: 289px;
  width: 100%;
  margin-bottom: 143px;

  @media screen and (max-width: 550px) {
    flex-direction: column;
    height: 100%;
    margin-bottom: 5px;
    justify-content: center;
  }
}

.classdetial_container {
  display: flex;
  justify-content: center;
  gap: 50px;

  .class_text {
    max-width: 60%;
  }

  .picture {
    width: 100%;
  }

  @media screen and (max-width: 550px) {
    flex-direction: column;
    height: 100%;
    margin-bottom: 5px;
    justify-content: center;
  }
}

@media screen and (max-width: 500px) {
  .classdetial_container .class_text {
    max-width: 100%;
    text-align: justify;
  }
}

.pop_up_body_box_row .pop_up_box_col {
  // width: 38%;
  display: flex;
  flex-direction: column;
}

.pop_dummy_container {
  display: flex !important;
  justify-content: center !important;
  flex-direction: column !important;
  align-items: center !important;
}

.pop_up_box_col {
  width: 50%;

  @media screen and (max-width: 550px) {
    width: 100%;
    margin-bottom: 30px;
  }
}

.parent_pop_caontainer_fluid {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.pop_up_body_container_fluid {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: fit-content;

  @media screen and (max-width: 550px) {
    flex-direction: column;
    justify-content: center;
    display: flex;
    align-items: center;
  }
}

/* col-img-container */
.pop_up_image_container-1 {
  background: url("../../images/Pop_Images/pop_up_green.png");
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: bottom;
  background-size: cover;

  @media screen and (max-width: 1000px) {
    height: 300px;
  }

  @media screen and (max-width: 775px) {
    height: 200px;
  }

  @media screen and (max-width: 550px) {
    height: 160px;
  }
}

.pop_up_image_container-2 {
  background: url("../../images/Pop_Images/pop_up_pink.png");
  height: 100%;

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: bottom;
  background-size: cover;

  @media screen and (max-width: 1000px) {
    height: 300px;
  }

  @media screen and (max-width: 775px) {
    height: 200px;
  }

  @media screen and (max-width: 550px) {
    height: 163px;
  }
}

.pop_up_image_container-3 {
  background: url("../../images/Pop_Images/pop_up_orange.png");
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: bottom;
  background-size: cover;

  @media screen and (max-width: 1000px) {
    height: 300px;
  }

  @media screen and (max-width: 775px) {
    height: 200px;
  }

  @media screen and (max-width: 550px) {
    height: 164px;
  }
}

.pop_up_image_container-4 {
  background: url("../../images/Pop_Images/pop_up_blue.png");
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: bottom;
  background-size: cover;

  @media screen and (max-width: 1000px) {
    height: 300px;
  }

  @media screen and (max-width: 775px) {
    height: 200px;
  }

  @media screen and (max-width: 550px) {
    height: 160px;
  }
}

.pop_up_image_container-5 {
  background: url("../../images/Pop_Images/pop_up_purple.png");
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: bottom;
  background-size: cover;

  @media screen and (max-width: 1000px) {
    height: 300px;
  }

  @media screen and (max-width: 775px) {
    height: 200px;
  }

  @media screen and (max-width: 550px) {
    height: 157px;
  }
}

.pop_up_image_container-6 {
  background: url("../../images/Pop_Images/pop_up_green_2.png");
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: bottom;
  background-size: cover;

  @media screen and (max-width: 1000px) {
    height: 300px;
  }

  @media screen and (max-width: 775px) {
    height: 200px;
  }

  @media screen and (max-width: 550px) {
    height: 162px;
  }
}

.pop_up_image_container-1 img,
.pop_up_image_container-2 img,
.pop_up_image_container-3 img,
.pop_up_image_container-4 img,
.pop_up_image_container-5 img,
.pop_up_image_container-6 img {
  height: 80%;
  width: 69%;
  background-color: white;
  margin: 41px;
  object-fit: cover;

  @media screen and (max-width: 1000px) {
    height: 72%;
    width: fit-content;
  }
}

/* col-text-para */
.pop_col_paratext_container .pop_col_paratext {
  margin-top: 20px;
  // font-size: 0.8rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 25px;

  @media screen and (max-width: 600px) {
    font-size: 0.8rem;
    font-weight: 400;
    padding: 0px 10px;
    line-height: 1.3rem;
  }
}

.half_full_heading_title_container {
  .register_title_heading {
    font-size: 2rem;
    font-weight: 600;
    color: #5e5f5f;
    line-height: 1.5;
    text-align: center;

    @media screen and (max-width: 780px) {
      font-size: 2rem;
    }

    @media screen and (max-width: 580px) {
      font-size: 1.5rem;
      text-align: center;
    }

    @media screen and (max-width: 400px) {
      font-size: 1.3rem;
    }
  }
}

.registration_fees_desc_heading {
  margin-top: 50px;
  font-size: 2rem;
  font-weight: 700;
  color: #5e5f5f;

  @media screen and (max-width: 780px) {
    font-size: 1.9rem;
  }

  @media screen and (max-width: 580px) {
    font-size: 1.5rem;
    text-align: center;
  }

  @media screen and (max-width: 400px) {
    font-size: 1.3rem;
  }
}

.registration_fees_desc_para_container {
  margin-top: 1rem;

  @media screen and (max-width: 450px) {
    padding: 20px;
  }

  .registration_fees_desc_para_container_row {
    margin-bottom: 1rem;
    font-size: 1.15rem;

    @media screen and (max-width: 780px) {
      font-size: 1.1rem;
    }

    @media screen and (max-width: 580px) {
      font-size: 0.9rem;
      text-align: center;
    }

    .camp_type,
    .camp_week_cost {
      font-weight: 600;
    }
  }
}

.half_full_day_schedule_desc {
  display: flex;
  gap: 19%;
  margin-top: 3rem;
  align-items: center;

  @media screen and (max-width: 780px) {
    display: flex;
    flex-direction: column;
    align-content: center;
  }

  .half_full_box_container {
    width: 30%;

    @media screen and (max-width: 780px) {
      display: flex;
      gap: 10px;
      justify-content: center;
      width: 100%;
    }

    @media screen and (max-width: 450px) {
      display: inline;
    }
  }

  .half_full_weekly_desc {
    width: 100%;
    margin-top: -5.1rem;

    @media screen and (max-width:800px) {
      margin-top: 0rem;
    }

    @media screen and (max-width: 450px) {
      margin-top: 1rem;
      padding: 0px;
    }

    .weekly_description {
      .weekly_desc_head_title {
        font-size: 1.4rem;
        font-weight: 600;
        color: #5e5f5f;
      }

      .weekly_schedule {
        font-size: 1.1rem;
      }
    }

    .weekly_description_info {
      display: flex;
      gap: 10px;
      margin-top: 20px;

      .weekday-count-name {
        .week_day_count {
          font-size: 1.3rem;
          font-weight: 600;
          color: #5e5f5f;
          display: flex;
          align-items: center;
          gap: 10px;

        }
      }
    }
  }
}

.half_day_schedule_title_heading {
  color: white;
  font-size: 1.6rem;
  font-weight: 600;

  @media screen and (max-width: 1000px) {
    font-size: 1.1rem;
  }

  @media screen and (min-width: 400px) and (max-width: 749px) {
    font-size: 1.1rem;
  }
}

.half_day_box {
  display: flex;
  width: 123%;
  justify-content: center;
  align-items: center;
  background: url(../../images/camp-orange.png) center/cover no-repeat;
  height: 20rem;
  margin-bottom: 2rem;
  background-position: bottom;
  // background-size: contain;
  padding: 30px;

  @media screen and (min-width: 800px) and (max-width: 1000px) {
    height: 15rem;
  }

  @media screen and (max-width: 799px) {
    width: 48%;
  }

  @media screen and (max-width: 450px) {
    width: 100%;
  }
}

.full_day_box {
  display: flex;
  width: 123%;
  justify-content: center;
  align-items: center;
  background: url(../../images/camp-version-blue.png) center/cover no-repeat;
  height: 20rem;
  margin-bottom: 2rem;
  padding: 35px;

  @media screen and (min-width: 800px) and (max-width: 1000px) {
    height: 15rem;
  }

  @media screen and (max-width: 799px) {
    width: 48%;
  }

  @media screen and (max-width: 450px) {
    width: 100%;
  }
}

.half_day_schedule_time {
  color: white;
}

.halfday_schedule_desc {
  color: white;
}

.week_end_line {
  margin-top: 3px;
  height: 2px;
  width: 81%;
  background-color: #7c7c7c;

  @media screen and (max-width: 425px) {
    width: 100%;
  }
}

.girl_gymnastics_day_box {
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(../../images/girl-gymnastic-bg-blue.png) center/cover no-repeat;
  height: 30rem;
  width: 185%;
  margin-bottom: 2rem;
  background-position: center;
  background-size: contain;
  position: absolute;
  top: -3px;
  right: 0;

  @media screen and (max-width: 450px) {
    width: 100%;
    margin-bottom: 0px;
    margin-top: -5rem;
    position: relative;
  }
}

.aboutData__col {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cheer_dir_email {
  font-weight: 600;
  font-size: 1.3rem;

  @media screen and (max-width: 450px) {
    margin-left: 19px;
  }
}

.team_cheer_gymnastics_container_fluid {
  line-height: 2;

  @media screen and (max-width: 450px) {
    margin-top: -25px;
  }
}

.team_classes_type_heading {
  font-size: 2rem;
  font-weight: 700;
}

.team_classes_type_desc {
  font-size: 1.1rem;
}

.cheer_gym_box_container_fluid {
  display: flex;
  justify-content: center;
  margin-top: 35px;

  @media screen and (max-width: 750px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .blue_box_container {
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: fit-content;
    padding: 60px;
    height: 20rem;
    background: url(../../images/cheer-camp-bg-blue.png) center/cover no-repeat;
    gap: 8px;
    background-position: bottom;
    background-size: contain;
    min-width: 425px;

    @media screen and (max-width: 750px) {
      width: 100%;
    }

    @media screen and (max-width: 450px) {
      margin-top: -30px;
      margin-bottom: 10px;
      width: 360px;
      min-width: 360px;
    }

    @media screen and (max-width: 420px) {
      margin-top: -50px;
      width: 340px;
      min-width: 340px;
    }

    .mandatory_heading {
      font-size: 1.6rem;
      font-weight: 600;
      text-align: center;

      @media screen and (max-width: 430px) {
        margin-top: 37px;
        font-size: 1.3rem;
        width: 375px;
      }

      @media screen and (max-width: 420px) {
        margin-top: 97px;
        font-size: 1.3rem;
        width: 375px;
      }
    }

    .day_time_mandatory_heading {
      font-size: 1.3rem;
      font-weight: 600;

      @media screen and (max-width: 430px) {
        font-size: 0.9rem;
      }
    }

    .mandatory_practice_desc {
      font-size: 0.91rem;
      text-align: center;
    }
  }

  .pink_box_container {
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 33%;
    padding: 60px;
    height: 19rem;
    background: url(../../images/cheer-camp-bg-pink.png) center/cover no-repeat;
    background-position: bottom;
    background-size: contain;
    min-width: 425px;

    @media screen and (max-width: 750px) {
      width: 100%;
    }

    @media screen and (max-width: 450px) {
      margin-bottom: 20px;
      margin-top: -50px;
      min-width: 350px;
      width: 360px;
      padding: 74px;
    }

    @media screen and (max-width: 420px) {
      margin-bottom: 20px;
      margin-top: -69px;
      width: 340px;
      padding: 74px;
      min-width: 340px;
    }

    .mandatory_heading {
      font-size: 1.6rem;
      font-weight: 600;

      @media screen and (max-width: 430px) {
        font-size: 1.3rem;
        margin-top: 97px;
      }
    }

    .mandatory_practice_desc {
      font-size: 1rem;
      margin-top: 14px;
      text-align: center;

      @media screen and (max-width: 430px) {
        font-size: 0.9rem;
      }
    }
  }
}

.pop_up_btn_more_info {
  float: right;
  background: #00bbe3;
  clip-path: polygon(6% 0, 90% 0, 100% 100%, 0% 100%);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% auto;
  height: 100%;
  width: fit-content;
  font-size: 1.3rem;
  padding: 12px;
  border: none;
  font-weight: 800;
  border-radius: 0;
  font-size: 1.3rem;
  padding: 9px 25px;
  margin-top: 10px;

  @media screen and (max-width: 1200px) {
    font-size: 1.1rem;
    padding: 10px 20px;
  }

  @media screen and (max-width: 789px) {
    font-size: 0.9rem;
    padding: 5px 12px;
  }
}

.pinkBg-section {
  background: url(../../images/enrichment-images/team-cheer-pink.png) center/contain no-repeat;
  padding: 12vh 12vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 500px) {
    margin-top: -100px;
  }
}

.pinkBg-section .container {
  max-width: 40%;
  height: auto;
  display: flex;
  justify-content: center;
}

.pinkBg-section img {
  max-width: 70%;
  height: auto;
}

.pinkBg-section h1 {
  font-weight: 400;

  @media screen and (max-width: 500px) {
    margin-top: 30px;
    font-size: 0.8rem;
  }
}

.pinkBg-section h1,
.pinkBg-section p {
  color: #ffffff;
}

.pinkBg-section {
  .container {
    background: url(../../images/birthday-icons/white-bg.png);
    padding: 52px;
    margin-top: 20px;

    @media screen and (max-width: 780px) {
      padding: 0px;
    }

    @media screen and (max-width: 500px) {
      margin-bottom: 35px;
    }
  }

  .container img {
    width: 100%;
    height: 145px;

    @media screen and (max-width: 500px) {
      height: 103px;
    }
  }
}

.cheer-carousel {
  padding: 66px 71px;
}

// .galleryGrid__item {
//   height: 330px;
// }

.pop_up_btn_more_info:hover {
  color: #333;
}

// ==================camp modal schedule styling=============

.camp_three_four_body_container {
  .camp_schedule_type_container {
    display: flex;
    justify-content: space-evenly;
  }

  .camp_schedule_day_time_container {
    margin-top: 20px;
    gap: 20px;
    display: flex;
    justify-content: space-evenly;
    align-items: end;
  }

  .add_schedule_btn {
    margin-top: 10px;
  }

  .numberOfActivity_container {
    margin-top: 10px;
    display: flex;
    justify-content: space-around;
    align-items: end;
  }
}

.activity_name_container {
  display: flex;
  gap: 20px;
  align-items: center;
}

.week_count_activity_container {
  margin-bottom: 15px;
  display: flex;
  justify-content: space-around;

  .activity_container {
    display: flex;
    gap: 10px;
    align-items: center;
  }
}

.add_weekly_schedule {
  margin-top: 15px;
  margin-bottom: 10px;
}

.girl_gymnastics_activity_scheduler_container {
  .girl_blue_box_data_container {
    display: flex;
    justify-content: space-evenly;
    align-items: end;

    .girl_gymnastics_time {
      display: flex;
      gap: 10px;
      align-items: end;
    }
  }

  .numberOfActivity_container {
    margin-top: 10px;
    display: flex;
    justify-content: space-around;
    align-items: end;
  }
}

.girl_start_end_time {
  margin-top: 10px;
  display: flex;
  justify-content: space-around;
}

.ques_ans_section {
  margin-bottom: 20px;

  .faq_question {
    font-weight: 600;
    font-size: 1.2rem;
  }

  .faq_answer {
    margin-top: 6px;
    font-size: 1.1rem;
    line-height: 25px;
    font-weight: 400;
  }

  .ques_subtitle {
    font-weight: 700;
  }
}

.girl_gymnastics_content {
  margin-bottom: 0;
  margin-top: 10px;
  line-height: 29px;
  word-spacing: 0px;
  font-size: 1.2rem;
}

.birthdaySec .birthdaypartyDetail__cardBox {
  background: #c3d940;
  -webkit-clip-path: polygon(3% 0, 97% 3%, 100% 97%, 0 100%);
  clip-path: polygon(3% 0, 97% 3%, 100% 97%, 0 100%);
  margin-top: 80px;
  padding: 2.5rem 3rem;
}

.staff_founder_picture_box {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: url("../../images/staff-layer-main.png");
  background-repeat: no-repeat !important;
  background-position: center center !important;
  background-size: contain !important;
  height: 100%;
  width: 100%;
  min-height: 380px;
  padding: 45px 0 35px 60px;
  text-align: center;

  img {
    height: 100%;

    @media screen and (max-width: 775px) {
      height: 260px;
    }

    @media screen and (max-width: 450px) {
      height: 230px;
    }

    @media screen and (max-width: 420px) {
      height: 194px;
    }
  }

  @media screen and (max-width: 1000px) {
    padding: 68px 0 72px 60px;
  }

  @media screen and (max-width: 775px) {
    padding: 0px;
  }
}

.cheerBgImage {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: url(../../images/static-cheer-image/cheer-purple-bg.png);
  background-repeat: no-repeat !important;
  background-position: center center !important;
  background-size: contain !important;
  height: 100%;
  width: 100%;
  min-height: 380px;
  padding: 69px 0 35px 119px;
  text-align: center;

  @media screen and (max-width: 1400px) {
    padding: 34px 0 13px 69px;
  }

  @media screen and (max-width: 1000px) {
    padding: 52px 0 46px 32px;
  }

  @media screen and (max-width: 500px) {
    margin-top: -60px;
    margin-bottom: -60px;
  }

  @media screen and (min-width: 423px) and (max-width: 500px) {
    margin-top: -40px;
    margin-bottom: -40px;
  }
}

.cheer_additional_popUp_descs {
  @media screen and (max-width: 500px) {
    font-size: 0.8rem;
  }
}

.music_dj_birthday_Page {
  @media screen and (max-width: 999px) {
    display: none;
  }
}

.dj-checkbox {
  @media screen and (max-width: 500px) {
    width: 16px !important;
    height: 40px !important;
    margin-bottom: 7px !important;
  }
}

.dj-title {
  @media screen and (max-width: 500px) {
    font-size: 1.1rem !important;
  }
}

.team_cheer_headin {
  font-weight: 500 !important;
  font-size: 1.4rem;
  color: #5e5f5f;

  @media screen and (max-width: 767px) {
    padding-right: 44px !important;
  }
}

.teamCheerSec {
  @media screen and (max-width: 450px) {
    margin-top: 20px;
    padding: 0px !important;
  }
}

.camp-row {
  display: flex;
  align-items: center;
}

/* CSS */
.form__checkbox:checked+.dj-title {
  background-color: red;
}

.team_cheer_gym_faci {
  @media screen and (max-width: 630px) {
    padding: 0px 35px !important;
  }

  @media screen and (max-width: 450px) {
    padding: 0px 35px !important;
  }
}

.team_cheer_gym_faci_container {
  @media screen and (max-width: 450px) {
    padding: 0px 20px !important;
  }

  @media screen and (max-width: 420px) {
    padding: 0px 0px !important;
  }
}

.rotateIcon {
  transform: rotate(180deg);
}

.recurringDivContainer {
  display: flex;
  gap: 20px;
  align-items: center;
}

.recClassDiv {
  position: relative;
}

.recClassDiv input[type="checkbox"] {
  width: 15px;
  height: 15px;
}

.recUl {
  display: flex;
  padding-left: 5%;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.recUl li {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 300px;
}

.recUl li p {
  font-size: 0.8rem;
  font-weight: 500;
  flex: 1;
}

.recLabels {
  display: flex;
  align-items: center;
  gap: 10px;
  padding-left: 3%;
}

.Enrichment_combo_heading_title {
  margin-bottom: 5px;
  font-weight: 700;
  font-size: 2rem;
}

.Enrichment_combo_btn {
  background: #00bbe3;
  clip-path: polygon(6% 0, 90% 0, 100% 100%, 0% 100%);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% auto;
  height: 100%;
  width: 100%;
  font-size: 0.81rem;
  // padding: 12px;
  border: none;
  font-weight: 800;
  border-radius: 0;
}

.continue_reading_container {
  display: flex;
  justify-content: center;
}

.continue_reading_btn {
  font-size: 1rem;
  border: 1px solid rgb(165, 165, 165);
  border-radius: 10px;
  padding: 5px 10px;
  background-color: rgb(206, 205, 205);
}

.birthday_page_picture_img .birthday_page_picture_img {
  bottom: -15px !important;
}

.class_detail_image_container {
  @media screen and (max-width: 450px) {
    padding: 0px;
  }

  @media screen and (max-width: 420px) {
    padding: 25px;
  }

  @media screen and (max-width: 414px) {
    padding: 0px;
  }

  @media screen and (max-width: 390px) {
    padding: 0px;
  }

  @media screen and (min-width: 391px) {
    padding: 0px 0px;
  }
}

.class_detail_image_container img {
  float: left;
  margin: 10px 40px;
  border-radius: 5px;
  width: 500px;
}

@media screen and (max-width: 768px) {
  .class_detail_image_container img {
    margin: 10px 0px;
    width: 100%;
  }
}

.class_details_shortText_container {
  line-height: 29px;
  font-size: 1.1rem;
  padding: 0px 35px;

  @media screen and (max-width: 600px) {
    text-align: justify;
    font-size: 1.1rem;
    padding: 0px;
    line-height: 29px;
  }

  @media screen and (max-width: 450px) {
    font-size: 1.09rem;
    text-align: left;
  }
}

.half_full_day_container_fluid_center {
  display: flex;
  justify-content: center;
  width: 100%;
}

.dummy_container {
  display: flex;
  justify-content: center;
}

.girl_gymnastics_full_detail_conatiner {
  display: flex;
  justify-content: center;
}

.half_full_day_schedule_desc_temp {
  margin-top: 6rem;
  position: relative;
}

.classAvailability_container_fluid {
  display: flex;
  justify-content: center;

  .classAvailability {
    width: 1305px;
    margin-top: 20px;
    padding: 0px 48px;

    @media screen and (max-width: 1400px) {
      width: 1050px !important;
      padding: 0px 40px;
    }

    @media screen and (max-width: 1100px) {
      width: 900px !important;
    }

    @media screen and (max-width: 900px) {
      width: 750px !important;
      padding: 0px 30px;
    }

    @media screen and (max-width: 390px) {
      // width: 750px !important;
      padding: 0px 0px;
    }
  }
}

.fade_loader_modal_container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.class_wk {
  text-align: center;
  margin-right: 10px;
  align-content: center;
  align-items: center;
}

.titleBox--cake--responsive {
  @media screen and (max-width: 450px) {
    width: 115px !important;
  }
}

.yoga_title {
  @media screen and (max-width: 450px) {
    margin-left: 10px !important;
  }
}

.camp_content_mobile {
  @media screen and (max-width: 450px) {
    margin-top: -65px;
  }
}

.enrichment_combo_new_title {
  @media screen and (max-width: 450px) {
    margin-bottom: 0px !important;
    font-size: 2.3rem !important;
    margin-left: 10px;
  }
}

.girls_gymnastics_title_new {
  @media screen and (max-width: 450px) {
    margin-left: 20px;
  }
}

.camp_dropdown_cms {
  width: 100px !important;
}

// 14 pro max screen

@media screen and (max-width: 430px) {
  .facilitiesSec .gymTimeGuyIcon_fac {
    width: 25% !important;
    height: auto !important;
  }
}

.home_dummy_text_container {
  padding: 5px 0px !important;
  margin-top: 2rem;

  @media screen and (max-width: 1000px) {
    margin-top: 2rem;
  }
}

.shortext_dummy_text_home {
  font-size: 1.45rem;
  font-weight: 400;
  word-spacing: 1px;
  line-height: 30px;
  margin-top: 30px;

  @media screen and (max-width: 1420px) {
    font-size: 1.1rem;
  }

  @media screen and (max-width: 1040px) {
    margin-top: 5px;
    font-size: 1.1rem;
  }

  @media screen and (max-width: 440px) {
    padding: 6px 35px;
    line-height: 23px;
    margin-top: 0px;
    margin-bottom: 20px;
  }

  @media screen and (max-width: 420px) {
    padding: 10px 35px;
    line-height: 23px;
    margin-top: 20px;
    // margin-top: -50px;
    margin-bottom: 20px;

    @media screen and (max-width: 390px) {
      padding: 20px 25px;
    }
  }
}

.our_staff_list_container_fluid {
  @media screen and (max-width: 450px) {
    display: flex;
    justify-content: center;
  }
}

.popUp_text_area_container {
  width: 200px !important;
  min-height: 280px !important;
}

.commonList__row__pop_flexwrap {
  flex-wrap: wrap;
}

.home_dummy_shortText {
  margin-top: 20px;
  padding: 0px 35px;
  margin-bottom: 0px !important;
}

@media screen and (max-width: 430px) {
  .home_dummy_shortText {
    padding: 0px 30px;
  }
}

.team_cheer_gymnastics_pink_box_text {
  margin-left: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 435px) {
    margin-top: 20px;
    margin-left: 24px;
    line-height: 27px;
  }
}

.team_cheer_gymnastics_blue_box_text {
  @media screen and (max-width-435px) {
    line-height: 30px;
    margin-top: 50px;
  }
}

.scrollToScrool-btn {
  float: right;
}

.table_main_container_with_scroll_up {
  position: relative;

  .scrollToScrool-btn {
    background-color: #5497c7;
    border-radius: 50% !important;
    width: fit-content !important;
    position: fixed;
    bottom: 30px;
    right: 50px;
    min-width: 0px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;

    img {
      color: white;
      height: 30px;
    }
  }
}

// .weekdays_width_container{
//   width: 82px;
// }

.enr_icon {
  @media screen and (max-width: 639px) {
    height: 65px !important;
  }
}

.aboutusSec {
  @media screen and (max-width: 639px) {
    padding: "0px 35px" !important;
  }
}

.content {
  .shortText {
    @media screen and (max-width: 639px) {
      font-size: 1.2rem !important;
      line-height: 35px !important;
    }
  }
}

.enr_text,
.enr_para {
  @media screen and (max-width: 639px) {
    font-size: 1.2rem !important;
    line-height: 35px !important;
  }
}

.picture program_picture program_picture_responsive {
  @media screen and (max-width: 1440px) {
    padding: 0px 15px !important;
  }
}

.team_classes_type_heading_container,
.team_gym_cheer_email {
  @media screen and (max-width: 450px) {
    padding: 22px !important;
    line-height: 30px;
  }
}

.facilitySec_responsive {
  @media screen and (max-width: 450px) {
    padding: 0px 50px !important;
  }

  @media screen and (max-width: 414px) {
    padding: 0px 35px !important;
  }

  @media screen and (max-width: 420px) {
    padding: 0px 35px !important;
  }
}

.contentWrapper_responsive {
  @media screen and (max-width: 450px) {
    padding-bottom: 0px !important;
  }
}

.about_video_img {
  transform: rotate(-9deg);
}

.commonSec_mobile_version {
  @media screen and (min-width: 389px) and (max-width: 391px) {
    padding: 0px !important;
  }
}

#policy_subtitles {
  font-weight: 600;
  font-size: 1.3rem;
  line-height: 29px;
  color: rgb(234, 81, 156);
}

#policy_para_content {
  margin-bottom: 20px;
  line-height: 29px;
  font-size: 1.1rem;
}

.dummy_picture__img_boy {
  @media screen and (max-width: 450px) {
    height: 200px;
  }
}

.pop_up_image_container-1 img,
.pop_up_image_container-4 img,
.pop_up_image_container-6 img {
  @media screen and (max-width: 1400px) {
    margin: 36px;
  }
}

.camp_week_days_date {
  font-size: 1.1rem;
  font-weight: 400;
  margin-top: 2.5px;
}

.weekday-count-name {
  line-height: 28px;
}

.Showcase__style__handle {
  position: relative;
  top: 1px;
  display: block;
  width: 18px;
  height: 11px;
  opacity: .25;
  margin-right: 20px;
  cursor: grab;
  background: -webkit-linear-gradient(top, #000, #000 20%, #fff 0, #fff 40%, #000 0, #000 60%, #fff 0, #fff 80%, #000 0, #000);
  background: linear-gradient(180deg, #000, #000 20%, #fff 0, #fff 40%, #000 0, #000 60%, #fff 0, #fff 80%, #000 0, #000);
}