.three_four_half_day_container{
    display: flex;
    gap: 20px;
    height: 23rem;
}
.jodit-wysiwyg{
    min-height: 175px !important;
}

.camp_btm{
    float: right;
    margin-bottom: 20px;
}
.schedule_day_title_camp{
    font-weight: 700;
    margin-top:0px;
}