@use "./admin-variables" as *;

.icon {
  display: block;

  &-profile{
    background-image: url("../../images/icons/profile.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    width: 16px;
    height: 16px;
  }

  &-signout {
    background-image: url("../../images/icons/signout.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    width: 16px;
    height: 16px;
  }
}
