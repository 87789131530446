.birthday_shimmer_row{
    display: flex;
    gap: 20px;
}
.birthday_shimmer_row .birthady_shimmer_col {
    position: relative;
    height: 350px;
    border-radius: 10px;
    width: 100%;
    background-color: rgb(236, 236, 236);
    overflow: hidden;

    @media screen and (max-width: 1200px) {
        height: 300px;
    }

    @media screen and (max-width: 800px) {
        height: 200px;
    }
}

.birthday_shimmer_row .birthady_shimmer_col::after {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 200%;
    height: 100%;
    background: linear-gradient(to right, transparent 0%, rgba(255, 255, 255, 0.6) 50%, transparent 100%);
    animation: shimmer 5.5s infinite;
}

@keyframes shimmer {
    100% {
        left: 100%;
    }
}

